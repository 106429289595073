@import "variables.scss";

.cmx-popover {
  position: absolute;
  max-width: 200px;
  font-size: 14px;
  padding: 0;
  background: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  z-index: 1000;
  transition: all .3s ease;
  transform: translate(0, -5px);
  opacity: 0;

  &:after {
    content: '';
    position: absolute;
    border-style: solid;
  }

  &-top:after {
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-color: white transparent transparent transparent;
    filter: drop-shadow(0px 4px 2px rgba(0,0,0,.2));
  }

  &-right:after {
    top: 50%;
    right: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-color: transparent white transparent transparent;
    filter: drop-shadow(-2px 0px 1px rgba(0, 0, 0, 0.3));
  }

  &-bottom {

    .popover-header {

        &::after {
            content: '';
            position: absolute;
            border-style: solid;
            bottom: 100%;
            left: 50%;
            margin-left: -5px;
            border-width: 5px;
            border-color: transparent transparent $color-blue transparent;
            filter: drop-shadow(0px -4px 2px rgba(0,0,0,.2));
        }
    }
  }

  &-left:after {
    top: 50%;
    left: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-color: transparent transparent transparent white;
    filter: drop-shadow(2px 0px 1px rgba(0, 0, 0, 0.3));
  }

  &-show {
    transform: translate(0);
    opacity: 1;
  }

  &-nowrap {
    white-space: nowrap;
    max-width: none;
  }

  .popover-header {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      background-color: $color-blue;
      color: white;

      h5 {
        white-space: nowrap;
        font-size: 1em;
        font-weight: 500;
        margin: 0;
        padding: 0;
      }
  }
  .popover-content {
      padding: 5px 10px;
      font-size: .9em;

      p {
          margin: 0;
          padding: 0;
      }
  }
}
