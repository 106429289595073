@import './app/theme/layouts/styles/variables.scss';

* {
    font-family: Poppins;
}

body {
    height: calc(100% - 70px);
    margin-top: 70px;
    background-color: rgb(247, 247, 247);
}

a {
    color: black;
}

*:focus {
    outline: none !important;
    box-shadow: 0 0 0 0 transparent !important;
}

strong {
    font-weight: 600;
}

.page-title-identifier {
    position: fixed;
    visibility: hidden;
}

.m-content {
    width: 100vw;
}

.m-datatable.m-datatable--default > .m-datatable__table > .m-datatable__body .m-datatable__row > .m-datatable__cell {
    padding: 5px 10px !important;
}

.ui-widget {
    font-family: Poppins !important;
}

.m-footer {
    margin-left: 0px !important;
}

.m-login.m-login--1 .m-login__wrapper {
    padding: 5% 2rem 2rem 2rem;
}

.m-login__logo img {
    width: auto !important;
    max-height: 20vh;
    max-width: 20vw;
}

.m-brand .m-brand__logo .m-brand__logo-wrapper img {
    width: auto !important;
    max-height: 58px;
    max-width: 140px;
}

.m-subheader {
    padding: 15px 30px 0 30px;
}

.m-content {
    margin-bottom: 40px;
}

.has-success label.col-form-label {
    color: #575962 !important;
}

.number-column {
    text-align: right;
    &.section-header span {
        visibility: hidden;
    }
}

td.section-header,
td.total-line {
    font-weight: bold;
}

.text-column {
    text-align: left;
}

.table-y-striped th:nth-child(even),
.table-y-striped td:nth-child(even) {
    background: #fbfbfe;
}

.m-stack__item.m-brand {
    width: 190px;
    box-shadow: 0px 0px 0px 0px rgba(12, 4, 124, 0) !important;
}

body.m-aside-left--skin-dark .m-header .m-header-head {
    box-shadow: 0px 0px 0px 0px rgba(113, 106, 202, 0) !important;
}

.m-link.m-link--primary:hover:after {
    border-bottom: 4px solid $comexport-blue !important;
    opacity: 1 !important;
}

.m-datatable.m-datatable--default
    > .m-datatable__pager
    > .m-datatable__pager-nav
    > li
    > .m-datatable__pager-link.m-datatable__pager-link--active {
    background: #76b729;
    color: #ffffff;
}

.m-header-menu.m-header-menu--submenu-skin-light
    .m-menu__nav
    > .m-menu__item
    .m-menu__submenu
    > .m-menu__subnav
    > .m-menu__item
    > .m-menu__link
    .m-menu__link-text {
    color: #676c7b !important;
}

.m-datatable__pager-nav {
    margin: 10px 10px 10px 10px !important;
}

// DASHBOARD
.cmx-widget24 {
    h1 {
        margin-left: 1.8rem;
        margin-top: 2rem;
        display: block;
    }
    h4 {
        color: #575962;
        margin-left: 1.8rem;
        margin-top: 0;
        display: inline-block;
        font-size: 1.1rem;
        font-weight: 600;
    }
    .progress {
        height: 100%;
        margin-top: 1rem;
        margin-left: 1.8rem;
        margin-right: 1.8rem;
        margin-bottom: 0.5rem;
    }
    .cmx-widget24__change {
        color: #9699a2;
        margin-left: 1.8rem;
        display: inline-block;
        margin-bottom: 2.86rem;
        font-size: 1rem;
        font-weight: 300;
    }
    .cmx-widget24__number {
        color: #9699a2;
        float: right;
        margin-right: 1.8rem;
        margin-bottom: 2.86rem;
        font-size: 1rem;
        font-weight: 300;
    }
}

.cmx-event-done {
    background: #f2f2f2 !important;
    span {
        text-decoration: line-through;
        color: #cccccc !important;
    }
}

.cmx-fc-event--bordered {
    border: 1px solid $comexport-blue !important;
}

// LOGIN
.m-login__logo img {
    width: 80%;
}

.m-topbar-cmx__username {
    vertical-align: middle;
    display: table-cell;
    padding-right: 10px;
    color: #000000;
}

.footer {
    height: 50px;
    bottom: 0;
    position: fixed;
    display: block;
    background-color: white;
    width: 100%;
    visibility: hidden;
    display: none;
    opacity: 0;
}
.footer.show {
    visibility: visible;
    display: block;
    opacity: 1;
}
.footer-content {
    position: absolute;
    bottom: 10px;
    right: 10px;
}

// TOP NAV
.m-header {
    box-shadow: 0px 1px 15px 2px rgba(black, 0.2);
}

.m-link.m-link--primary:hover:after {
    border-bottom: 4px solid rgba($grey-600, 0.7) !important;
    opacity: 1 !important;
}
.m-link.m-link--quotation:hover:after {
    border-bottom: 4px solid rgba($grey-600, 0.7) !important;
    opacity: 1 !important;
    width: calc(100% + 30px);
}
.m-link.m-link--finance:hover:after {
    border-bottom: 4px solid rgba($grey-600, 0.7) !important;
    opacity: 1 !important;
    width: calc(100% + 44px);
}

.m-stack__item.m-brand {
    box-shadow: 0px 1px 15px 1px rgba(113, 106, 202, 0.1);
}

.m-card-user {
    width: 100%;
}

.m-card-user__email_customer,
.m-card-user__email_customer:hover {
    color: #ffffff;
}

.m-card-user__email_customer:hover:after {
    border-bottom: 1px solid #ffffff;
    opacity: 0.3;
    filter: alpha(opacity=30);
}

.m-card-user__email_customer {
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.m-dropdown__arrow_customer {
    color: #000000;
}

.m-spinner--hidden {
    display: none;
}

.m-brand {
    background-color: white !important;
}

.m-loader.cmx-loader--left.btn {
    padding-left: 2.44rem !important;

    &.m-btn--icon.m-btn--icon-only {
        padding-left: 0 !important;

        &::before {
            left: 1.1rem !important;
        }
    }

    &.btn-outline-danger:before {
        border-top-color: #f4516c !important;
    }
}

.m-page-loader.m-page-loader--non-block {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin-top: auto;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: auto;
}

.btn.nested {
    padding: 0 1.25rem 0 0;
}

tbody tr.m-datatable__row {
    cursor: pointer;
}

.m-header-menu.m-header-menu--skin-light .m-menu__nav > .m-menu__item:hover > .m-menu__link .m-menu__link-icon {
    color: rgba($grey-600, 0.7) !important;
}

.m-header-menu.m-header-menu--skin-light .m-menu__nav > .m-menu__item:hover > .m-menu__link .m-menu__link-text {
    color: rgba($grey-600, 0.7) !important;
}

.m-body {
    background-color: #eeeeee;
    .m-content {
        height: 100%;
        padding: 0px 20px;
        padding-top: 10px;
        margin-bottom: 0px;
    }
}

.m-portlet {
    margin-bottom: 0px;
}

.m-footer--push.m-aside-left--enabled:not(.m-footer--fixed) .m-wrapper {
    margin-bottom: 0px;
}

.m-radio > span:after {
    border: 3px solid #7281a4;
}

//Cursor Pointer
.pointer {
    cursor: pointer;
}

// Container for chart components
.flex-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: flex-start;
    align-content: stretch;
}

// Component inside container
.flex-item {
    position: relative;
    -webkit-box-shadow: 2px 2px 20px -6px rgba($grey-600, 0.7);
    -moz-box-shadow: 2px 2px 20px -6px rgba($grey-600, 0.7);
    box-shadow: 2px 2px 20px -6px rgba($grey-600, 0.7);
    border-radius: 0.4%;
    background-color: #ffffff;
    padding: 25px 30px 10px 30px;
    width: 48%;
    margin-bottom: 20px;
    transition: width 0.3s;
    min-height: 200px;
    // Change size when calls function "resizeBtn()"
    &.full {
        width: 98%;
        transition: width 0.3s;
    }
    &:hover {
        -webkit-box-shadow: 3px 3px 21px -5px rgba($grey-600, 0.7);
        -moz-box-shadow: 3px 3px 21px -5px rgba($grey-600, 0.7);
        box-shadow: 3px 3px 21px -5px rgba($grey-600, 0.7);
    }
}

// Chart get max width inside container
.chart {
    width: 100%;
    height: 280px;
}

// Resize button positioned in northeast
.resizeBtn {
    z-index: 13;
    cursor: pointer;
    font-size: 20px !important;
    position: absolute;
    top: 15px;
    right: 45px;
    color: rgba(65, 64, 66, 0.3);
    // Change color when hover
    &:hover {
        color: rgba(65, 64, 66, 0.7);
    }
    &.solo {
        right: 12px;
    }
}

.moveBtn {
    cursor: move;
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
    margin-left: -30px;
    width: 100%;
    position: absolute;
    top: 0;
    height: 60px;
}
.moveBtn:hover .moveLine {
    background-color: rgba($grey-600, 0.5);
}

.moveLine {
    cursor: move;
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
    position: absolute;
    z-index: 10;
    top: 0px;
    width: 100%;
    height: 5px;
    background-color: rgba($grey-600, 0.3);
    &:hover {
        background-color: rgba($grey-600, 0.5);
    }
}

.editBtn {
    z-index: 13;
    cursor: pointer;
    font-size: 22px !important;
    position: absolute;
    top: 15px;
    right: 15px;
    color: rgba($grey-600, 0.3);
    // Change color when hover
    &:hover {
        color: rgba($grey-600, 0.7);
    }
}

.editModal {
    z-index: 12;
    margin-left: -30px;
    padding: 30px;
    position: absolute;
    bottom: 0;
    height: 100%;
    width: 100%;
    background-color: white;
    transition: height 0.5s;
    .modalContent {
        opacity: 1;
        transition: opacity 1.2s, visibility 1.2s;
    }
    &.hidden {
        .modalContent {
            opacity: 0;
            visibility: hidden;
            transition: opacity 0.2s, visibility 0.2s;
        }
        padding: 0px;
        height: 0px;
        transition: height 0.6s, padding 1s;
    }
}
.switchCr {
    position: relative;
    display: inline-block;
    width: 76px;
    height: 34px;
}
.switchCr input {
    display: none;
}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}
.switch input {
    display: none;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba($grey-600, 0.4);
    -webkit-transition: 0.4s;
    transition: 0.4s;
}
.slider:before {
    position: absolute;
    content: '';
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

input:checked + .slider {
    background-color: rgba($grey-600, 0.6);
}
input:focus + .slider {
    box-shadow: 0 0 1px #a19add;
}
input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

circle,
g {
    cursor: pointer;
}

.nopadding {
    padding: 0;
}

.ui-widget-content {
    background: white !important;
}

.ui-widget-content.slick-row.even {
    background: #f1f1f19e !important;
}

.slick-resizable-handle {
    width: 12px;
    z-index: 2;
}

.slick-column-name {
    font-weight: 700;
}

.datepicker {
    z-index: 99999999 !important;
}

.contentLoader {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 99999;
    background-color: rgba(128, 128, 128, 0.534);
}

.fullLoader {
    top: 0px;
    left: 0px;
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 99999;
    background-color: rgba(128, 128, 128, 0.534);
    text-align: center;
    img {
        position: fixed;
        top: -20%;
        bottom: 0;
        left: 0;
        right: 0;
        margin-top: auto;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: auto;
        max-width: 20vw;
        z-index: 99999;
        display: block !important;
    }
}

.mainContainerPage {
    position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;
    margin-left: auto;
    margin-right: auto;
    width: calc(100% - 40px);
    height: calc(100% - 90px);
    background-color: white;
    border-top: 5px solid grey;
    box-shadow: 0px 1px 15px 1px rgba(113, 106, 202, 0.08);
}

.exporting {
    font-weight: 500;
    position: absolute;
    color: #e9e9e9;
    top: 45%;
    bottom: 0;
    left: 0;
    right: 0;
    margin-top: auto;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: auto;
    display: block !important;
}

.modal {
    z-index: 102;
}

.modal-backdrop {
    z-index: 101;
}

::-webkit-scrollbar {
    height: 8px;
    width: 8px;
}

::-webkit-scrollbar-thumb {
    background: #e1e1e1;
    border-radius: 50px;
}

::-webkit-scrollbar-thumb:hover {
    background: #adadad;
}

::-webkit-scrollbar-thumb:active {
    background: #929292;
}

::-webkit-scrollbar-track {
    background: #ffffff;
    border: 0px none #ffffff;
    border-radius: 27px;
}

::-webkit-scrollbar-track:hover {
    ::-webkit-scrollbar-thumb {
        background: #adadad;
    }
}

::-webkit-scrollbar-corner {
    background: transparent;
}

.valueFont {
    font-family: Calibri, Ubuntu, sans-serif !important;
}

.selector {
    position: relative;
    border: 2px solid #eee;
    border-radius: 6px;
    outline: none;
    -webkit-tap-highlight-color: transparent;
    padding: 12px 12px 12px 12px;
    background-color: white;
    color: grey;
    min-width: 220px;
    width: 100%;
    cursor: pointer;
    padding-right: 20px;
}

.options {
    border-radius: 6px;
    margin-top: 5px;
    border-top: 2px solid grey;
    box-shadow: 0px 1px 15px 2px rgba(113, 106, 202, 0.3);
    z-index: 20;
    position: absolute;
    margin: 0;
    overflow: auto;
    background: white;
    -webkit-padding-start: 0px !important;
    width: 220px;
    height: 200px;
    display: block;
    &.hidden {
        display: none;
    }
}

.options > li {
    width: 220px;
    list-style-type: none;
    background-color: white;
    line-height: 25px;
    font-size: 14px;
    padding: 10px 10px;
    cursor: pointer;
}

.options > li:hover {
    background: #eee;
}

.options::-webkit-scrollbar {
    width: 6px;
}

.options::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
}

.options::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
}

.select-icon {
    position: absolute;
    right: 10px;
    top: 15px;
}

#contextMenu {
    border-radius: 10px;
    background: white;
    border: 1px solid gray;
    padding: 10px;
    display: inline-block;
    min-width: 100px;
    -moz-box-shadow: 2px 2px 2px silver;
    -webkit-box-shadow: 2px 2px 2px silver;
    z-index: 99999;
}

#contextMenu li {
    padding: 4px 4px 4px 14px;
    list-style: none;
    cursor: pointer;
}

#contextMenu li:hover {
    background-color: rgb(233, 233, 233);
}

.dashTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: stretch;
    width: 100%;

    .loaderModel4 {
        top: -3px;
        left: 10px;
        .cube {
            margin: 2px;
            padding: 4px 4px;
            width: 4px;
            height: 4px;
        }
    }
    .dashTitleComponents {
        display: flex;
        justify-content:flex-end;
        align-items: center;
        width: calc(100vw - 180px);
    }
}

.toogleContainer {
    z-index: 1;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    width: 130px;
    height: 29px;
    border-radius: 10px;
    border: 1px solid #ced4da;
    .toogleContent {
        cursor: pointer;
        background-color: white;
        position: absolute;
        width: 200%;
        top: 0;
        bottom: 0;
        left: 0;
        transition: left 0.35s;
        .toogleLeft,
        .toogleRight {
            cursor: pointer;
            margin-top: 3px;
            border-radius: 0;
            text-align: center;
        }
        .toogleLeft {
            width: 43%;
            margin-left: 0px;
            transition: margin-left 0.4s;
            &.clicked {
                transition: margin-left 0.4s;
                margin-left: -132px;
            }
        }
        .toogleRight {
            width: 40%;
        }
        .middleHandler {
            display: inline-block;
            text-align: center;
            white-space: nowrap;
            vertical-align: middle;
            padding: 10px 16px;
            background-color: #e6e6e6;
            position: relative;
            margin: 0 auto;
            padding-top: 0px;
            padding-bottom: 0px;
            height: 110%;
            width: 20px;
            &:hover {
                background-color: #919191;
            }
        }
    }
}

.checkmark-container {
    position: absolute;
    top: 0px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: baseline;
    left: 160px;
    &.withBorderTop {
        top: -5px;
    }
    .checkmark {
        position: relative;
        border-radius: 0px;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
        font-size: 12px;
        line-height: 15px;
        margin: 0px 4px;
        padding: 6px 16px 6px 16px;
        background-color: #e9ecef;
        font-weight: 500;
        cursor: pointer;
        transition: padding 1s;
        img {
            height: 1.2vw;
            width: auto;
            pointer-events: none;
        }
        .tooltip-text {
            position: absolute;
            color: grey;
            font-size: 10px;
            left: 0;
            bottom: -20px;
            text-align: center;
            width: 300%;
            margin-left: -100%;
            visibility: hidden;
        }
        &.checked {
            transition: padding 1s;
            border-bottom: 4px solid #14509b;
            padding: 7px 12px;
            .tooltip-text {
                bottom: -25px;
            }
            &#programado {
                border-bottom: 4px solid #b93caf !important;
                color: #b93caf !important;
            }
            &#pdi {
                border-bottom: 4px solid #a8cf45 !important;
                color: #a8cf45 !important;
            }
            &#ano-corrente,
            &#iniciado {
                border-bottom: 4px solid #68217a !important;
                color: #68217a !important;
            }
            &#doze-meses,
            &#embarque {
                border-bottom: 4px solid rgb(41, 180, 115);
                color: rgb(41, 180, 115);
            }
            &#mes-corrente,
            &#transito {
                border-bottom: 4px solid rgb(255, 128, 10);
                color: rgb(255, 128, 10);
            }
            &#mes-anterior,
            &#carga {
                border-bottom: 4px solid #15519b;
                color: #15519b;
            }
            &#dez-dias,
            &#registro,
            &#registro_da {
                border-bottom: 4px solid rgb(206, 10, 10);
                color: rgb(206, 10, 10);
            }
            &#trinta-dias,
            &#desembaraco,
            &#desembaraco_da {
                border-bottom: 4px solid #6b3922;
                color: #6b3922;
            }
            &#noventa-dias,
            &#faturamento {
                border-bottom: 4px solid #e89600;
                color: #e89600;
            }
            &#entrega,
            &#bloqueado {
                border-bottom: 4px solid #b83dad;
                color: #b83dad;
            }
            &#faturado {
                border-bottom: 4px solid #e8c658;
                color: #e8c658;
            }
            &#vpcend {
                border-bottom: 4px solid #a8cf45;
                color: #a8cf45;
            }
            &#dispfaturamento {
                border-bottom: 4px solid #585570;
                color: #585570;
            }
            &#finalizado {
                border-bottom: 4px solid #409bbf;
                color: #409bbf;
            }
        }
        &:hover {
            .tooltip-text {
                visibility: visible;
            }
        }
        &.text {
            padding: 12px 15px 8px 15px;
            &.checked {
                padding: 16px 15px 8px 15px;
            }
        }
        &.custom {
            border-bottom: 4px solid grey;
            height: 100%;
            transition: margin-bottom 1.2s, padding 1.2s, height 1.2s ease-in-out;
            &.hidden {
                margin-bottom: 0px;
                padding: 0px;
                height: 0%;
                display: none;
                opacity: 0;
            }
        }
    }
    &.left-menu {
        flex-direction: column;
        font-size: 12.5px;
        position: absolute;
        left: 0px;
        bottom: 10px;
        top: auto;
        .checkmark {
            margin: 0px;
            border-radius: 0px;
            font-weight: 100;
            cursor: pointer;
            margin-bottom: 6px;
            padding: 5px 10px;
            background-color: #e9ecef;
            border-top-right-radius: 15px;
            border-bottom-right-radius: 15px;
            &.checked,
            &.custom {
                border: 0px;
                border-left: 4px solid grey;
            }
            &:hover {
                background-color: #ced4da;
            }
        }
    }
    &.reduzed {
        .checkmark {
            padding: 8px 18px 8px 18px;
            img {
                height: 16px;
            }
            .tooltip-text {
                bottom: -18px;
            }
            &.checked {
                .tooltip-text {
                    bottom: -20px;
                }
            }
        }
        &.subCheckmark {
            top: -10px;
            left: 10px;
            .checkmark {
                padding: 6px 15px 6px 15px;
                img {
                    height: 14px;
                }
            }
        }
        .checked {
            border-bottom: 4px solid #14509b;
        }
    }
}

.filterBall {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(231, 231, 231);
    width: 30px;
    height: 30px;
    border-radius: 100%;
    margin: 0px 3px;
    cursor: pointer;
    position: relative;
    i {
        font-size: 17px;
        pointer-events: none;
    }
    img {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        pointer-events: none;
        height: 50%;
        width: auto;
    }
    &:hover {
        background-color: rgb(223, 223, 223);
    }
    &.selected {
        background-color: rgb(185, 185, 185);
        &:hover {
            background-color: rgb(151, 151, 151);
        }
    }
}

#searchLine,
#infoLine {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    align-content: stretch;
    margin-bottom: 20px;
    height: 30px;
    &.statusComponent {
        margin: 20px 0;
    }
    .total {
        margin-bottom: 6px;
        padding: 5px 10px;
        background-color: #e9ecef;
        border-top-right-radius: 15px;
        border-bottom-right-radius: 15px;
        cursor: default;
        margin-left: -30px;
        padding-left: 30px;
        padding-right: 20px;
        margin-right: 15px;
        b {
            font-weight: 600;
        }
    }
    .search-container {
        position: relative;
        display: flex;
        .searchInput {
            position: relative;
            height: 28px;
        }
        .applyImpStatus.first,
        .applyChassiStatus.first {
            margin: 0px 15px 0px -35px;
        }
        .disableSearch {
            position: absolute;
            height: 100%;
            width: 100%;
            background-color: rgba(128, 128, 128, 0.15);
            z-index: 99;
            border-radius: 15px 15px 15px 15px;
            span {
                position: absolute;
                left: -25px;
                top: 0;
                bottom: 0;
                margin: auto;
                color: #c8b92c;
                font-size: 22px;
                cursor: default;
            }
        }
        .searchOptions {
            position: absolute;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-around;
            align-items: baseline;
            align-content: stretch;
            width: calc(100% - 44px);
            background-color: white;
            padding-top: 28px;
            border-radius: 15px;
            box-shadow: 2px 2px 20px -6px rgba(65, 64, 66, 0.7);
            .searchItem {
                cursor: pointer;
                text-align: center;
                width: 50%;
                padding: 5px;
                &.full {
                    width: 100%;
                    border-top: 1px solid #ced4da;
                }
                &.selected {
                    font-weight: 600;
                }
            }
            &.hidden {
                box-shadow: 0px 0px 0px 0px rgba(65, 64, 66, 0.7);
                padding-top: 0px;
                display: none;
                .searchItem {
                    padding: 0px;
                }
            }
        }
    }
    .datepicker-invalid {
        margin-top: 5px;
        color: red;
        font-size: 13px;
        margin-left: 10px;
    }
    .date-impStatusTable {
        border-color: #ced4da;
        border-radius: 20px;
        margin-left: -15px;
        font-size: 12px;
        padding-top: 12px;
    }
    .input-group-addon {
        background-color: #f4f5f8;
    }
    .input-group-addon.first {
        margin-left: -11px;
        margin-right: -11px;
    }
    .input-group {
        width: 160px;
        height: 23px;
        margin-left: -14px;
        .input-group-addon {
            padding: 10px 20px;
            background-clip: unset;
        }
        &.right {
            margin-left: -15px;
            width: 175px;
            .input-group-addon {
                padding: 10px 21px 10px 31px;
            }
        }
        .form-control:focus {
            border-color: grey;
            color: #575962;
        }
    }
    & .dropdown-list {
        padding-top: 4px;
    }
    & .cmx-dropdown {
        ul {
            max-height: 255px !important;
        }
        & .arrow-up {
            border: 0px;
        }
        z-index: 2;
        width: 290px;
    }
    .applyImpStatus {
        cursor: pointer;
        height: 23px;
        border: 0px;
        border-radius: 20px;
        margin-left: -23px;
        margin-top: 0px;
        color: grey;
        padding-left: 30px;
        padding-right: 8px;
        align-items: center;
        display: flex;
        align-items: center;
        justify-content: center;
        &.dropdownSearch {
            padding-left: 10px;
            padding-right: 26px;
            margin-left: 0px;
            margin-right: -40px;
            position: absolute;
            top: 0;
            left: 0px !important;
        }
        &:hover {
            color: #495057;
        }
    }
}

#searchLine.reduzed {
    .filterMode {
        display: flex;
        align-items: center;
        justify-content: center;
        div {
            height: 25px;
            width: 25px;
            i {
                font-size: 16px;
            }
        }
    }
    .input-group {
        height: 23px;
        width: auto;
        .right {
            margin-left: -25px;
        }
        .first {
            height: 23px !important;
            margin-left: 0px;
            margin-right: 0px;
            padding: 10px 20px 10px 10px;
        }
        .form-control {
            width: 110px !important;
            font-size: 12px;
            padding-top: 10px;
        }
        .year-input input {
            width: 80px !important;
        }
    }
    .input-group-addon {
        height: 23px !important;
        border-radius: 20px;
        border-color: #ebedf2;
        font-size: 12px;
        padding: 10px 20px !important;
        span {
            font-size: 12px !important;
        }
    }
    .applyImpStatus,
    .applyReport {
        display: flex;
        align-items: center;
        height: 23px;
        padding-left: 33px;
        margin-left: -30px;
        padding-right: 7px;
        .fa {
            font-size: 13px;
        }
    }
    .Zebra_DatePicker_Icon_Wrapper {
        .material-icons {
            top: 5px !important;
            font-size: 15px;
        }
    }
    .toogleContainer {
        height: 23px;
        width: 110px;
        .toogleContent {
            top: -2px;
            label {
                font-size: 11px;
                &.toogleLeft.clicked {
                    margin-left: -117px;
                }
            }
            .toogleLeft {
                &.reclicked {
                    margin-left: -110%;
                }
            }
            .toogleMiddle {
                width: 36%;
            }
        }
    }
    .c-token {
        display: flex;
    }

    #modal {
        .cmx-dropdown {
            width: 110px;
        }
    }
    #fieldSearch,
    #impSearch,
    #chassiSearch,
    #skuSearch,
    #startDatePicker,
    #endDatePicker,
    #invoiceSearch {
        height: 23px;
        font-size: 12px;
    }
    .search-container {
        height: 23px !important;
        .search {
            &::placeholder {
                font-size: 12px !important;
            }
        }
        .applyImpStatus.first {
            margin: 0px 15px 0px -30px !important;
        }
        .applyChassiStatus {
            height: 23px !important;
        }
    }
    .dropdownSearch {
        margin-left: 0px !important;
        .fa {
            pointer-events: none;
            position: absolute;
            top: 0;
            left: 10px;
            bottom: 0;
            margin: auto;
            height: max-content;
            height: fit-content;
        }
    }
}

#multi-select-component {
    width: 100%;

    & .dropdown-list {
        padding-top: 4px;
    }
    & .cmx-dropdown {
        ul {
            max-height: 255px !important;
        }
        & .arrow-up {
            border: 0px;
        }
        z-index: 2;
        width: 100%;
    }
}

.filterMode {
    width: max-content !important;
    width: fit-content !important;
    margin-left: 5px !important;
    z-index: 2;
    div {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 28px;
        width: 28px;
        background-color: white;
        border: 1px solid #ced4da;
        border-radius: 100px;
        cursor: pointer;
        i {
            color: grey;
            font-size: 18px;
            margin-bottom: 2px;
        }
        &:hover {
            i {
                color: black;
            }
        }
    }
    &.clicked {
        div {
            background-color: #14355e;
            i {
                color: white;
            }
        }
    }
}

.c-list::-webkit-scrollbar-button {
    width: 10px;
    height: 10px;
}

.invisible {
    visibility: hidden;
    opacity: 0;
}

#fieldSearch,
#impSearch,
#impSearchTitle,
#chassiSearch,
#skuSearch,
#boardSearch,
#vesselSearch,
#invoiceSearch {
    height: 30px;
    margin-left: 25px;
    margin-right: 0px;
    margin-bottom: 10px;
    border: 1px solid #ced4da;
    color: $color-grey;
    border-radius: 20px;
    padding: 3px 10px;
    width: 125px;
    z-index: 2;
    position: relative;
    &:focus {
        border: 1px solid grey;
        color: grey;
    }
    &::placeholder {
        color: rgb(214, 214, 214);
    }
}

ul.pagination {
    display: flex;
    overflow: auto;
    white-space: nowrap;
    margin: 5px 0px;
    margin-right: 27px;
    padding-bottom: 2px;
}

ul.pagination li {
    display: inline;
}

ul.pagination li a {
    color: black;
    float: left;
    padding: 8px 16px;
    text-decoration: none;
}

ul.pagination li a.active {
    background-color: grey;
    color: white;
}

ul.pagination li a:hover:not(.active) {
    background-color: #ddd;
}

ul.pagination::-webkit-scrollbar,
.slick-viewport-left::-webkit-scrollbar,
.invoicesModal::-webkit-scrollbar,
.NFsModal::-webkit-scrollbar {
    height: 8px;
    width: 8px;
}

::-webkit-scrollbar-thumb {
    background: rgb(110, 110, 110);
    border-radius: 50px;
}

::-webkit-scrollbar-thumb:hover {
    cursor: pointer !important;
    background: #38467c;
}

::-webkit-scrollbar-track,
::-webkit-scrollbar-track {
    background: #ffffff;
    border: 0px;
    border-radius: 27px;
}

ul.pagination::-webkit-scrollbar-track:hover {
    ::-webkit-scrollbar-thumb {
        background: #adadad;
    }
}

ul.pagination::-webkit-scrollbar-corner,
.slick-viewport-left::-webkit-scrollbar-corner {
    background: transparent;
}

.right-align {
    text-align: right;
}

.reloadContainer {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    &.left,
    &.right {
        width: 50%;
    }
    &.right {
        left: auto;
        right: 0;
    }
}
.reloadContent {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    i {
        font-size: 8vh;
        color: #d8d8d8;
        cursor: pointer;
        &:hover {
            color: #b6b6b6;
        }
    }
}

#customDashBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px 18px;
    border: none;
    border-radius: 100px;
    background-color: #0091d6;
    color: white;
    box-shadow: 2px 2px 20px -6px rgba(65, 64, 66, 0.7);
    margin-top: 0px !important;
    margin-left: 15px;
    cursor: pointer;
    &:hover {
        background-color: #006696;
    }
    i {
        margin-right: 5px;
    }
}

.noContent {
    color: $red-600;
    font-weight: 500;
    width: 100%;
    text-align: center;
    margin-top: 20px;
}

// Cores para Veículos
.ball,
.colorBall {
    &.branco {
        border: 1px solid #b2b2b2;
        background-color: white;
    }
    &.cinza {
        background-color: #a4a4a4;
    }
    &.prata {
        background-color: #c9c9c9;
    }
    &.vermelho {
        background-color: #a80000;
    }
    &.marrom {
        background-color: #866363;
    }
    &.azul {
        background-color: rgb(0, 47, 150);
    }
    &.preto {
        background-color: black;
    }
    &.laranja {
        background-color: orangered;
    }
    &.bege {
        background-color: #af9595;
    }
    &.dourado {
        background-color: #b76e79;
    }
    &.bronze {
        background-color: #b76e79;
    }
    &.roxo {
        background-color: purple;
    }
    &.verde {
        background-color: #006e06;
    }
    &.amarelo {
        background-color: #ffeb3b;
    }
    &.cobre {
        background-color: #977508;
    }
    &.brancopolar {
        background-color: rgb(236, 236, 236);
    }
    &.vermelhovolcano {
        background-color: rgb(128, 0, 0);
    }
    &.pretoatitude {
        background-color: rgb(26, 26, 26);
    }
    &.cinzagranito {
        background-color: rgb(116, 116, 116);
    }
    &.marromambar {
        background-color: rgb(92, 60, 60);
    }
    &.pratanevoa {
        background-color: rgb(197, 197, 197);
    }
}

.ImpValue {
    font-size: 11px !important;
    cursor: pointer;
    font-weight: 600;
    font-family: Calibri, Ubuntu, sans-serif !important;
    padding: 0px 12px;
    border-radius: 20px;
    background-color: grey;
    color: white;
    &:hover {
        background-color: rgb(97, 97, 97);
    }
}

// Filters
.input-group-addon {
    background-clip: unset !important;
}
// Search
.search-container {
    button {
        background-color: $color-blue-opaque !important;
    }
}
input {
    &.search {
        border: 1px solid $color-blue-light-grey !important;
        &::placeholder {
            color: $color-grey !important;
        }
    }
}
// Toogle
.toogleContainer {
    border: 1px solid $color-blue-light-grey !important;
}
// DatePicker
.Zebra_DatePicker_Icon_Wrapper {
    input {
        border: 1px solid $color-blue-light-grey !important;
    }
}


@media only screen and (max-width: 1024px) {
    .resizeBtn {
        visibility: hidden;
    }
    .flex-item {
        width: 98%;
    }
}

@media only screen and (max-width: 768px) {
    .flex-item {
        width: 98%;
    }
}

//Keyframes
@-webkit-keyframes changeColor {
    0% {
        filter: hue-rotate(30);
    }
    50% {
        filter: hue-rotate(180deg);
    }
    100% {
        filter: hue-rotate(360deg);
    }
}

@keyframes changeColor {
    0% {
        filter: hue-rotate(30);
    }
    50% {
        filter: hue-rotate(180deg);
    }
    100% {
        filter: hue-rotate(360deg);
    }
}

@-webkit-keyframes cubeDelay {
    0%,
    70%,
    100% {
        -webkit-transform: scale3D(1, 1, 1);
        transform: scale3D(1, 1, 1);
    }
    35% {
        -webkit-transform: scale3D(0, 0, 1);
        transform: scale3D(0, 0, 1);
    }
}

@keyframes cubeDelay {
    0%,
    70%,
    100% {
        -webkit-transform: scale3D(1, 1, 1);
        transform: scale3D(1, 1, 1);
    }
    35% {
        -webkit-transform: scale3D(0, 0, 1);
        transform: scale3D(0, 0, 1);
    }
}

@-webkit-keyframes rotate {
    0% {
        transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    }
    50% {
        transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    }
    100% {
        transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    }
}

@keyframes rotate {
    0% {
        transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    }
    50% {
        transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    }
    100% {
        transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    }
}

//Loader Model 4
.loaderModel4 {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin-top: auto;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: auto;
    width: 120px;
    height: 7px;
}

.loaderModel4 .cube {
    margin: 3px;
    padding: 7px 7px;
    width: 7px;
    height: 7px;
    background-color: #dddddd;
    float: left;
    -webkit-animation: cubeDelay 1.1s infinite ease-in-out, changeColor 2s linear infinite;
    animation: cubeDelay 1.1s infinite ease-in-out, changeColor 2s linear infinite;
}

.loaderModel4 {
    & .cube1 {
        -webkit-animation-delay: 0.2s;
        animation-delay: 0.2s;
    }
    & .cube2 {
        -webkit-animation-delay: 0.3s;
        animation-delay: 0.3s;
    }
    & .cube3 {
        -webkit-animation-delay: 0.4s;
        animation-delay: 0.4s;
    }
    & .cube4 {
        -webkit-animation-delay: 0.5s;
        animation-delay: 0.5s;
    }
    & .cube5 {
        -webkit-animation-delay: 0.6s;
        animation-delay: 0.6s;
    }
    & .cube6 {
        -webkit-animation-delay: 0.7s;
        animation-delay: 0.7s;
    }
}

h5 {
    font-size: 15px;
}

ng2-completer {

    .completer-holder {
      display: block;
      border: 1px solid $color-grey;
      transition: border-color 0.3s ease;
      border-radius: 25px;

      input {
        width: 100%;
        border: none;
        background: transparent;
        padding: 8px 10px;

        &::placeholder {
          color: $color-grey;
        }

        &:focus-within {
          border-color: $color-grey-dark;
        }
      }
    }

    &.disabled-input {
      .completer-holder {
        border-color: $color-grey-light;

        input {
          color: $color-grey-light;

          &::placeholder {
            color: $color-grey-light;
          }
        }
      }
    }
  }

  .completer-dropdown {
    width: calc(100% - 31px) !important;
    padding: 0px !important;
    margin: 7px 0 0 0 !important;
    background-color: transparent;
    border: 1px solid $color-grey-light !important;
    border-radius: 25px !important;
    overflow: hidden !important;
  }
  .completer-searching {
    padding: 10px !important;
    margin: 0px;
    border: none;
  }
  .completer-no-results {
    padding: 10px !important;
    margin: 0px;
  }
  .completer-row {
    width: 100% !important;
    padding: 10px !important;
    margin: 0px !important;
    border-bottom: 1px dashed $color-grey-light2;
    transition: background-color 0.3s ease;
  }

  .completer-selected-row {
    padding: 10px !important;
    margin: 0px;
    color: black !important;
    background-color: $color-grey-opaque !important;
  }
  .no-padding-left {
    padding-left: 0;
  }
  input.form-input-date-component,
  input.form-input-text-component {
    width: 100%;
    border: none;
    background: transparent;
    display: block;
    border: 1px solid $color-grey !important;
    transition: border-color 0.3s ease;
    border-radius: 25px;
    padding: 8px 10px !important;

    &::placeholder {
      color: $color-grey;
    }

    &:focus-within {
      border-color: $color-grey-dark;
    }

    &:disabled {
      border-color: $color-grey-light;

      &::placeholder {
        color: $color-grey-light;
      }
    }
  }

  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #AAAAAA !important;
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #AAAAAA !important;
  }

  ::-ms-input-placeholder { /* Microsoft Edge */
    color: #AAAAAA !important;
  }
