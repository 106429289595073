//
//** Grid Nav Component
//

//==== Component Mixins ====//

//== Component Base
@mixin m-build-component--nav-grid-base($layout) {
    .m-nav-grid {
        padding:0;
        margin: 0; 
        display: table;
        table-layout: fixed;
        width: 100%;

        // nav row
        > .m-nav-grid__row {
            display: table-row;       

            // nav item 
            > .m-nav-grid__item {
                display: table-cell; 
                vertical-align: middle;   
                text-align: center;
                padding: array-get($layout, item, self, padding);

                &:hover {
                    text-decoration: none;
                }

                // nav icon
                .m-nav-grid__icon {
                    text-align: center;
                    font-size: array-get($layout, item, icon, font-size);
                }

                // nav text
                .m-nav-grid__text {
                    display: block;
                    line-height: 1;
                    text-align: center;
                    margin: array-get($layout, item, text, margin);
                    font-size: get-font-size(regular);
                    font-weight: get-font-weight(bolder);
                }
            }
        }
    }
}

//== Component Skin
@mixin m-build-component--nav-grid-skin($layout, $skin) {
    
    @include component-skin(m-nav-grid, $skin) {
        .m-nav-grid__row {
            .m-nav-grid__item {
                border-right: 1px solid get-color($skin, panel, '-');
                border-bottom: 1px solid get-color($skin, panel, '-');

                &:last-child {
                    border-right: 0;
                }

                .m-nav-grid__icon {
                    color: get-state-color(metal);
                }

                .m-nav-grid__text {                           
                    color: get-brand-color();      
                }                

                &:hover {
                    background: get-color($skin, panel, '----');

                    .m-nav-grid__icon {
                        color: get-color($skin, icon, '+');
                    }
                }   
            }

            &:last-child {
                .m-nav-grid__item {
                    border-bottom: 0;
                }
            }
        }
    }
}

//==== Generate Component ====//

//== Component Base
@include m-build-component--nav-grid-base( array-get($m--nav, grid) );

//== Component Skin - Light
@include m-build-component--nav-grid-skin( array-get($m--nav, grid), light);

//== Component Skin - Dark
@include m-build-component--nav-grid-skin( array-get($m--nav, grid), dark);
