$light-bg-color: rgba(255, 255, 255, 0.7);
$light-border-color: #a0b4cb;
$light-text-color: #000000;

$dark-bg-color: #16263F;
$dark-border-color: #16263F;
$dark-text-color: #ffffff;

.cmx-tooltip {
  position: absolute;
  font-size: 14px;
  text-align: center;
  padding: 4px 8px;
  border-radius: 4px;
  z-index: 99999 !important;
  opacity: 0;


  background: $light-bg-color;
  color: $light-text-color;
  border: 2px solid $light-border-color;


  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  &.md {
    font-size: 12px;
  }

  &.sm {
    font-size: 11px;
  }

  &-show {
    opacity: 1;
  }

  &-nowrap {
    white-space: nowrap;
    max-width: none;
  }

  &.dark-theme {
    background: $dark-bg-color;
    color: $dark-text-color;
    border: 1px solid $dark-border-color;

    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);

    &-top:after {
      border-color: $dark-border-color transparent transparent transparent;
    }

    &-right:after {
      border-color: transparent $dark-border-color transparent transparent;
    }

    &-bottom:after {
      border-color: transparent transparent $dark-border-color transparent;
    }

    &-left:after {
      border-color: transparent transparent transparent $dark-border-color;
    }
  }
}
