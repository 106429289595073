body {
	&.m-page--boxed {
		background-attachment: fixed;
		background-repeat: no-repeat;
		background-size: cover;
	}	
}

.m-page {	
	// hide page when page loading is enabled
	.m-page--loading & {
		display: none;
	}
}	

@media (min-width: array-get($m--layout-breakpoints, xxl)) {
	.m-page--boxed {
		.m-page {
			width: array-get($m--container-widths, xxl);
			margin-left: auto;
			margin-right: auto;
		}	
	}
}