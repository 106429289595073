//
//** Forms
// 

.custom-select {
	-webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.custom-file-control:after {
    float: left;
}