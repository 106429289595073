//
//** Chart Widget 2 Component
//

//== Component Base
@mixin m-build-component--widget15-base() {
     // Widget 6
    .m-widget15 { 
        // Chart
        .m-widget15__chart {
            margin-top: 1rem;
            margin-bottom: 3.5rem;
            position: relative;

            canvas {
                @include border-radius(8px);
            } 
        }

        // Items
        .m-widget15__items {            
            // Item
            .m-widget15__item {
                // Stats
                .m-widget15__stats {
                    font-size: get-font-size(regular, '+');
                    font-weight: get-font-weight(boldest);                      
                }
                
                // Text
                .m-widget15__text {
                    font-size: get-font-size(regular, '-');
                    font-weight: get-font-weight(regular);
                    float: right;
                    margin-top: 0.3rem;
                }

                // Progress
                .progress {
                    margin-bottom: 1.6rem;
                    .progress-bar {

                    }
                }    
            }            
        }
        // Description
        .m-widget15__desc {
            margin-top: 1.6rem;
            font-size: get-font-size(regular);
            font-weight: get-font-weight(regular);
        }        
    }     	 
} 
 

//== Component Skin
@mixin m-build-component--widget15-skin() {
    $skin: get-default-skin();    

    .m-widget15 {
    	.m-widget15__items {
            .m-widget15__stats {
                color: get-color($skin, regular, '-');
            }
    		.m-widget15__text {
                color: get-color($skin, regular, '----');
            }
    	}
    }
}

//== Build Component Base
@include m-build-component--widget15-base();

//== Build Component - Light Skin
@include m-build-component--widget15-skin();
                        