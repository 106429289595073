@charset "UTF-8";

@import './app/theme/layouts/styles/variables.scss';

:root {
    --surface-a:#ffffff;
    --surface-b:#efefef;
    --surface-c:#e9ecef;
    --surface-d:#dee2e6;
    --surface-e:#ffffff;
    --surface-f:#ffffff;
    --text-color:#212529;
    --text-color-secondary:#6c757d;
    --primary-color: $color-blue-light;
    --primary-color-text:#ffffff;
    --font-family: Poppins, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
    --surface-0: #ffffff;
    --surface-50: #f9fafb;
    --surface-100: #f8f9fa;
    --surface-200: #e9ecef;
    --surface-300: #dee2e6;
    --surface-400: #ced4da;
    --surface-500: #adb5bd;
    --surface-600: #6c757d;
    --surface-700: #495057;
    --surface-800: #343a40;
    --surface-900: #212529;
    --content-padding:1.25rem;
    --inline-spacing:0.5rem;
}

* {
    box-sizing: border-box;
}

.p-component {
    font-family: Poppins, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 1rem;
    font-weight: normal;
}

.p-component-overlay {
    background-color: rgba(0, 0, 0, 0.4);
    transition-duration: 0.15s;
}

.p-disabled, .p-component:disabled {
    opacity: 0.65;
}

.p-error {
    color: #dc3545;
}

.p-text-secondary {
    color: #6c757d;
}

.pi {
    font-size: 1rem;
}

.p-link {
    font-size: 1rem;
    font-family: Poppins, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    border-radius: 4px;
}
.p-link:focus {
    outline: 0 none;
    outline-offset: 0;

}

:root {
    --blue-50:#f3f8ff;
    --blue-100:#c5dcff;
    --blue-200:#97c1fe;
    --blue-300:#69a5fe;
    --blue-400:#3b8afd;
    --blue-500:#0d6efd;
    --blue-600:#0b5ed7;
    --blue-700:#094db1;
    --blue-800:#073d8b;
    --blue-900:#052c65;
    --green-50:#f4f9f6;
    --green-100:#c8e2d6;
    --green-200:#9ccbb5;
    --green-300:#70b595;
    --green-400:#459e74;
    --green-500:#198754;
    --green-600:#157347;
    --green-700:#125f3b;
    --green-800:#0e4a2e;
    --green-900:#0a3622;
    --yellow-50:#fffcf3;
    --yellow-100:#fff0c3;
    --yellow-200:#ffe494;
    --yellow-300:#ffd965;
    --yellow-400:#ffcd36;
    --yellow-500:#ffc107;
    --yellow-600:#d9a406;
    --yellow-700:#b38705;
    --yellow-800:#8c6a04;
    --yellow-900:#664d03;
    --cyan-50:#f3fcfe;
    --cyan-100:#c5f2fb;
    --cyan-200:#97e8f9;
    --cyan-300:#69def6;
    --cyan-400:#3bd4f3;
    --cyan-500:#0dcaf0;
    --cyan-600:#0baccc;
    --cyan-700:#098da8;
    --cyan-800:#076f84;
    --cyan-900:#055160;
    --pink-50:#fdf5f9;
    --pink-100:#f5cee1;
    --pink-200:#eda7ca;
    --pink-300:#e681b3;
    --pink-400:#de5a9b;
    --pink-500:#d63384;
    --pink-600:#b62b70;
    --pink-700:#96245c;
    --pink-800:#761c49;
    --pink-900:#561435;
    --indigo-50:#f7f3fe;
    --indigo-100:#dac6fc;
    --indigo-200:#bd98f9;
    --indigo-300:#a06bf7;
    --indigo-400:#833df4;
    --indigo-500:#6610f2;
    --indigo-600:#570ece;
    --indigo-700:#470ba9;
    --indigo-800:#380985;
    --indigo-900:#290661;
    --teal-50:#f4fcfa;
    --teal-100:#c9f2e6;
    --teal-200:#9fe8d2;
    --teal-300:#75debf;
    --teal-400:#4ad3ab;
    --teal-500:#20c997;
    --teal-600:#1bab80;
    --teal-700:#168d6a;
    --teal-800:#126f53;
    --teal-900:#0d503c;
    --orange-50:#fff9f3;
    --orange-100:#ffe0c7;
    --orange-200:#fec89a;
    --orange-300:#feaf6d;
    --orange-400:#fd9741;
    --orange-500:#fd7e14;
    --orange-600:#d76b11;
    --orange-700:#b1580e;
    --orange-800:#8b450b;
    --orange-900:#653208;
    --bluegray-50:#f8f9fb;
    --bluegray-100:#e0e4ea;
    --bluegray-200:#c7ced9;
    --bluegray-300:#aeb9c8;
    --bluegray-400:#95a3b8;
    --bluegray-500:#7c8ea7;
    --bluegray-600:#69798e;
    --bluegray-700:#576375;
    --bluegray-800:#444e5c;
    --bluegray-900:#323943;
    --purple-50:#f8f6fc;
    --purple-100:#dcd2f0;
    --purple-200:#c1aee4;
    --purple-300:#a68ad9;
    --purple-400:#8a66cd;
    --purple-500:#6f42c1;
    --purple-600:#5e38a4;
    --purple-700:#4e2e87;
    --purple-800:#3d246a;
    --purple-900:#2c1a4d;
}

.p-autocomplete .p-autocomplete-loader {
    right: 0.75rem;
}
.p-autocomplete.p-autocomplete-dd .p-autocomplete-loader {
    right: 3.107rem;
}
.p-autocomplete .p-autocomplete-multiple-container {
    padding: 0.25rem 0.75rem;
}
.p-autocomplete .p-autocomplete-multiple-container:not(.p-disabled):hover {
    border-color: #ced4da;
}
.p-autocomplete .p-autocomplete-multiple-container:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;

    border-color: $color-blue-light;
}
.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token {
    padding: 0.25rem 0;
}
.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token input {
    font-family: Poppins, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 1rem;
    color: #212529;
    padding: 0;
    margin: 0;
}
.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token {
    padding: 0.25rem 0.75rem;
    margin-right: 0.5rem;
    background: $color-blue-light;
    color: #ffffff;
    border-radius: 25px;
}
.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token .p-autocomplete-token-icon {
    margin-left: 0.5rem;
}

p-autocomplete.ng-dirty.ng-invalid > .p-autocomplete > .p-inputtext {
    border-color: #dc3545;
}

.p-autocomplete-panel {
    background: #ffffff;
    color: #212529;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    box-shadow: none;
}
.p-autocomplete-panel .p-autocomplete-items {
    padding: 0.5rem 0;
}
.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item {
    margin: 0;
    padding: 0.5rem 1.5rem;
    border: 0 none;
    color: #212529;
    background: transparent;
    transition: box-shadow 0.15s;
    border-radius: 0;
}
.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item:hover {
    color: #212529;
    background: #e9ecef;
}
.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item.p-highlight {
    color: #ffffff;
    background: $color-blue-light;
}
.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item-group {
    margin: 0;
    padding: 0.75rem 1rem;
    color: #212529;
    background: #ffffff;
    font-weight: 600;
}

p-calendar.ng-dirty.ng-invalid > .p-calendar > .p-inputtext {
    border-color: #dc3545;
}

.p-datepicker {
    padding: 0;
    background: #ffffff;
    color: #212529;
    border: 1px solid #ced4da;
    border-radius: 4px;
}
.p-datepicker:not(.p-datepicker-inline) {
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: none;
}
.p-datepicker:not(.p-datepicker-inline) .p-datepicker-header {
    background: #efefef;
}
.p-datepicker .p-datepicker-header {
    padding: 0.5rem;
    color: #212529;
    background: #ffffff;
    font-weight: 600;
    margin: 0;
    border-bottom: 1px solid #dee2e6;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
}
.p-datepicker .p-datepicker-header .p-datepicker-prev,
.p-datepicker .p-datepicker-header .p-datepicker-next {
    width: 2rem;
    height: 2rem;
    color: #6c757d;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: box-shadow 0.15s;
}
.p-datepicker .p-datepicker-header .p-datepicker-prev:enabled:hover,
.p-datepicker .p-datepicker-header .p-datepicker-next:enabled:hover {
    color: #495057;
    border-color: transparent;
    background: transparent;
}
.p-datepicker .p-datepicker-header .p-datepicker-prev:focus,
.p-datepicker .p-datepicker-header .p-datepicker-next:focus {
    outline: 0 none;
    outline-offset: 0;

}
.p-datepicker .p-datepicker-header .p-datepicker-title {
    line-height: 2rem;
}
.p-datepicker .p-datepicker-header .p-datepicker-title select {
    transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
}
.p-datepicker .p-datepicker-header .p-datepicker-title select:focus {
    outline: 0 none;
    outline-offset: 0;

    border-color: $color-blue-light;
}
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
    margin-right: 0.5rem;
}
.p-datepicker table {
    font-size: 1rem;
    margin: 0.5rem 0;
}
.p-datepicker table th {
    padding: 0.5rem;
}
.p-datepicker table th > span {
    width: 2.5rem;
    height: 2.5rem;
}
.p-datepicker table td {
    padding: 0.5rem;
}
.p-datepicker table td > span {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 4px;
    transition: box-shadow 0.15s;
    border: 1px solid transparent;
}
.p-datepicker table td > span.p-highlight {
    color: #ffffff;
    background: $color-blue-light;
}
.p-datepicker table td > span:focus {
    outline: 0 none;
    outline-offset: 0;

}
.p-datepicker table td.p-datepicker-today > span {
    background: #ced4da;
    color: #212529;
    border-color: transparent;
}
.p-datepicker table td.p-datepicker-today > span.p-highlight {
    color: #ffffff;
    background: $color-blue-light;
}
.p-datepicker .p-datepicker-buttonbar {
    padding: 1rem 0;
    border-top: 1px solid #dee2e6;
}
.p-datepicker .p-datepicker-buttonbar .p-button {
    width: auto;
}
.p-datepicker .p-timepicker {
    border-top: 1px solid #dee2e6;
    padding: 0.5rem;
}
.p-datepicker .p-timepicker button {
    width: 2rem;
    height: 2rem;
    color: #6c757d;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: box-shadow 0.15s;
}
.p-datepicker .p-timepicker button:enabled:hover {
    color: #495057;
    border-color: transparent;
    background: transparent;
}
.p-datepicker .p-timepicker button:focus {
    outline: 0 none;
    outline-offset: 0;

}
.p-datepicker .p-timepicker button:last-child {
    margin-top: 0.2em;
}
.p-datepicker .p-timepicker span {
    font-size: 1.25rem;
}
.p-datepicker .p-timepicker > div {
    padding: 0 0.5rem;
}
.p-datepicker.p-datepicker-timeonly .p-timepicker {
    border-top: 0 none;
}
.p-datepicker .p-monthpicker {
    margin: 0.5rem 0;
}
.p-datepicker .p-monthpicker .p-monthpicker-month {
    padding: 0.5rem;
    transition: box-shadow 0.15s;
    border-radius: 4px;
}
.p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight {
    color: #ffffff;
    background: $color-blue-light;
}
.p-datepicker.p-datepicker-multiple-month .p-datepicker-group {
    border-right: 1px solid #dee2e6;
    padding-right: 0;
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 0;
}
.p-datepicker.p-datepicker-multiple-month .p-datepicker-group:first-child {
    padding-left: 0;
}
.p-datepicker.p-datepicker-multiple-month .p-datepicker-group:last-child {
    padding-right: 0;
    border-right: 0 none;
}
.p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover {
    background: #e9ecef;
}
.p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):focus {
    outline: 0 none;
    outline-offset: 0;

}
.p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-highlight):not(.p-disabled):hover {
    background: #e9ecef;
}
.p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-highlight):not(.p-disabled):focus {
    outline: 0 none;
    outline-offset: 0;

}

@media screen and (max-width: 769px) {
    .p-datepicker table th, .p-datepicker table td {
        padding: 0;
    }
}
.p-cascadeselect {
    background: #ffffff;
    border: 1px solid #ced4da;
    transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
    border-radius: 4px;
}
.p-cascadeselect:not(.p-disabled):hover {
    border-color: #ced4da;
}
.p-cascadeselect:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;

    border-color: $color-blue-light;
}
.p-cascadeselect .p-cascadeselect-label {
    background: transparent;
    border: 0 none;
    padding: 0.5rem 0.75rem;
}
.p-cascadeselect .p-cascadeselect-label.p-placeholder {
    color: #6c757d;
}
.p-cascadeselect .p-cascadeselect-label:enabled:focus {
    outline: 0 none;
    box-shadow: none;
}
.p-cascadeselect .p-cascadeselect-trigger {
    background: transparent;
    color: #495057;
    width: 2.357rem;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.p-cascadeselect-panel {
    background: #ffffff;
    color: #212529;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    box-shadow: none;
}
.p-cascadeselect-panel .p-cascadeselect-items {
    padding: 0.5rem 0;
}
.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item {
    margin: 0;
    border: 0 none;
    color: #212529;
    background: transparent;
    transition: box-shadow 0.15s;
    border-radius: 0;
}
.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item .p-cascadeselect-item-content {
    padding: 0.5rem 1.5rem;
}
.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item .p-cascadeselect-item-content:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.15rem rgba(38, 143, 255, 0.5);
}
.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item.p-highlight {
    color: #ffffff;
    background: $color-blue-light;
}
.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item:not(.p-highlight):not(.p-disabled):hover {
    color: #212529;
    background: #e9ecef;
}
.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item .p-cascadeselect-group-icon {
    font-size: 0.875rem;
}

p-cascadeselect.ng-dirty.ng-invalid > .p-cascadeselect {
    border-color: #dc3545;
}

.p-input-filled .p-cascadeselect {
    background: #efefef;
}
.p-input-filled .p-cascadeselect:not(.p-disabled):hover {
    background-color: #efefef;
}
.p-input-filled .p-cascadeselect:not(.p-disabled).p-focus {
    background-color: #efefef;
}

.p-checkbox-label {
    margin-left: 0.5rem;
}

.p-checkbox {
    width: 20px;
    height: 20px;
}

.p-checkbox .p-checkbox-box {
    border: 2px solid #ced4da;
    background: #ffffff;
    width: 20px;
    height: 20px;
    color: #212529;
    border-radius: 4px;
    transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
}
.p-checkbox .p-checkbox-box .p-checkbox-icon {
    transition-duration: 0.15s;
    color: #ffffff;
    font-size: 14px;
}
.p-checkbox .p-checkbox-box.p-highlight {
    border-color: $color-blue-light;
    background: $color-blue-light;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
    border-color: #ced4da;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
    outline: 0 none;
    outline-offset: 0;

    border-color: $color-blue-light;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
    border-color: #0062cc;
    background: #0062cc;
    color: #ffffff;
}

p-checkbox.ng-dirty.ng-invalid > .p-checkbox > .p-checkbox-box {
    border-color: #dc3545;
}

.p-input-filled .p-checkbox .p-checkbox-box {
    background-color: #efefef;
}
.p-input-filled .p-checkbox .p-checkbox-box.p-highlight {
    background: $color-blue-light;
}
.p-input-filled .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
    background-color: #efefef;
}
.p-input-filled .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
    background: #0062cc;
}

.p-highlight .p-checkbox .p-checkbox-box {
    border-color: #ffffff;
}

.p-chips .p-chips-multiple-container {
    padding: 0.25rem 0.75rem;
}
.p-chips .p-chips-multiple-container:not(.p-disabled):hover {
    border-color: #ced4da;
}
.p-chips .p-chips-multiple-container:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;

    border-color: $color-blue-light;
}
.p-chips .p-chips-multiple-container .p-chips-token {
    padding: 0.25rem 0.75rem;
    margin-right: 0.5rem;
    background: $color-blue-light;
    color: #ffffff;
    border-radius: 4px;
}
.p-chips .p-chips-multiple-container .p-chips-token .p-chips-token-icon {
    margin-left: 0.5rem;
}
.p-chips .p-chips-multiple-container .p-chips-input-token {
    padding: 0.25rem 0;
}
.p-chips .p-chips-multiple-container .p-chips-input-token input {
    font-family: Poppins, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 1rem;
    color: #212529;
    padding: 0;
    margin: 0;
}

p-chips.ng-dirty.ng-invalid > .p-chips > .p-inputtext {
    border-color: #dc3545;
}

.p-colorpicker-preview,
.p-fluid .p-colorpicker-preview.p-inputtext {
    width: 2rem;
    height: 2rem;
}

.p-colorpicker-panel {
    background: #212529;
    border-color: #212529;
}
.p-colorpicker-panel .p-colorpicker-color-handle,
.p-colorpicker-panel .p-colorpicker-hue-handle {
    border-color: #ffffff;
}

.p-colorpicker-overlay-panel {
    box-shadow: none;
}

.p-dropdown {
    background: #ffffff;
    border: 1px solid #ced4da;
    transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
    border-radius: 4px;
}
.p-dropdown:not(.p-disabled):hover {
    border-color: #ced4da;
}
.p-dropdown:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;

    border-color: $color-blue-light;
}
.p-dropdown.p-dropdown-clearable .p-dropdown-label {
    padding-right: 1.75rem;
}
.p-dropdown .p-dropdown-label {
    background: transparent;
    border: 0 none;
}
.p-dropdown .p-dropdown-label.p-placeholder {
    color: #6c757d;
}
.p-dropdown .p-dropdown-label:enabled:focus {
    outline: 0 none;
    box-shadow: none;
}
.p-dropdown .p-dropdown-trigger {
    background: transparent;
    color: #495057;
    width: 2.357rem;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}
.p-dropdown .p-dropdown-clear-icon {
    color: #495057;
    right: 2.357rem;
}

.p-dropdown-panel {
    background: #ffffff;
    color: #212529;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    box-shadow: none;
}
.p-dropdown-panel .p-dropdown-header {
    padding: 0.75rem 1.5rem;
    border-bottom: 1px solid #dee2e6;
    color: #212529;
    background: #efefef;
    margin: 0;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
}
.p-dropdown-panel .p-dropdown-header .p-dropdown-filter {
    padding-right: 1.75rem;
}
.p-dropdown-panel .p-dropdown-header .p-dropdown-filter-icon {
    right: 0.75rem;
    color: #495057;
}
.p-dropdown-panel .p-dropdown-items {
    padding: 0.5rem 0;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    margin: 0;
    padding: 0.5rem 1.5rem;
    border: 0 none;
    color: #212529;
    background: transparent;
    transition: box-shadow 0.15s;
    border-radius: 0;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    color: #ffffff;
    background: $color-blue-light;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
    color: #212529;
    background: #e9ecef;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-empty-message {
    padding: 0.5rem 1.5rem;
    color: #212529;
    background: transparent;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item-group {
    margin: 0;
    padding: 0.75rem 1rem;
    color: #212529;
    background: #ffffff;
    font-weight: 600;
}

p-dropdown.ng-dirty.ng-invalid > .p-dropdown {
    border-color: #dc3545;
}

.p-input-filled .p-dropdown {
    background: #efefef;
}
.p-input-filled .p-dropdown:not(.p-disabled):hover {
    background-color: #efefef;
}
.p-input-filled .p-dropdown:not(.p-disabled).p-focus {
    background-color: #efefef;
}

.p-editor-container .p-editor-toolbar {
    background: #efefef;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
}
.p-editor-container .p-editor-toolbar.ql-snow {
    border: 1px solid #dee2e6;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-stroke {
    stroke: #6c757d;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-fill {
    fill: #6c757d;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label {
    border: 0 none;
    color: #6c757d;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover {
    color: #212529;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover .ql-stroke {
    stroke: #212529;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover .ql-fill {
    fill: #212529;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
    color: #212529;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-stroke {
    stroke: #212529;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-fill {
    fill: #212529;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: none;
    border-radius: 4px;
    padding: 0.5rem 0;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options .ql-picker-item {
    color: #212529;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options .ql-picker-item:hover {
    color: #212529;
    background: #e9ecef;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded:not(.ql-icon-picker) .ql-picker-item {
    padding: 0.5rem 1.5rem;
}
.p-editor-container .p-editor-content {
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
}
.p-editor-container .p-editor-content.ql-snow {
    border: 1px solid #dee2e6;
}
.p-editor-container .p-editor-content .ql-editor {
    background: #ffffff;
    color: #495057;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
}
.p-editor-container .ql-snow.ql-toolbar button:hover,
.p-editor-container .ql-snow.ql-toolbar button:focus {
    color: #212529;
}
.p-editor-container .ql-snow.ql-toolbar button:hover .ql-stroke,
.p-editor-container .ql-snow.ql-toolbar button:focus .ql-stroke {
    stroke: #212529;
}
.p-editor-container .ql-snow.ql-toolbar button:hover .ql-fill,
.p-editor-container .ql-snow.ql-toolbar button:focus .ql-fill {
    fill: #212529;
}
.p-editor-container .ql-snow.ql-toolbar button.ql-active,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected {
    color: $color-blue-light;
}
.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-stroke,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke {
    stroke: $color-blue-light;
}
.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-fill,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill {
    fill: $color-blue-light;
}
.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-picker-label,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-picker-label,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-picker-label {
    color: $color-blue-light;
}

.p-inputgroup-addon {
    background: #e9ecef;
    color: #495057;
    border-top: 1px solid #ced4da;
    border-left: 1px solid #ced4da;
    border-bottom: 1px solid #ced4da;
    padding: 0.5rem 0.75rem;
    min-width: 2.357rem;
}
.p-inputgroup-addon:last-child {
    border-right: 1px solid #ced4da;
}

.p-inputgroup > .p-component,
.p-inputgroup > .p-float-label > .p-component {
    border-radius: 0;
    margin: 0;
}
.p-inputgroup > .p-component + .p-inputgroup-addon,
.p-inputgroup > .p-float-label > .p-component + .p-inputgroup-addon {
    border-left: 0 none;
}
.p-inputgroup > .p-component:focus,
.p-inputgroup > .p-float-label > .p-component:focus {
    z-index: 1;
}
.p-inputgroup > .p-component:focus ~ label,
.p-inputgroup > .p-float-label > .p-component:focus ~ label {
    z-index: 1;
}

.p-inputgroup-addon:first-child,
.p-inputgroup button:first-child,
.p-inputgroup input:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.p-inputgroup .p-float-label:first-child input {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.p-inputgroup-addon:last-child,
.p-inputgroup button:last-child,
.p-inputgroup input:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.p-inputgroup .p-float-label:last-child input {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.p-fluid .p-inputgroup .p-button {
    width: auto;
}
.p-fluid .p-inputgroup .p-button.p-button-icon-only {
    width: 2.357rem;
}

p-inputmask.ng-dirty.ng-invalid > .p-inputtext {
    border-color: #dc3545;
}

p-inputnumber.ng-dirty.ng-invalid > .p-inputnumber > .p-inputtext {
    border-color: #dc3545;
}

.p-inputswitch {
    width: 3rem;
    height: 1.75rem;
}
.p-inputswitch .p-inputswitch-slider {
    background: $color-grey-light;
    transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
    border-radius: 35px;
    &.focus {
        outline: 2px solid #797979 !important;
    }
}
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
    &.focus {
        outline: 2px solid #92b5df !important;
    }
}
.p-inputswitch .p-inputswitch-slider:before {
    background: #ffffff;
    width: 1.25rem;
    height: 1.25rem;
    left: 0.25rem;
    margin-top: -0.625rem;
    border-radius: 35px;
    transition-duration: 0.15s;
}
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
    transform: translateX(1.25rem);
}
.p-inputswitch.p-focus .p-inputswitch-slider {
    outline: 0 none;
    outline-offset: 0;
}
.p-inputswitch:not(.p-disabled):hover .p-inputswitch-slider {
    background: $color-grey-light;
}
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
    background: $color-blue-light;
}
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
    background: #ffffff;
}
.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
    background: $color-blue-light;
}
.p-inputswitch.p-disabled .p-inputswitch-slider {
    background: $color-grey-light;
}
.p-inputswitch.p-disabled .p-inputswitch-slider:before {
    background: $color-grey-opaque;
}

p-inputswitch.ng-dirty.ng-invalid > .p-inputswitch {
    border-color: #dc3545;
}

.p-inputtext {
    font-family: Poppins, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 1rem;
    color: #000000;
    background: #ffffff;
    padding: 0.5rem 0.75rem;
    border: 1px solid #ced4da;
    transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
    appearance: none;
    border-radius: 20px;
}
.p-inputtext:enabled:hover {
    border-color: #ced4da;
}
.p-inputtext:enabled:focus {
    outline: 0 none;
    outline-offset: 0;

    border-color: $color-blue-light;
}
.p-inputtext.ng-dirty.ng-invalid {
    border-color: #dc3545;
}
.p-inputtext.ng-invalid {
    border-color: #dc3545 !important;
}
.p-inputtext.p-inputtext-sm {
    font-size: 0.875rem;
    padding: 0.4375rem 0.65625rem;
}
.p-inputtext.p-inputtext-lg {
    font-size: 1.25rem;
    padding: 0.625rem 0.9375rem;
}

.p-float-label > label {
    left: 0.75rem;
    color: #6c757d;
    transition-duration: 0.15s;
}

.p-float-label > .ng-invalid.ng-dirty + label {
    color: #dc3545;
}

.p-input-icon-left > i:first-of-type {
    left: 0.75rem;
    color: #495057;
}

.p-input-icon-left > .p-inputtext {
    padding-left: 2.5rem;
}

.p-input-icon-left.p-float-label > label {
    left: 2.5rem;
}

.p-input-icon-right > i:last-of-type {
    right: 0.75rem;
    color: #495057;
}

.p-input-icon-right > .p-inputtext {
    padding-right: 2.5rem;
}

::-webkit-input-placeholder {
    color: #6c757d;
}

:-moz-placeholder {
    color: #6c757d;
}

::-moz-placeholder {
    color: #6c757d;
}

:-ms-input-placeholder {
    color: #6c757d;
}

.p-input-filled .p-inputtext {
    background-color: #efefef;
}
.p-input-filled .p-inputtext:enabled:hover {
    background-color: #efefef;
}
.p-input-filled .p-inputtext:enabled:focus {
    background-color: #efefef;
}

.p-inputtext-sm .p-inputtext {
    font-size: 0.875rem;
    padding: 0.4375rem 0.65625rem;
}

.p-inputtext-lg .p-inputtext {
    font-size: 1.25rem;
    padding: 0.625rem 0.9375rem;
}

.p-listbox {
    background: #ffffff;
    color: #212529;
    border: 1px solid #ced4da;
    border-radius: 4px;
}
.p-listbox .p-listbox-header {
    padding: 0.75rem 1.5rem;
    border-bottom: 1px solid #dee2e6;
    color: #212529;
    background: #efefef;
    margin: 0;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
}
.p-listbox .p-listbox-header .p-listbox-filter {
    padding-right: 1.75rem;
}
.p-listbox .p-listbox-header .p-listbox-filter-icon {
    right: 0.75rem;
    color: #495057;
}
.p-listbox .p-listbox-header .p-checkbox {
    margin-right: 0.5rem;
}
.p-listbox .p-listbox-list {
    padding: 0.5rem 0;
}
.p-listbox .p-listbox-list .p-listbox-item {
    margin: 0;
    padding: 0.5rem 1.5rem;
    border: 0 none;
    color: #212529;
    transition: box-shadow 0.15s;
    border-radius: 0;
}
.p-listbox .p-listbox-list .p-listbox-item.p-highlight {
    color: #ffffff;
    background: $color-blue-light;
}
.p-listbox .p-listbox-list .p-listbox-item:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.15rem rgba(38, 143, 255, 0.5);
}
.p-listbox .p-listbox-list .p-listbox-item .p-checkbox {
    margin-right: 0.5rem;
}
.p-listbox .p-listbox-list .p-listbox-item-group {
    margin: 0;
    padding: 0.75rem 1rem;
    color: #212529;
    background: #ffffff;
    font-weight: 600;
}
.p-listbox:not(.p-disabled) .p-listbox-item:not(.p-highlight):not(.p-disabled):hover {
    color: #212529;
    background: #e9ecef;
}

p-listbox.ng-dirty.ng-invalid > .p-listbox {
    border-color: #dc3545;
}

.p-multiselect {
    background: #ffffff;
    border: 1px solid #ced4da;
    transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
    border-radius: 4px;
}
.p-multiselect:not(.p-disabled):hover {
    border-color: #ced4da;
}
.p-multiselect:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;

    border-color: $color-blue-light;
}
.p-multiselect .p-multiselect-label {
    padding: 0.5rem 0.75rem;
    transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
}
.p-multiselect .p-multiselect-label.p-placeholder {
    color: #6c757d;
}
.p-multiselect.p-multiselect-chip .p-multiselect-token {
    padding: 0.25rem 0.75rem;
    margin-right: 0.5rem;
    background: $color-blue-light;
    color: #ffffff;
    border-radius: 4px;
}
.p-multiselect.p-multiselect-chip .p-multiselect-token .p-multiselect-token-icon {
    margin-left: 0.5rem;
}
.p-multiselect .p-multiselect-trigger {
    background: transparent;
    color: #495057;
    width: 2.357rem;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.p-inputwrapper-filled .p-multiselect.p-multiselect-chip .p-multiselect-label {
    padding: 0.25rem 0.75rem;
}

.p-multiselect-panel {
    background: #ffffff;
    color: #212529;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    box-shadow: none;
}
.p-multiselect-panel .p-multiselect-header {
    padding: 0.75rem 1.5rem;
    border-bottom: 1px solid #dee2e6;
    color: #212529;
    background: #efefef;
    margin: 0;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-inputtext {
    padding-right: 1.75rem;
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-multiselect-filter-icon {
    right: 0.75rem;
    color: #495057;
}
.p-multiselect-panel .p-multiselect-header .p-checkbox {
    margin-right: 0.5rem;
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-close {
    margin-left: 0.5rem;
    width: 2rem;
    height: 2rem;
    color: #6c757d;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: box-shadow 0.15s;
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-close:enabled:hover {
    color: #495057;
    border-color: transparent;
    background: transparent;
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-close:focus {
    outline: 0 none;
    outline-offset: 0;

}
.p-multiselect-panel .p-multiselect-items {
    padding: 0.5rem 0;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
    margin: 0;
    padding: 0.5rem 1.5rem;
    border: 0 none;
    color: #212529;
    background: transparent;
    transition: box-shadow 0.15s;
    border-radius: 0;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
    color: #ffffff;
    background: $color-blue-light;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
    color: #212529;
    background: #e9ecef;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.15rem rgba(38, 143, 255, 0.5);
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item .p-checkbox {
    margin-right: 0.5rem;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item-group {
    margin: 0;
    padding: 0.75rem 1rem;
    color: #212529;
    background: #ffffff;
    font-weight: 600;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-empty-message {
    padding: 0.5rem 1.5rem;
    color: #212529;
    background: transparent;
}

p-multiselect.ng-dirty.ng-invalid > .p-multiselect {
    border-color: #dc3545;
}

.p-input-filled .p-multiselect {
    background: #efefef;
}
.p-input-filled .p-multiselect:not(.p-disabled):hover {
    background-color: #efefef;
}
.p-input-filled .p-multiselect:not(.p-disabled).p-focus {
    background-color: #efefef;
}

.p-password.p-invalid.p-component > .p-inputtext {
    border-color: #dc3545;
}

.p-password-panel {
    padding: 1.25rem;
    background: #ffffff;
    color: #212529;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: none;
    border-radius: 4px;
}
.p-password-panel .p-password-meter {
    margin-bottom: 0.5rem;
    background: #e9ecef;
}
.p-password-panel .p-password-meter .p-password-strength.weak {
    background: #dc3545;
}
.p-password-panel .p-password-meter .p-password-strength.medium {
    background: #ffc107;
}
.p-password-panel .p-password-meter .p-password-strength.strong {
    background: #28a745;
}

.p-radiobutton {
    width: 20px;
    height: 20px;
}
.p-radiobutton .p-radiobutton-box {
    border: 2px solid #ced4da;
    background: #ffffff;
    width: 20px;
    height: 20px;
    color: #212529;
    border-radius: 50%;
    transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
}
.p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
    border-color: #ced4da;
}
.p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;

    border-color: $color-blue-light;
}
.p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
    width: 12px;
    height: 12px;
    transition-duration: 0.15s;
    background-color: #ffffff;
}
.p-radiobutton .p-radiobutton-box.p-highlight {
    border-color: $color-blue-light;
    background: $color-blue-light;
}
.p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
    border-color: #0062cc;
    background: #0062cc;
    color: #ffffff;
}

p-radiobutton.ng-dirty.ng-invalid > .p-radiobutton > .p-radiobutton-box {
    border-color: #dc3545;
}

.p-input-filled .p-radiobutton .p-radiobutton-box {
    background-color: #efefef;
}
.p-input-filled .p-radiobutton .p-radiobutton-box:not(.p-disabled):hover {
    background-color: #efefef;
}
.p-input-filled .p-radiobutton .p-radiobutton-box.p-highlight {
    background: $color-blue-light;
}
.p-input-filled .p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
    background: #0062cc;
}

.p-radiobutton-label {
    transform: translate(4px, 4px);
    margin-left: 5px;
}

.p-highlight .p-radiobutton .p-radiobutton-box {
    border-color: #ffffff;
}

.p-rating .p-rating-icon {
    color: #495057;
    margin-left: 0.5rem;
    transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
    font-size: 1.143rem;
}
.p-rating .p-rating-icon.p-rating-cancel {
    color: #dc3545;
}
.p-rating .p-rating-icon:focus {
    outline: 0 none;
    outline-offset: 0;

}
.p-rating .p-rating-icon:first-child {
    margin-left: 0;
}
.p-rating .p-rating-icon.pi-star {
    color: $color-blue-light;
}
.p-rating:not(.p-disabled):not(.p-readonly) .p-rating-icon:hover {
    color: $color-blue-light;
}
.p-rating:not(.p-disabled):not(.p-readonly) .p-rating-icon.p-rating-cancel:hover {
    color: #dc3545;
}

.p-highlight .p-rating .p-rating-icon {
    color: #ffffff;
}

.p-selectbutton .p-button {
    background: #6c757d;
    border: 1px solid #6c757d;
    color: #ffffff;
    transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
}
.p-selectbutton .p-button .p-button-icon-left,
.p-selectbutton .p-button .p-button-icon-right {
    color: #ffffff;
}
.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover {
    background: #5a6268;
    border-color: #545b62;
    color: #ffffff;
}
.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-left,
.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-right {
    color: #ffffff;
}
.p-selectbutton .p-button.p-highlight {
    background: #545b62;
    border-color: #4e555b;
    color: #ffffff;
}
.p-selectbutton .p-button.p-highlight .p-button-icon-left,
.p-selectbutton .p-button.p-highlight .p-button-icon-right {
    color: #ffffff;
}
.p-selectbutton .p-button.p-highlight:hover {
    background: #545b62;
    border-color: #4e555b;
    color: #ffffff;
}
.p-selectbutton .p-button.p-highlight:hover .p-button-icon-left,
.p-selectbutton .p-button.p-highlight:hover .p-button-icon-right {
    color: #ffffff;
}

p-selectbutton.ng-dirty.ng-invalid > .p-selectbutton > .p-button {
    border-color: #dc3545;
}

.p-slider {
    background: #e9ecef;
    border: 0 none;
    border-radius: 4px;
}
.p-slider.p-slider-horizontal {
    height: 0.286rem;
}
.p-slider.p-slider-horizontal .p-slider-handle {
    margin-top: -0.5715rem;
    margin-left: -0.5715rem;
}
.p-slider.p-slider-vertical {
    width: 0.286rem;
}
.p-slider.p-slider-vertical .p-slider-handle {
    margin-left: -0.5715rem;
    margin-bottom: -0.5715rem;
}
.p-slider .p-slider-handle {
    height: 1.143rem;
    width: 1.143rem;
    background: $blue-400;
    border: 2px solid $blue-400;
    border-radius: 4px;
    transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
}
.p-slider .p-slider-handle:focus {
    outline: 0 none;
    outline-offset: 0;

}
.p-slider .p-slider-range {
    background: $blue-400;
}
.p-slider:not(.p-disabled) .p-slider-handle:hover {
    background: $blue-600;
    border-color: $blue-600;
}
.p-slider.p-slider-animate.p-slider-horizontal .p-slider-handle {
    transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s, left 0.15s;
}
.p-slider.p-slider-animate.p-slider-horizontal .p-slider-range {
    transition: width 0.15s;
}
.p-slider.p-slider-animate.p-slider-vertical .p-slider-handle {
    transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s, bottom 0.15s;
}
.p-slider.p-slider-animate.p-slider-vertical .p-slider-range {
    transition: height 0.15s;
}

.p-togglebutton.p-button {
    background: #6c757d;
    border: 1px solid #6c757d;
    color: #ffffff;
    transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
}
.p-togglebutton.p-button .p-button-icon-left,
.p-togglebutton.p-button .p-button-icon-right {
    color: #ffffff;
}
.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover {
    background: #5a6268;
    border-color: #545b62;
    color: #ffffff;
}
.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-left,
.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-right {
    color: #ffffff;
}
.p-togglebutton.p-button.p-highlight {
    background: #545b62;
    border-color: #4e555b;
    color: #ffffff;
}
.p-togglebutton.p-button.p-highlight .p-button-icon-left,
.p-togglebutton.p-button.p-highlight .p-button-icon-right {
    color: #ffffff;
}
.p-togglebutton.p-button.p-highlight:hover {
    background: #545b62;
    border-color: #4e555b;
    color: #ffffff;
}
.p-togglebutton.p-button.p-highlight:hover .p-button-icon-left,
.p-togglebutton.p-button.p-highlight:hover .p-button-icon-right {
    color: #ffffff;
}

p-togglebutton.ng-dirty.ng-invalid > .p-togglebutton.p-button {
    border-color: #dc3545;
}

.p-button {
    color: #ffffff;
    background: $color-blue-light;
    border: 1px solid $color-blue-light;
    padding: 0.5rem 0.75rem;
    font-size: 1rem;
    transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
    border-radius: 4px;
}
.p-button:enabled:hover {
    background: $blue-600;
    color: #ffffff;
    border-color: $blue-600;
}
.p-button:enabled:active {
    background: $blue-600;
    color: #ffffff;
    border-color: $blue-600;
}
.p-button.p-button-outlined {
    background-color: transparent;
    color: $color-blue-light;
    border: 1px solid;
}
.p-button.p-button-outlined:enabled:hover {
    background: rgba(0, 123, 255, 0.04);
    color: $color-blue-light;
    border: 1px solid;
}
.p-button.p-button-outlined:enabled:active {
    background: rgba(0, 123, 255, 0.16);
    color: $color-blue-light;
    border: 1px solid;
}
.p-button.p-button-outlined.p-button-plain {
    color: #6c757d;
    border-color: #6c757d;
}
.p-button.p-button-outlined.p-button-plain:enabled:hover {
    background: #e9ecef;
    color: #6c757d;
}
.p-button.p-button-outlined.p-button-plain:enabled:active {
    background: #dee2e6;
    color: #6c757d;
}
.p-button.p-button-text {
    background-color: transparent;
    color: $color-blue-light;
    border-color: transparent;
}
.p-button.p-button-text:enabled:hover {
    background: rgba(0, 123, 255, 0.04);
    color: $color-blue-light;
    border-color: transparent;
}
.p-button.p-button-text:enabled:active {
    background: rgba(0, 123, 255, 0.16);
    color: $color-blue-light;
    border-color: transparent;
}
.p-button.p-button-text.p-button-plain {
    color: #6c757d;
}
.p-button.p-button-text.p-button-plain:enabled:hover {
    background: #e9ecef;
    color: #6c757d;
}
.p-button.p-button-text.p-button-plain:enabled:active {
    background: #dee2e6;
    color: #6c757d;
}
.p-button:focus {
    outline: 0 none;
    outline-offset: 0;

}
.p-button .p-button-icon-left {
    margin-right: 0.5rem;
}
.p-button .p-button-icon-right {
    margin-left: 0.5rem;
}
.p-button .p-button-icon-bottom {
    margin-top: 0.5rem;
}
.p-button .p-button-icon-top {
    margin-bottom: 0.5rem;
}
.p-button .p-badge {
    margin-left: 0.5rem;
    min-width: 1rem;
    height: 1rem;
    line-height: 1rem;
    color: $color-blue-light;
    background-color: #ffffff;
}
.p-button.p-button-raised {
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.p-button.p-button-rounded {
    border-radius: 2rem;
}
.p-button.p-button-icon-only {
    width: 2.357rem;
    padding: 0.5rem 0;
}
.p-button.p-button-icon-only .p-button-icon-left,
.p-button.p-button-icon-only .p-button-icon-right {
    margin: 0;
}
.p-button.p-button-icon-only.p-button-rounded {
    border-radius: 50%;
    height: 2.357rem;
}
.p-button.p-button-sm {
    font-size: 0.875rem;
    padding: 0.4375rem 0.65625rem;
}
.p-button.p-button-sm .p-button-icon {
    font-size: 0.875rem;
}
.p-button.p-button-lg {
    font-size: 1.25rem;
    padding: 0.625rem 0.9375rem;
}
.p-button.p-button-lg .p-button-icon {
    font-size: 1.25rem;
}

.p-fluid .p-button {
    width: 100%;
}
.p-fluid .p-button-icon-only {
    width: 2.357rem;
}
.p-fluid .p-buttonset {
    display: flex;
}
.p-fluid .p-buttonset .p-button {
    flex: 1;
}

.p-button.p-button-secondary, .p-buttonset.p-button-secondary > .p-button, .p-splitbutton.p-button-secondary > .p-button {
    color: #ffffff;
    background: #6c757d;
    border: 1px solid #6c757d;
}
.p-button.p-button-secondary:enabled:hover, .p-buttonset.p-button-secondary > .p-button:enabled:hover, .p-splitbutton.p-button-secondary > .p-button:enabled:hover {
    background: #5a6268;
    color: #ffffff;
    border-color: #5a6268;
}
.p-button.p-button-secondary:enabled:focus, .p-buttonset.p-button-secondary > .p-button:enabled:focus, .p-splitbutton.p-button-secondary > .p-button:enabled:focus {
    box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}
.p-button.p-button-secondary:enabled:active, .p-buttonset.p-button-secondary > .p-button:enabled:active, .p-splitbutton.p-button-secondary > .p-button:enabled:active {
    background: #545b62;
    color: #ffffff;
    border-color: #4e555b;
}
.p-button.p-button-secondary.p-button-outlined, .p-buttonset.p-button-secondary > .p-button.p-button-outlined, .p-splitbutton.p-button-secondary > .p-button.p-button-outlined {
    background-color: transparent;
    color: #6c757d;
    border: 1px solid;
}
.p-button.p-button-secondary.p-button-outlined:enabled:hover, .p-buttonset.p-button-secondary > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-secondary > .p-button.p-button-outlined:enabled:hover {
    background: rgba(108, 117, 125, 0.04);
    color: #6c757d;
    border: 1px solid;
}
.p-button.p-button-secondary.p-button-outlined:enabled:active, .p-buttonset.p-button-secondary > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-secondary > .p-button.p-button-outlined:enabled:active {
    background: rgba(108, 117, 125, 0.16);
    color: #6c757d;
    border: 1px solid;
}
.p-button.p-button-secondary.p-button-text, .p-buttonset.p-button-secondary > .p-button.p-button-text, .p-splitbutton.p-button-secondary > .p-button.p-button-text {
    background-color: transparent;
    color: #6c757d;
    border-color: transparent;
}
.p-button.p-button-secondary.p-button-text:enabled:hover, .p-buttonset.p-button-secondary > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-secondary > .p-button.p-button-text:enabled:hover {
    background: rgba(108, 117, 125, 0.04);
    border-color: transparent;
    color: #6c757d;
}
.p-button.p-button-secondary.p-button-text:enabled:active, .p-buttonset.p-button-secondary > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-secondary > .p-button.p-button-text:enabled:active {
    background: rgba(108, 117, 125, 0.16);
    border-color: transparent;
    color: #6c757d;
}

.p-button.p-button-info, .p-buttonset.p-button-info > .p-button, .p-splitbutton.p-button-info > .p-button {
    color: #ffffff;
    background: #17a2b8;
    border: 1px solid #17a2b8;
}
.p-button.p-button-info:enabled:hover, .p-buttonset.p-button-info > .p-button:enabled:hover, .p-splitbutton.p-button-info > .p-button:enabled:hover {
    background: #138496;
    color: #ffffff;
    border-color: #117a8b;
}
.p-button.p-button-info:enabled:focus, .p-buttonset.p-button-info > .p-button:enabled:focus, .p-splitbutton.p-button-info > .p-button:enabled:focus {
    box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
.p-button.p-button-info:enabled:active, .p-buttonset.p-button-info > .p-button:enabled:active, .p-splitbutton.p-button-info > .p-button:enabled:active {
    background: #138496;
    color: #ffffff;
    border-color: #117a8b;
}
.p-button.p-button-info.p-button-outlined, .p-buttonset.p-button-info > .p-button.p-button-outlined, .p-splitbutton.p-button-info > .p-button.p-button-outlined {
    background-color: transparent;
    color: #17a2b8;
    border: 1px solid;
}
.p-button.p-button-info.p-button-outlined:enabled:hover, .p-buttonset.p-button-info > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-info > .p-button.p-button-outlined:enabled:hover {
    background: rgba(23, 162, 184, 0.04);
    color: #17a2b8;
    border: 1px solid;
}
.p-button.p-button-info.p-button-outlined:enabled:active, .p-buttonset.p-button-info > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-info > .p-button.p-button-outlined:enabled:active {
    background: rgba(23, 162, 184, 0.16);
    color: #17a2b8;
    border: 1px solid;
}
.p-button.p-button-info.p-button-text, .p-buttonset.p-button-info > .p-button.p-button-text, .p-splitbutton.p-button-info > .p-button.p-button-text {
    background-color: transparent;
    color: #17a2b8;
    border-color: transparent;
}
.p-button.p-button-info.p-button-text:enabled:hover, .p-buttonset.p-button-info > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-info > .p-button.p-button-text:enabled:hover {
    background: rgba(23, 162, 184, 0.04);
    border-color: transparent;
    color: #17a2b8;
}
.p-button.p-button-info.p-button-text:enabled:active, .p-buttonset.p-button-info > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-info > .p-button.p-button-text:enabled:active {
    background: rgba(23, 162, 184, 0.16);
    border-color: transparent;
    color: #17a2b8;
}

.p-button.p-button-success, .p-buttonset.p-button-success > .p-button, .p-splitbutton.p-button-success > .p-button {
    color: #ffffff;
    background: #28a745;
    border: 1px solid #28a745;
}
.p-button.p-button-success:enabled:hover, .p-buttonset.p-button-success > .p-button:enabled:hover, .p-splitbutton.p-button-success > .p-button:enabled:hover {
    background: #218838;
    color: #ffffff;
    border-color: #1e7e34;
}
.p-button.p-button-success:enabled:focus, .p-buttonset.p-button-success > .p-button:enabled:focus, .p-splitbutton.p-button-success > .p-button:enabled:focus {
    box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}
.p-button.p-button-success:enabled:active, .p-buttonset.p-button-success > .p-button:enabled:active, .p-splitbutton.p-button-success > .p-button:enabled:active {
    background: #1e7e34;
    color: #ffffff;
    border-color: #1c7430;
}
.p-button.p-button-success.p-button-outlined, .p-buttonset.p-button-success > .p-button.p-button-outlined, .p-splitbutton.p-button-success > .p-button.p-button-outlined {
    background-color: transparent;
    color: #28a745;
    border: 1px solid;
}
.p-button.p-button-success.p-button-outlined:enabled:hover, .p-buttonset.p-button-success > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-success > .p-button.p-button-outlined:enabled:hover {
    background: rgba(40, 167, 69, 0.04);
    color: #28a745;
    border: 1px solid;
}
.p-button.p-button-success.p-button-outlined:enabled:active, .p-buttonset.p-button-success > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-success > .p-button.p-button-outlined:enabled:active {
    background: rgba(40, 167, 69, 0.16);
    color: #28a745;
    border: 1px solid;
}
.p-button.p-button-success.p-button-text, .p-buttonset.p-button-success > .p-button.p-button-text, .p-splitbutton.p-button-success > .p-button.p-button-text {
    background-color: transparent;
    color: #28a745;
    border-color: transparent;
}
.p-button.p-button-success.p-button-text:enabled:hover, .p-buttonset.p-button-success > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-success > .p-button.p-button-text:enabled:hover {
    background: rgba(40, 167, 69, 0.04);
    border-color: transparent;
    color: #28a745;
}
.p-button.p-button-success.p-button-text:enabled:active, .p-buttonset.p-button-success > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-success > .p-button.p-button-text:enabled:active {
    background: rgba(40, 167, 69, 0.16);
    border-color: transparent;
    color: #28a745;
}

.p-button.p-button-warning, .p-buttonset.p-button-warning > .p-button, .p-splitbutton.p-button-warning > .p-button {
    color: #212529;
    background: #ffc107;
    border: 1px solid #ffc107;
}
.p-button.p-button-warning:enabled:hover, .p-buttonset.p-button-warning > .p-button:enabled:hover, .p-splitbutton.p-button-warning > .p-button:enabled:hover {
    background: #e0a800;
    color: #212529;
    border-color: #d39e00;
}
.p-button.p-button-warning:enabled:focus, .p-buttonset.p-button-warning > .p-button:enabled:focus, .p-splitbutton.p-button-warning > .p-button:enabled:focus {
    box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}
.p-button.p-button-warning:enabled:active, .p-buttonset.p-button-warning > .p-button:enabled:active, .p-splitbutton.p-button-warning > .p-button:enabled:active {
    background: #d39e00;
    color: #212529;
    border-color: #c69500;
}
.p-button.p-button-warning.p-button-outlined, .p-buttonset.p-button-warning > .p-button.p-button-outlined, .p-splitbutton.p-button-warning > .p-button.p-button-outlined {
    background-color: transparent;
    color: #ffc107;
    border: 1px solid;
}
.p-button.p-button-warning.p-button-outlined:enabled:hover, .p-buttonset.p-button-warning > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-warning > .p-button.p-button-outlined:enabled:hover {
    background: rgba(255, 193, 7, 0.04);
    color: #ffc107;
    border: 1px solid;
}
.p-button.p-button-warning.p-button-outlined:enabled:active, .p-buttonset.p-button-warning > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-warning > .p-button.p-button-outlined:enabled:active {
    background: rgba(255, 193, 7, 0.16);
    color: #ffc107;
    border: 1px solid;
}
.p-button.p-button-warning.p-button-text, .p-buttonset.p-button-warning > .p-button.p-button-text, .p-splitbutton.p-button-warning > .p-button.p-button-text {
    background-color: transparent;
    color: #ffc107;
    border-color: transparent;
}
.p-button.p-button-warning.p-button-text:enabled:hover, .p-buttonset.p-button-warning > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-warning > .p-button.p-button-text:enabled:hover {
    background: rgba(255, 193, 7, 0.04);
    border-color: transparent;
    color: #ffc107;
}
.p-button.p-button-warning.p-button-text:enabled:active, .p-buttonset.p-button-warning > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-warning > .p-button.p-button-text:enabled:active {
    background: rgba(255, 193, 7, 0.16);
    border-color: transparent;
    color: #ffc107;
}

.p-button.p-button-help, .p-buttonset.p-button-help > .p-button, .p-splitbutton.p-button-help > .p-button {
    color: #ffffff;
    background: #6f42c1;
    border: 1px solid #6f42c1;
}
.p-button.p-button-help:enabled:hover, .p-buttonset.p-button-help > .p-button:enabled:hover, .p-splitbutton.p-button-help > .p-button:enabled:hover {
    background: #633bad;
    color: #ffffff;
    border-color: #58349a;
}
.p-button.p-button-help:enabled:focus, .p-buttonset.p-button-help > .p-button:enabled:focus, .p-splitbutton.p-button-help > .p-button:enabled:focus {
    box-shadow: 0 0 0 0.2rem #d3c6ec;
}
.p-button.p-button-help:enabled:active, .p-buttonset.p-button-help > .p-button:enabled:active, .p-splitbutton.p-button-help > .p-button:enabled:active {
    background: #58349a;
    color: #ffffff;
    border-color: #4d2e87;
}
.p-button.p-button-help.p-button-outlined, .p-buttonset.p-button-help > .p-button.p-button-outlined, .p-splitbutton.p-button-help > .p-button.p-button-outlined {
    background-color: transparent;
    color: #6f42c1;
    border: 1px solid;
}
.p-button.p-button-help.p-button-outlined:enabled:hover, .p-buttonset.p-button-help > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-help > .p-button.p-button-outlined:enabled:hover {
    background: rgba(111, 66, 193, 0.04);
    color: #6f42c1;
    border: 1px solid;
}
.p-button.p-button-help.p-button-outlined:enabled:active, .p-buttonset.p-button-help > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-help > .p-button.p-button-outlined:enabled:active {
    background: rgba(111, 66, 193, 0.16);
    color: #6f42c1;
    border: 1px solid;
}
.p-button.p-button-help.p-button-text, .p-buttonset.p-button-help > .p-button.p-button-text, .p-splitbutton.p-button-help > .p-button.p-button-text {
    background-color: transparent;
    color: #6f42c1;
    border-color: transparent;
}
.p-button.p-button-help.p-button-text:enabled:hover, .p-buttonset.p-button-help > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-help > .p-button.p-button-text:enabled:hover {
    background: rgba(111, 66, 193, 0.04);
    border-color: transparent;
    color: #6f42c1;
}
.p-button.p-button-help.p-button-text:enabled:active, .p-buttonset.p-button-help > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-help > .p-button.p-button-text:enabled:active {
    background: rgba(111, 66, 193, 0.16);
    border-color: transparent;
    color: #6f42c1;
}

.p-button.p-button-danger, .p-buttonset.p-button-danger > .p-button, .p-splitbutton.p-button-danger > .p-button {
    color: #ffffff;
    background: #dc3545;
    border: 1px solid #dc3545;
}
.p-button.p-button-danger:enabled:hover, .p-buttonset.p-button-danger > .p-button:enabled:hover, .p-splitbutton.p-button-danger > .p-button:enabled:hover {
    background: #c82333;
    color: #ffffff;
    border-color: #bd2130;
}
.p-button.p-button-danger:enabled:focus, .p-buttonset.p-button-danger > .p-button:enabled:focus, .p-splitbutton.p-button-danger > .p-button:enabled:focus {
    box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}
.p-button.p-button-danger:enabled:active, .p-buttonset.p-button-danger > .p-button:enabled:active, .p-splitbutton.p-button-danger > .p-button:enabled:active {
    background: #bd2130;
    color: #ffffff;
    border-color: #b21f2d;
}
.p-button.p-button-danger.p-button-outlined, .p-buttonset.p-button-danger > .p-button.p-button-outlined, .p-splitbutton.p-button-danger > .p-button.p-button-outlined {
    background-color: transparent;
    color: #dc3545;
    border: 1px solid;
}
.p-button.p-button-danger.p-button-outlined:enabled:hover, .p-buttonset.p-button-danger > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-danger > .p-button.p-button-outlined:enabled:hover {
    background: rgba(220, 53, 69, 0.04);
    color: #dc3545;
    border: 1px solid;
}
.p-button.p-button-danger.p-button-outlined:enabled:active, .p-buttonset.p-button-danger > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-danger > .p-button.p-button-outlined:enabled:active {
    background: rgba(220, 53, 69, 0.16);
    color: #dc3545;
    border: 1px solid;
}
.p-button.p-button-danger.p-button-text, .p-buttonset.p-button-danger > .p-button.p-button-text, .p-splitbutton.p-button-danger > .p-button.p-button-text {
    background-color: transparent;
    color: #dc3545;
    border-color: transparent;
}
.p-button.p-button-danger.p-button-text:enabled:hover, .p-buttonset.p-button-danger > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-danger > .p-button.p-button-text:enabled:hover {
    background: rgba(220, 53, 69, 0.04);
    border-color: transparent;
    color: #dc3545;
}
.p-button.p-button-danger.p-button-text:enabled:active, .p-buttonset.p-button-danger > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-danger > .p-button.p-button-text:enabled:active {
    background: rgba(220, 53, 69, 0.16);
    border-color: transparent;
    color: #dc3545;
}

.p-button.p-button-link {
    color: $color-blue-light;
    background: transparent;
    border: transparent;
}
.p-button.p-button-link:enabled:hover {
    background: transparent;
    color: $blue-600;
    border-color: transparent;
}
.p-button.p-button-link:enabled:hover .p-button-label {
    text-decoration: underline;
}
.p-button.p-button-link:enabled:focus {
    background: transparent;

    border-color: transparent;
}
.p-button.p-button-link:enabled:active {
    background: transparent;
    color: $color-blue-light;
    border-color: transparent;
}

.p-carousel .p-carousel-content .p-carousel-prev,
.p-carousel .p-carousel-content .p-carousel-next {
    width: 2rem;
    height: 2rem;
    color: #6c757d;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: box-shadow 0.15s;
    margin: 0.5rem;
}
.p-carousel .p-carousel-content .p-carousel-prev:enabled:hover,
.p-carousel .p-carousel-content .p-carousel-next:enabled:hover {
    color: #495057;
    border-color: transparent;
    background: transparent;
}
.p-carousel .p-carousel-content .p-carousel-prev:focus,
.p-carousel .p-carousel-content .p-carousel-next:focus {
    outline: 0 none;
    outline-offset: 0;

}
.p-carousel .p-carousel-indicators {
    padding: 1rem;
}
.p-carousel .p-carousel-indicators .p-carousel-indicator {
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
}
.p-carousel .p-carousel-indicators .p-carousel-indicator button {
    background-color: #e9ecef;
    width: 2rem;
    height: 0.5rem;
    transition: box-shadow 0.15s;
    border-radius: 0;
}
.p-carousel .p-carousel-indicators .p-carousel-indicator button:hover {
    background: #dee2e6;
}
.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
    background: $color-blue-light;
    color: #ffffff;
}

.p-datatable .p-paginator-top {
    border-width: 1px 0 0 0;
    border-radius: 0;
}
.p-datatable .p-paginator-bottom {
    border-width: 1px 0 0 0;
    border-radius: 0;
}
.p-datatable .p-datatable-header {
    background: #ffffff;
    color: #212529;
    border-bottom: solid #dee2e6;
    border-width: 1px 0 0 0;
    padding: 1rem 1rem;
    font-weight: 600;
}
.p-datatable .p-datatable-footer {
    background: #efefef;
    color: #212529;
    border: 1px solid #dee2e6;
    border-width: 1px 0 1px 0;
    padding: 1rem 1rem;
    font-weight: 600;
}
.p-datatable .p-datatable-thead > tr > th {
    text-align: left;
    padding: 1rem 1rem;
    border: 1px solid #dee2e6;
    border-width: 1px 0 2px 0;
    font-weight: 600;
    color: #212529;
    background: #ffffff;
    transition: box-shadow 0.15s;
}
.p-datatable .p-datatable-tfoot > tr > td {
    text-align: left;
    padding: 1rem 1rem;
    border: 1px solid #dee2e6;
    border-width: 1px 0 1px 0;
    font-weight: 600;
    color: #212529;
    background: #ffffff;
}
.p-datatable .p-sortable-column .p-sortable-column-icon {
    color: #6c757d;
    margin-left: 0.5rem;
}
.p-datatable .p-sortable-column .p-sortable-column-badge {
    border-radius: 50%;
    height: 1.143rem;
    min-width: 1.143rem;
    line-height: 1.143rem;
    color: #ffffff;
    background: $color-blue-light;
    margin-left: 0.5rem;
}
.p-datatable .p-sortable-column:not(.p-highlight):hover {
    background: #e9ecef;
    color: #212529;
}
.p-datatable .p-sortable-column:not(.p-highlight):hover .p-sortable-column-icon {
    color: #6c757d;
}
.p-datatable .p-sortable-column.p-highlight {
    background: #ffffff;
    color: $color-blue-light;
}
.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
    color: $color-blue-light;
}
.p-datatable .p-sortable-column.p-highlight:hover {
    background: #e9ecef;
    color: $color-blue-light;
}
.p-datatable .p-sortable-column.p-highlight:hover .p-sortable-column-icon {
    color: $color-blue-light;
}
.p-datatable .p-sortable-column:focus {
    box-shadow: inset 0 0 0 0.15rem rgba(38, 143, 255, 0.5);
    outline: 0 none;
}
.p-datatable .p-datatable-tbody > tr {
    background: #ffffff;
    color: #212529;
    transition: box-shadow 0.15s;
    outline-color: rgba(38, 143, 255, 0.5);
}
.p-datatable .p-datatable-tbody > tr > td {
    text-align: left;
    border: 1px solid #dee2e6;
    border-width: 1px 0 0 0;
    padding: 1rem 1rem;
}
.p-datatable .p-datatable-tbody > tr > td .p-row-toggler,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-init,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel {
    width: 2rem;
    height: 2rem;
    color: #6c757d;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: box-shadow 0.15s;
}
.p-datatable .p-datatable-tbody > tr > td .p-row-toggler:enabled:hover,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-init:enabled:hover,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save:enabled:hover,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel:enabled:hover {
    color: #495057;
    border-color: transparent;
    background: transparent;
}
.p-datatable .p-datatable-tbody > tr > td .p-row-toggler:focus,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-init:focus,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save:focus,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel:focus {
    outline: 0 none;
    outline-offset: 0;

}
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save {
    margin-right: 0.5rem;
}
.p-datatable .p-datatable-tbody > tr.p-highlight {
    background: $color-blue-light;
    color: #ffffff;
}
.p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-top > td {
    box-shadow: inset 0 2px 0 0 $color-blue-light;
}
.p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-bottom > td {
    box-shadow: inset 0 -2px 0 0 $color-blue-light;
}
.p-datatable.p-datatable-hoverable-rows .p-datatable-tbody > tr:not(.p-highlight):hover {
    background: #e9ecef;
    color: #212529;
}
.p-datatable .p-column-resizer-helper {
    background: $color-blue-light;
}
.p-datatable .p-datatable-scrollable-header,
.p-datatable .p-datatable-scrollable-footer {
    background: #efefef;
}
.p-datatable .p-datatable-loading-icon {
    font-size: 2rem;
}
.p-datatable.p-datatable-gridlines .p-datatable-header {
    border-width: 1px 1px 0 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-footer {
    border-width: 0 1px 1px 1px;
}
.p-datatable.p-datatable-gridlines .p-paginator-top {
    border-width: 0 1px 0 1px;
}
.p-datatable.p-datatable-gridlines .p-paginator-bottom {
    border-width: 0 1px 1px 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th {
    border-width: 1px 1px 2px 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-tbody > tr > td {
    border-width: 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-tfoot > tr > td {
    border-width: 1px;
}
.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even) {
    background: rgba(0, 0, 0, 0.05);
}
.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even).p-highlight {
    background: $color-blue-light;
    color: #ffffff;
}
.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even).p-highlight .p-row-toggler {
    color: #ffffff;
}
.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even).p-highlight .p-row-toggler:hover {
    color: #ffffff;
}
.p-datatable.p-datatable-sm .p-datatable-header {
    padding: 0.5rem 0.5rem;
}
.p-datatable.p-datatable-sm .p-datatable-thead > tr > th {
    padding: 0.5rem 0.5rem;
}
.p-datatable.p-datatable-sm .p-datatable-tbody > tr > td {
    padding: 0.5rem 0.5rem;
}
.p-datatable.p-datatable-sm .p-datatable-tfoot > tr > td {
    padding: 0.5rem 0.5rem;
}
.p-datatable.p-datatable-sm .p-datatable-footer {
    padding: 0.5rem 0.5rem;
}
.p-datatable.p-datatable-lg .p-datatable-header {
    padding: 1.25rem 1.25rem;
}
.p-datatable.p-datatable-lg .p-datatable-thead > tr > th {
    padding: 1.25rem 1.25rem;
}
.p-datatable.p-datatable-lg .p-datatable-tbody > tr > td {
    padding: 1.25rem 1.25rem;
}
.p-datatable.p-datatable-lg .p-datatable-tfoot > tr > td {
    padding: 1.25rem 1.25rem;
}
.p-datatable.p-datatable-lg .p-datatable-footer {
    padding: 1.25rem 1.25rem;
}

.p-dataview .p-paginator-top {
    border-width: 1px 0 0 0;
    border-radius: 0;
}
.p-dataview .p-paginator-bottom {
    border-width: 1px 0 0 0;
    border-radius: 0;
}
.p-dataview .p-dataview-header {
    background: #efefef;
    color: #212529;
    border: solid #dee2e6;
    border-width: 1px 0 0 0;
    padding: 1rem 1rem;
    font-weight: 600;
}
.p-dataview .p-dataview-content {
    background: #ffffff;
    color: #212529;
    border: 0 none;
    padding: 0;
}
.p-dataview.p-dataview-list .p-dataview-content > .p-grid > div {
    border: 1px solid #dee2e6;
    border-width: 1px 0 0 0;
}
.p-dataview .p-dataview-footer {
    background: #efefef;
    color: #212529;
    border: 1px solid #dee2e6;
    border-width: 1px 0 1px 0;
    padding: 1rem 1rem;
    font-weight: 600;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}
.p-dataview .p-dataview-loading-icon {
    font-size: 2rem;
}
.p-dataview .p-dataview-emptymessage {
    padding: 1.25rem;
}

.p-column-filter-row .p-column-filter-menu-button,
.p-column-filter-row .p-column-filter-clear-button {
    margin-left: 0.5rem;
}

.p-column-filter-menu-button {
    width: 2rem;
    height: 2rem;
    color: #6c757d;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: box-shadow 0.15s;
}
.p-column-filter-menu-button:hover {
    color: #495057;
    border-color: transparent;
    background: transparent;
}
.p-column-filter-menu-button.p-column-filter-menu-button-open, .p-column-filter-menu-button.p-column-filter-menu-button-open:hover {
    background: transparent;
    color: #495057;
}
.p-column-filter-menu-button.p-column-filter-menu-button-active, .p-column-filter-menu-button.p-column-filter-menu-button-active:hover {
    background: $color-blue-light;
    color: #ffffff;
}
.p-column-filter-menu-button:focus {
    outline: 0 none;
    outline-offset: 0;

}

.p-column-filter-clear-button {
    width: 2rem;
    height: 2rem;
    color: #6c757d;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: box-shadow 0.15s;
}
.p-column-filter-clear-button:hover {
    color: #495057;
    border-color: transparent;
    background: transparent;
}
.p-column-filter-clear-button:focus {
    outline: 0 none;
    outline-offset: 0;

}

.p-column-filter-overlay {
    background: #ffffff;
    color: #212529;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    box-shadow: none;
    min-width: 12.5rem;
}
.p-column-filter-overlay .p-column-filter-row-items {
    padding: 0.5rem 0;
}
.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item {
    margin: 0;
    padding: 0.5rem 1.5rem;
    border: 0 none;
    color: #212529;
    background: transparent;
    transition: box-shadow 0.15s;
    border-radius: 0;
}
.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item.p-highlight {
    color: #ffffff;
    background: $color-blue-light;
}
.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item:not(.p-highlight):not(.p-disabled):hover {
    color: #212529;
    background: #e9ecef;
}
.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.15rem rgba(38, 143, 255, 0.5);
}
.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-separator {
    border-top: 1px solid #dee2e6;
    margin: 0.5rem 0;
}

.p-column-filter-overlay-menu .p-column-filter-operator {
    padding: 0.75rem 1.5rem;
    border-bottom: 1px solid #dee2e6;
    color: #212529;
    background: #efefef;
    margin: 0;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
}
.p-column-filter-overlay-menu .p-column-filter-constraint {
    padding: 1.25rem;
    border-bottom: 1px solid #dee2e6;
}
.p-column-filter-overlay-menu .p-column-filter-constraint .p-column-filter-matchmode-dropdown {
    margin-bottom: 0.5rem;
}
.p-column-filter-overlay-menu .p-column-filter-constraint .p-column-filter-remove-button {
    margin-top: 0.5rem;
}
.p-column-filter-overlay-menu .p-column-filter-constraint:last-child {
    border-bottom: 0 none;
}
.p-column-filter-overlay-menu .p-column-filter-add-rule {
    padding: 0.5rem 1.25rem;
}
.p-column-filter-overlay-menu .p-column-filter-buttonbar {
    padding: 1.25rem;
}

.fc .fc-view-container th {
    background: #efefef;
    border: 1px solid #dee2e6;
    color: #212529;
}
.fc .fc-view-container td.fc-widget-content {
    background: #ffffff;
    border: 1px solid #dee2e6;
    color: #212529;
}
.fc .fc-view-container td.fc-head-container {
    border: 1px solid #dee2e6;
}
.fc .fc-view-container .fc-row {
    border-right: 1px solid #dee2e6;
}
.fc .fc-view-container .fc-event {
    background: #0069d9;
    border: 1px solid #0069d9;
    color: #ffffff;
}
.fc .fc-view-container .fc-divider {
    background: #efefef;
    border: 1px solid #dee2e6;
}
.fc .fc-toolbar .fc-button {
    color: #ffffff;
    background: $color-blue-light;
    border: 1px solid $color-blue-light;
    font-size: 1rem;
    transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
    border-radius: 4px;
    display: flex;
    align-items: center;
}
.fc .fc-toolbar .fc-button:enabled:hover {
    background: #0069d9;
    color: #ffffff;
    border-color: #0069d9;
}
.fc .fc-toolbar .fc-button:enabled:active {
    background: #0062cc;
    color: #ffffff;
    border-color: #0062cc;
}
.fc .fc-toolbar .fc-button:enabled:active:focus {
    outline: 0 none;
    outline-offset: 0;

}
.fc .fc-toolbar .fc-button .fc-icon-chevron-left {
    font-family: "PrimeIcons" !important;
    text-indent: 0;
    font-size: 1rem;
}
.fc .fc-toolbar .fc-button .fc-icon-chevron-left:before {
    content: "";
}
.fc .fc-toolbar .fc-button .fc-icon-chevron-right {
    font-family: "PrimeIcons" !important;
    text-indent: 0;
    font-size: 1rem;
}
.fc .fc-toolbar .fc-button .fc-icon-chevron-right:before {
    content: "";
}
.fc .fc-toolbar .fc-button:focus {
    outline: 0 none;
    outline-offset: 0;

}
.fc .fc-toolbar .fc-button.fc-dayGridMonth-button, .fc .fc-toolbar .fc-button.fc-timeGridWeek-button, .fc .fc-toolbar .fc-button.fc-timeGridDay-button {
    background: #6c757d;
    border: 1px solid #6c757d;
    color: #ffffff;
    transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
}
.fc .fc-toolbar .fc-button.fc-dayGridMonth-button:hover, .fc .fc-toolbar .fc-button.fc-timeGridWeek-button:hover, .fc .fc-toolbar .fc-button.fc-timeGridDay-button:hover {
    background: #5a6268;
    border-color: #545b62;
    color: #ffffff;
}
.fc .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active, .fc .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active, .fc .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active {
    background: #545b62;
    border-color: #4e555b;
    color: #ffffff;
}
.fc .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active:hover, .fc .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active:hover, .fc .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active:hover {
    background: #545b62;
    border-color: #4e555b;
    color: #ffffff;
}
.fc .fc-toolbar .fc-button.fc-dayGridMonth-button:focus, .fc .fc-toolbar .fc-button.fc-timeGridWeek-button:focus, .fc .fc-toolbar .fc-button.fc-timeGridDay-button:focus {
    outline: 0 none;
    outline-offset: 0;

    z-index: 1;
}
.fc .fc-toolbar .fc-button-group .fc-button {
    border-radius: 0;
}
.fc .fc-toolbar .fc-button-group .fc-button:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}
.fc .fc-toolbar .fc-button-group .fc-button:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.p-orderlist .p-orderlist-controls {
    padding: 1.25rem;
}
.p-orderlist .p-orderlist-controls .p-button {
    margin-bottom: 0.5rem;
}
.p-orderlist .p-orderlist-header {
    background: #efefef;
    color: #212529;
    border: 1px solid #dee2e6;
    padding: 1rem 1.25rem;
    border-bottom: 0 none;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
}
.p-orderlist .p-orderlist-header .p-orderlist-title {
    font-weight: 600;
}
.p-orderlist .p-orderlist-filter-container {
    padding: 1rem 1.25rem;
    background: #ffffff;
    border: 1px solid #dee2e6;
    border-bottom: 0 none;
}
.p-orderlist .p-orderlist-filter-container .p-orderlist-filter-input {
    padding-right: 1.75rem;
}
.p-orderlist .p-orderlist-filter-container .p-orderlist-filter-icon {
    right: 0.75rem;
    color: #495057;
}
.p-orderlist .p-orderlist-list {
    border: 1px solid #dee2e6;
    background: #ffffff;
    color: #212529;
    padding: 0.5rem 0;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
}
.p-orderlist .p-orderlist-list .p-orderlist-item {
    padding: 0.5rem 1.5rem;
    margin: 0;
    border: 0 none;
    color: #212529;
    background: transparent;
    transition: box-shadow 0.15s;
}
.p-orderlist .p-orderlist-list .p-orderlist-item:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.15rem rgba(38, 143, 255, 0.5);
}
.p-orderlist .p-orderlist-list .p-orderlist-item.p-highlight {
    color: #ffffff;
    background: $color-blue-light;
}
.p-orderlist .p-orderlist-list:not(.cdk-drop-list-dragging) .p-orderlist-item:not(.p-highlight):hover {
    background: #e9ecef;
    color: #212529;
}

.p-orderlist-item.cdk-drag-preview {
    padding: 0.5rem 1.5rem;
    box-shadow: none;
    border: 0 none;
    color: #212529;
    background: #ffffff;
    margin: 0;
}

.p-organizationchart .p-organizationchart-node-content.p-organizationchart-selectable-node:not(.p-highlight):hover {
    background: #e9ecef;
    color: #212529;
}
.p-organizationchart .p-organizationchart-node-content.p-highlight {
    background: $color-blue-light;
    color: #ffffff;
}
.p-organizationchart .p-organizationchart-node-content.p-highlight .p-node-toggler i {
    color: #003e80;
}
.p-organizationchart .p-organizationchart-line-down {
    background: #dee2e6;
}
.p-organizationchart .p-organizationchart-line-left {
    border-right: 1px solid #dee2e6;
    border-color: #dee2e6;
}
.p-organizationchart .p-organizationchart-line-top {
    border-top: 1px solid #dee2e6;
    border-color: #dee2e6;
}
.p-organizationchart .p-organizationchart-node-content {
    border: 1px solid #dee2e6;
    background: #ffffff;
    color: #212529;
    padding: 1.25rem;
}
.p-organizationchart .p-organizationchart-node-content .p-node-toggler {
    background: inherit;
    color: inherit;
    border-radius: 50%;
}
.p-organizationchart .p-organizationchart-node-content .p-node-toggler:focus {
    outline: 0 none;
    outline-offset: 0;

}

.p-paginator {
    background: #ffffff;
    color: $color-blue-light;
    border: solid #dee2e6;
    border-width: 0;
    padding: 0.75rem;
    border-radius: 4px;
}
.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last {
    background-color: #ffffff;
    border: 1px solid #dee2e6;
    color: $color-blue-light;
    min-width: 2.357rem;
    height: 2.357rem;
    margin: 0 0 0 -1px;
    transition: box-shadow 0.15s;
    border-radius: 0;
}
.p-paginator .p-paginator-first:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-prev:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-next:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-last:not(.p-disabled):not(.p-highlight):hover {
    background: #e9ecef;
    border-color: #dee2e6;
    color: $color-blue-light;
}
.p-paginator .p-paginator-first {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}
.p-paginator .p-paginator-last {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}
.p-paginator .p-dropdown {
    margin-left: 0.5rem;
    height: 2.357rem;
}
.p-paginator .p-dropdown .p-dropdown-label {
    padding-right: 0;
}
.p-paginator .p-paginator-current {
    background-color: #ffffff;
    border: 1px solid #dee2e6;
    color: $color-blue-light;
    min-width: 2.357rem;
    height: 2.357rem;
    margin: 0 0 0 -1px;
    padding: 0 0.5rem;
}
.p-paginator .p-paginator-pages .p-paginator-page {
    background-color: #ffffff;
    border: 1px solid #dee2e6;
    color: $color-blue-light;
    min-width: 2.357rem;
    height: 2.357rem;
    margin: 0 0 0 -1px;
    transition: box-shadow 0.15s;
    border-radius: 0;
}
.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    background: $color-blue-light;
    border-color: $color-blue-light;
    color: #ffffff;
}
.p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover {
    background: #e9ecef;
    border-color: #dee2e6;
    color: $color-blue-light;
}

.p-picklist .p-picklist-buttons {
    padding: 1.25rem;
}
.p-picklist .p-picklist-buttons .p-button {
    margin-bottom: 0.5rem;
}
.p-picklist .p-picklist-header {
    background: #efefef;
    color: #212529;
    border: 1px solid #dee2e6;
    padding: 1rem 1.25rem;
    border-bottom: 0 none;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
}
.p-picklist .p-picklist-header .p-picklist-title {
    font-weight: 600;
}
.p-picklist .p-picklist-filter-container {
    padding: 1rem 1.25rem;
    background: #ffffff;
    border: 1px solid #dee2e6;
    border-bottom: 0 none;
}
.p-picklist .p-picklist-filter-container .p-picklist-filter-input {
    padding-right: 1.75rem;
}
.p-picklist .p-picklist-filter-container .p-picklist-filter-icon {
    right: 0.75rem;
    color: #495057;
}
.p-picklist .p-picklist-list {
    border: 1px solid #dee2e6;
    background: #ffffff;
    color: #212529;
    padding: 0.5rem 0;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
}
.p-picklist .p-picklist-list .p-picklist-item {
    padding: 0.5rem 1.5rem;
    margin: 0;
    border: 0 none;
    color: #212529;
    background: transparent;
    transition: box-shadow 0.15s;
}
.p-picklist .p-picklist-list .p-picklist-item:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.15rem rgba(38, 143, 255, 0.5);
}
.p-picklist .p-picklist-list .p-picklist-item.p-highlight {
    color: #ffffff;
    background: $color-blue-light;
}
.p-picklist .p-picklist-list .p-picklist-empty-message {
    padding: 0.5rem 1.5rem;
    color: #212529;
}
.p-picklist .p-picklist-list:not(.cdk-drop-list-dragging) .p-picklist-item:not(.p-highlight):hover {
    background: #e9ecef;
    color: #212529;
}

.p-picklist-item.cdk-drag-preview {
    padding: 0.5rem 1.5rem;
    box-shadow: none;
    border: 0 none;
    color: #212529;
    background: #ffffff;
    margin: 0;
}

.p-timeline .p-timeline-event-marker {
    border: 0 none;
    border-radius: 50%;
    width: 1rem;
    height: 1rem;
    background-color: $color-blue-light;
}
.p-timeline .p-timeline-event-connector {
    background-color: #dee2e6;
}
.p-timeline.p-timeline-vertical .p-timeline-event-opposite,
.p-timeline.p-timeline-vertical .p-timeline-event-content {
    padding: 0 1rem;
}
.p-timeline.p-timeline-vertical .p-timeline-event-connector {
    width: 2px;
}
.p-timeline.p-timeline-horizontal .p-timeline-event-opposite,
.p-timeline.p-timeline-horizontal .p-timeline-event-content {
    padding: 1rem 0;
}
.p-timeline.p-timeline-horizontal .p-timeline-event-connector {
    height: 2px;
}

.p-tree {
    border: 1px solid #dee2e6;
    background: #ffffff;
    color: #212529;
    padding: 1.25rem;
    border-radius: 4px;
}
.p-tree .p-tree-container .p-treenode {
    padding: 0.143rem;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content {
    border-radius: 4px;
    transition: box-shadow 0.15s;
    padding: 0.5rem;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler {
    margin-right: 0.5rem;
    width: 2rem;
    height: 2rem;
    color: #6c757d;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: box-shadow 0.15s;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:enabled:hover {
    color: #495057;
    border-color: transparent;
    background: transparent;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:focus {
    outline: 0 none;
    outline-offset: 0;

}
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-treenode-icon {
    margin-right: 0.5rem;
    color: #6c757d;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox {
    margin-right: 0.5rem;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox .p-indeterminate .p-checkbox-icon {
    color: #212529;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content:focus {
    outline: 0 none;
    outline-offset: 0;

}
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
    background: $color-blue-light;
    color: #ffffff;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler,
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-treenode-icon {
    color: #ffffff;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler:hover,
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-treenode-icon:hover {
    color: #ffffff;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {
    background: #e9ecef;
    color: #212529;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-dragover {
    background: #e9ecef;
    color: #212529;
}
.p-tree .p-tree-filter-container {
    margin-bottom: 0.5rem;
}
.p-tree .p-tree-filter-container .p-tree-filter {
    width: 100%;
    padding-right: 1.75rem;
}
.p-tree .p-tree-filter-container .p-tree-filter-icon {
    right: 0.75rem;
    color: #495057;
}
.p-tree .p-treenode-children {
    padding: 0 0 0 1rem;
}
.p-tree .p-tree-loading-icon {
    font-size: 2rem;
}
.p-tree .p-treenode-droppoint.p-treenode-droppoint-active {
    background-color: #0062cc;
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content {
    border-radius: 4px;
    border: 1px solid #dee2e6;
    background-color: #ffffff;
    color: #212529;
    padding: 0.5rem;
    transition: box-shadow 0.15s;
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content.p-highlight {
    background-color: $color-blue-light;
    color: #ffffff;
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content.p-highlight .p-treenode-icon {
    color: #ffffff;
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-tree-toggler {
    margin-right: 0.5rem;
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-treenode-icon {
    color: #6c757d;
    margin-right: 0.5rem;
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-checkbox {
    margin-right: 0.5rem;
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-treenode-label:not(.p-highlight):hover {
    background-color: inherit;
    color: inherit;
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {
    background: #e9ecef;
    color: #212529;
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content:focus {
    outline: 0 none;
    outline-offset: 0;

}

.p-treetable .p-paginator-top {
    border-width: 1px 0 0 0;
    border-radius: 0;
}
.p-treetable .p-paginator-bottom {
    border-width: 1px 0 0 0;
    border-radius: 0;
}
.p-treetable .p-treetable-header {
    background: #efefef;
    color: #212529;
    border: solid #dee2e6;
    border-width: 1px 0 0 0;
    padding: 1rem 1rem;
    font-weight: 600;
}
.p-treetable .p-treetable-footer {
    background: #efefef;
    color: #212529;
    border: 1px solid #dee2e6;
    border-width: 1px 0 1px 0;
    padding: 1rem 1rem;
    font-weight: 600;
}
.p-treetable .p-treetable-thead > tr > th {
    text-align: left;
    padding: 1rem 1rem;
    border: 1px solid #dee2e6;
    border-width: 1px 0 2px 0;
    font-weight: 600;
    color: #212529;
    background: #ffffff;
    transition: box-shadow 0.15s;
}
.p-treetable .p-treetable-tfoot > tr > td {
    text-align: left;
    padding: 1rem 1rem;
    border: 1px solid #dee2e6;
    border-width: 1px 0 1px 0;
    font-weight: 600;
    color: #212529;
    background: #ffffff;
}
.p-treetable .p-sortable-column {
    outline-color: rgba(38, 143, 255, 0.5);
}
.p-treetable .p-sortable-column .p-sortable-column-icon {
    color: #6c757d;
    margin-left: 0.5rem;
}
.p-treetable .p-sortable-column .p-sortable-column-badge {
    border-radius: 50%;
    height: 1.143rem;
    min-width: 1.143rem;
    line-height: 1.143rem;
    color: #ffffff;
    background: $color-blue-light;
    margin-left: 0.5rem;
}
.p-treetable .p-sortable-column:not(.p-highlight):hover {
    background: #e9ecef;
    color: #212529;
}
.p-treetable .p-sortable-column:not(.p-highlight):hover .p-sortable-column-icon {
    color: #6c757d;
}
.p-treetable .p-sortable-column.p-highlight {
    background: #ffffff;
    color: $color-blue-light;
}
.p-treetable .p-sortable-column.p-highlight .p-sortable-column-icon {
    color: $color-blue-light;
}
.p-treetable .p-treetable-tbody > tr {
    background: #ffffff;
    color: #212529;
    transition: box-shadow 0.15s;
    outline-color: rgba(38, 143, 255, 0.5);
}
.p-treetable .p-treetable-tbody > tr > td {
    text-align: left;
    border: 1px solid #dee2e6;
    border-width: 1px 0 0 0;
    padding: 1rem 1rem;
}
.p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler {
    width: 2rem;
    height: 2rem;
    color: #6c757d;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: box-shadow 0.15s;
    margin-right: 0.5rem;
}
.p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler:enabled:hover {
    color: #495057;
    border-color: transparent;
    background: transparent;
}
.p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler:focus {
    outline: 0 none;
    outline-offset: 0;

}
.p-treetable .p-treetable-tbody > tr > td p-treetablecheckbox .p-checkbox {
    margin-right: 0.5rem;
}
.p-treetable .p-treetable-tbody > tr > td p-treetablecheckbox .p-checkbox .p-indeterminate .p-checkbox-icon {
    color: #212529;
}
.p-treetable .p-treetable-tbody > tr.p-highlight {
    background: $color-blue-light;
    color: #ffffff;
}
.p-treetable .p-treetable-tbody > tr.p-highlight .p-treetable-toggler {
    color: #ffffff;
}
.p-treetable .p-treetable-tbody > tr.p-highlight .p-treetable-toggler:hover {
    color: #ffffff;
}
.p-treetable.p-treetable-hoverable-rows .p-treetable-tbody > tr:not(.p-highlight):hover {
    background: #e9ecef;
    color: #212529;
}
.p-treetable.p-treetable-hoverable-rows .p-treetable-tbody > tr:not(.p-highlight):hover .p-treetable-toggler {
    color: #212529;
}
.p-treetable .p-column-resizer-helper {
    background: $color-blue-light;
}
.p-treetable .p-treetable-scrollable-header,
.p-treetable .p-treetable-scrollable-footer {
    background: #efefef;
}
.p-treetable .p-treetable-loading-icon {
    font-size: 2rem;
}
.p-treetable.p-treetable-gridlines .p-datatable-header {
    border-width: 1px 1px 0 1px;
}
.p-treetable.p-treetable-gridlines .p-treetable-footer {
    border-width: 0 1px 1px 1px;
}
.p-treetable.p-treetable-gridlines .p-treetable-top {
    border-width: 0 1px 0 1px;
}
.p-treetable.p-treetable-gridlines .p-treetable-bottom {
    border-width: 0 1px 1px 1px;
}
.p-treetable.p-treetable-gridlines .p-treetable-thead > tr > th {
    border-width: 1px;
}
.p-treetable.p-treetable-gridlines .p-treetable-tbody > tr > td {
    border-width: 1px;
}
.p-treetable.p-treetable-gridlines .p-treetable-tfoot > tr > td {
    border-width: 1px;
}
.p-treetable.p-treetable-sm .p-treetable-header {
    padding: 0.875rem 0.875rem;
}
.p-treetable.p-treetable-sm .p-treetable-thead > tr > th {
    padding: 0.5rem 0.5rem;
}
.p-treetable.p-treetable-sm .p-treetable-tbody > tr > td {
    padding: 0.5rem 0.5rem;
}
.p-treetable.p-treetable-sm .p-treetable-tfoot > tr > td {
    padding: 0.5rem 0.5rem;
}
.p-treetable.p-treetable-sm .p-treetable-footer {
    padding: 0.5rem 0.5rem;
}
.p-treetable.p-treetable-lg .p-treetable-header {
    padding: 1.25rem 1.25rem;
}
.p-treetable.p-treetable-lg .p-treetable-thead > tr > th {
    padding: 1.25rem 1.25rem;
}
.p-treetable.p-treetable-lg .p-treetable-tbody > tr > td {
    padding: 1.25rem 1.25rem;
}
.p-treetable.p-treetable-lg .p-treetable-tfoot > tr > td {
    padding: 1.25rem 1.25rem;
}
.p-treetable.p-treetable-lg .p-treetable-footer {
    padding: 1.25rem 1.25rem;
}

.p-virtualscroller .p-virtualscroller-header {
    background: #efefef;
    color: #212529;
    border: solid #dee2e6;
    border-width: 1px 0 0 0;
    padding: 1rem 1rem;
    font-weight: 600;
}
.p-virtualscroller .p-virtualscroller-content {
    background: #ffffff;
    color: #212529;
    border: 0 none;
    padding: 0;
}
.p-virtualscroller .p-virtualscroller-footer {
    background: #efefef;
    color: #212529;
    border: 1px solid #dee2e6;
    border-width: 1px 0 1px 0;
    padding: 1rem 1rem;
    font-weight: 600;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.p-accordion .p-accordion-header .p-accordion-header-link {
    padding: 1rem 1.25rem;
    border: 1px solid #dee2e6;
    color: $color-blue-light;
    background: #efefef;
    font-weight: 600;
    border-radius: 4px;
    transition: box-shadow 0.15s;
}
.p-accordion .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon {
    margin-right: 0.5rem;
}
.p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
    outline: 0 none;
    outline-offset: 0;

}
.p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link {
    background: #e9ecef;
    border-color: #dee2e6;
    color: #212529;
}
.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
    background: #efefef;
    border-color: #dee2e6;
    color: #212529;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}
.p-accordion .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link {
    border-color: #dee2e6;
    background: #e9ecef;
    color: #212529;
}
.p-accordion .p-accordion-content {
    padding: 1.25rem;
    border: 1px solid #dee2e6;
    background: #ffffff;
    color: #212529;
    border-top: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
}
.p-accordion p-accordiontab .p-accordion-tab {
    margin-bottom: 0;
}
.p-accordion p-accordiontab .p-accordion-header .p-accordion-header-link {
    border-radius: 0;
}
.p-accordion p-accordiontab .p-accordion-content {
    border-radius: 0;
}
.p-accordion p-accordiontab:not(:first-child) .p-accordion-header .p-accordion-header-link {
    border-top: 0 none;
}
.p-accordion p-accordiontab:not(:first-child) .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link, .p-accordion p-accordiontab:not(:first-child) .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link {
    border-top: 0 none;
}
.p-accordion p-accordiontab:first-child .p-accordion-header .p-accordion-header-link {
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
}
.p-accordion p-accordiontab:last-child .p-accordion-header:not(.p-highlight) .p-accordion-header-link {
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
}
.p-accordion p-accordiontab:last-child .p-accordion-content {
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
}

.p-card {
    background: #ffffff;
    color: #212529;
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
    border-radius: 4px;
}
.p-card .p-card-body {
    padding: 1.5rem;
}
.p-card .p-card-title {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
}
.p-card .p-card-subtitle {
    font-weight: 400;
    margin-bottom: 0.5rem;
    color: #6c757d;
}
.p-card .p-card-content {
    padding: 1rem 0;
}
.p-card .p-card-footer {
    padding: 1rem 0 0 0;
}

.p-divider .p-divider-content {
    background-color: #ffffff;
}
.p-divider.p-divider-horizontal {
    margin: 1rem 0;
    padding: 0 1rem;
}
.p-divider.p-divider-horizontal:before {
    border-top: 1px #dee2e6;
}
.p-divider.p-divider-horizontal .p-divider-content {
    padding: 0 0.5rem;
}
.p-divider.p-divider-vertical {
    margin: 0 1rem;
    padding: 1rem 0;
}
.p-divider.p-divider-vertical:before {
    border-left: 1px #dee2e6;
}
.p-divider.p-divider-vertical .p-divider-content {
    padding: 0.5rem 0;
}

.p-field {
    display: flex;
    flex-direction: column;
    position: relative;

    > label {
        color: #4b5257;
        font-size: $paragraphFontSizeSm;
        font-weight: $paragraphFontWeightRegular;
        margin: 4px 0;
    }

    .p-disabled, .p-component:disabled {
        background-color: #EFEFEF4D;
        &.p-inputtext {
            background-color: #c5c5c54d
        }
    }

    .p-inputtextarea {
        border-radius: 15px;
        &.ng-invalid {
            border-color: #dc3545;
        }
    }

    span {
        &.p-help-text {
            margin-top: 5px;
            color: $grey-400;
            font-size: $paragraphFontSizeXs;
            font-weight: $paragraphFontWeightRegular;
        }
    }

    .switch-item {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        margin: 2px 0 0 0;

        .label-switch {
            margin-left: 10px;
            font-weight: 600;
            color: #555;
        }
    }

    .spinner-loader {
        position: absolute;
        right: 7px;
        bottom: 0px;
        .icon-success {
            position: absolute;
            left: -15px;
            top: -21px;
            color: green;
        }
    }
}

.p-field-checkbox {
    label {
        margin: 0 0 0 5px;
    }
}

.p-field, .p-field-checkbox {
    input {
        height: 30px;
    }
    .required {
        font-size: 12px;
        color: #d00000;
        margin-left: 2px;
        margin-top: 0px;
    }
}

.p-field.info {
    label {
        display: flex;
        align-items: center;
        .info-container {
            position: relative;
            height: 18px;
            width: 18px;
            background-color: #a0b4cb;
            border-radius: 100%;
            margin-left: 5px;
            span {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                color: white;
                text-align: center;
                font-family: system-ui;
                font-size: 12px;
                font-weight: 600;
            }
        }
    }
}

.p-fieldset {
    border: 1px solid #dee2e6;
    background: #ffffff;
    color: #212529;
    border-radius: 4px;
}
.p-fieldset .p-fieldset-legend {
    padding: 1rem 1.25rem;
    border: 1px solid #dee2e6;
    color: #212529;
    background: #efefef;
    font-weight: 600;
    border-radius: 4px;
}
.p-fieldset.p-fieldset-toggleable .p-fieldset-legend {
    padding: 0;
    transition: box-shadow 0.15s;
}
.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a {
    padding: 1rem 1.25rem;
    color: #212529;
    border-radius: 4px;
    transition: box-shadow 0.15s;
}
.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a .p-fieldset-toggler {
    margin-right: 0.5rem;
}
.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a:focus {
    outline: 0 none;
    outline-offset: 0;

}
.p-fieldset.p-fieldset-toggleable .p-fieldset-legend:hover {
    background: #e9ecef;
    border-color: #dee2e6;
    color: #212529;
}
.p-fieldset .p-fieldset-content {
    padding: 1.25rem;
}

.p-panel .p-panel-header {
    border: 1px solid #dee2e6;
    padding: 1rem 1.25rem;
    background: #efefef;
    color: #212529;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
}
.p-panel .p-panel-header .p-panel-title {
    font-weight: 600;
}
.p-panel .p-panel-header .p-panel-header-icon {
    width: 2rem;
    height: 2rem;
    color: #6c757d;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: box-shadow 0.15s;
}
.p-panel .p-panel-header .p-panel-header-icon:enabled:hover {
    color: #495057;
    border-color: transparent;
    background: transparent;
}
.p-panel .p-panel-header .p-panel-header-icon:focus {
    outline: 0 none;
    outline-offset: 0;

}
.p-panel.p-panel-toggleable .p-panel-header {
    padding: 0.5rem 1.25rem;
}
.p-panel .p-panel-content {
    padding: 1.25rem;
    border: 1px solid #dee2e6;
    background: #ffffff;
    color: #212529;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    border-top: 0 none;
}
.p-panel .p-panel-footer {
    padding: 0.5rem 1.25rem;
    border: 1px solid #dee2e6;
    background: #ffffff;
    color: #212529;
    border-top: 0 none;
}

.p-scrollpanel .p-scrollpanel-bar {
    background: #efefef;
    border: 0 none;
}

.p-splitter {
    border: 1px solid #dee2e6;
    background: #ffffff;
    border-radius: 4px;
    color: #212529;
}
.p-splitter .p-splitter-gutter {
    transition: box-shadow 0.15s;
    background: #efefef;
}
.p-splitter .p-splitter-gutter .p-splitter-gutter-handle {
    background: #4c4c4c;
}
.p-splitter .p-splitter-gutter-resizing {
    background: #dee2e6;
}

.p-tabview .p-tabview-nav {
    background: transparent;
    border: 1px solid #dee2e6;
    border-width: 0 0 1px 0;
}
.p-tabview .p-tabview-nav li {
    margin-right: 0;
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link {
    border: solid;
    border-width: 1px;
    border-color: #ffffff #ffffff #dee2e6 #ffffff;
    background: #ffffff;
    color: #6c757d;
    padding: 0.75rem 1rem;
    font-weight: 600;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    transition: box-shadow 0.15s;
    margin: 0 0 -1px 0;
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
    outline: 0 none;
    outline-offset: 0;

}
.p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
    background: #ffffff;
    border-color: #dee2e6;
    color: #6c757d;
}
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    background: #ffffff;
    border-color: #dee2e6 #dee2e6 #ffffff #dee2e6;
    color: #495057;
}
.p-tabview .p-tabview-left-icon {
    margin-right: 0.5rem;
}
.p-tabview .p-tabview-right-icon {
    margin-left: 0.5rem;
}
.p-tabview .p-tabview-close {
    margin-left: 0.5rem;
}
.p-tabview .p-tabview-panels {
    background: #ffffff;
    padding: 1.25rem;
    border: 0 none;
    color: #212529;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
}

.p-toolbar {
    background: #efefef;
    border: 1px solid #dee2e6;
    padding: 1rem 1.25rem;
    border-radius: 4px;
}
.p-toolbar .p-toolbar-separator {
    margin: 0 0.5rem;
}

.p-confirm-popup {
    background: #ffffff;
    color: #212529;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    box-shadow: none;
}
.p-confirm-popup .p-confirm-popup-content {
    padding: 1.25rem;
}
.p-confirm-popup .p-confirm-popup-footer {
    text-align: right;
    padding: 0.5rem 1.25rem;
}
.p-confirm-popup .p-confirm-popup-footer button {
    margin: 0 0.5rem 0 0;
    width: auto;
}
.p-confirm-popup .p-confirm-popup-footer button:last-child {
    margin: 0;
}
.p-confirm-popup:after {
    border: solid transparent;
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #ffffff;
}
.p-confirm-popup:before {
    border: solid transparent;
    border-color: rgba(0, 0, 0, 0);
    border-bottom-color: rgba(0, 0, 0, 0.2);
}
.p-confirm-popup.p-confirm-popup-flipped:after {
    border-top-color: #ffffff;
}
.p-confirm-popup.p-confirm-popup-flipped:before {
    border-top-color: rgba(0, 0, 0, 0.2);
}
.p-confirm-popup .p-confirm-popup-icon {
    font-size: 1.5rem;
}
.p-confirm-popup .p-confirm-popup-message {
    margin-left: 1rem;
}

.p-dialog {
    border-radius: 4px;
    box-shadow: none;
    border: 1px solid rgba(0, 0, 0, 0.2);
}
.p-dialog .p-dialog-header {
    border-bottom: 1px solid #e9ecef;
    background: #ffffff;
    color: #212529;
    padding: 1rem;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
}
.p-dialog .p-dialog-header .p-dialog-title {
    font-weight: 600;
    font-size: 1.25rem;
}
.p-dialog .p-dialog-header .p-dialog-header-icon {
    width: 2rem;
    height: 2rem;
    color: #6c757d;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: box-shadow 0.15s;
    margin-right: 0.5rem;
}
.p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
    color: #495057;
    border-color: transparent;
    background: transparent;
}
.p-dialog .p-dialog-header .p-dialog-header-icon:focus {
    outline: 0 none;
    outline-offset: 0;

}
.p-dialog .p-dialog-header .p-dialog-header-icon:last-child {
    margin-right: 0;
}
.p-dialog .p-dialog-content {
    background: #ffffff;
    color: #212529;
    padding: 1rem;
}
.p-dialog .p-dialog-footer {
    border-top: 1px solid #e9ecef;
    background: #ffffff;
    color: #212529;
    padding: 1rem;
    text-align: right;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
}
.p-dialog .p-dialog-footer button {
    margin: 0 0.5rem 0 0;
    width: auto;
}
.p-dialog.p-confirm-dialog .p-confirm-dialog-icon {
    font-size: 2rem;
}
.p-dialog.p-confirm-dialog .p-confirm-dialog-message {
    margin-left: 1rem;
}

.p-dialog-mask.p-component-overlay {
    background-color: rgba(0, 0, 0, 0.4);
}

.p-overlaypanel {
    background: #ffffff;
    color: #212529;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    box-shadow: none;
}
.p-overlaypanel .p-overlaypanel-content {
    padding: 1.25rem;
}
.p-overlaypanel .p-overlaypanel-close {
    background: $color-blue-light;
    color: #ffffff;
    width: 2rem;
    height: 2rem;
    transition: box-shadow 0.15s;
    border-radius: 50%;
    position: absolute;
    top: -1rem;
    right: -1rem;
}
.p-overlaypanel .p-overlaypanel-close:enabled:hover {
    background: #0069d9;
    color: #ffffff;
}
.p-overlaypanel:after {
    border: solid transparent;
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #ffffff;
}
.p-overlaypanel:before {
    border: solid transparent;
    border-color: rgba(0, 0, 0, 0);
    border-bottom-color: rgba(0, 0, 0, 0.2);
}
.p-overlaypanel.p-overlaypanel-flipped:after {
    border-top-color: #ffffff;
}
.p-overlaypanel.p-overlaypanel-flipped:before {
    border-top-color: rgba(0, 0, 0, 0.2);
}

.p-sidebar {
    background: #ffffff;
    color: #212529;
    padding: 1.25rem;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: none;
}
.p-sidebar .p-sidebar-close {
    width: 2rem;
    height: 2rem;
    color: #6c757d;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: box-shadow 0.15s;
}
.p-sidebar .p-sidebar-close:enabled:hover {
    color: #495057;
    border-color: transparent;
    background: transparent;
}
.p-sidebar .p-sidebar-close:focus {
    outline: 0 none;
    outline-offset: 0;

}

.p-sidebar-mask.p-component-overlay {
    background: rgba(0, 0, 0, 0.4);
}

.p-tooltip .p-tooltip-text {
    background: #212529;
    color: #ffffff;
    padding: 0.5rem 0.75rem;
    box-shadow: none;
    border-radius: 4px;
}
.p-tooltip.p-tooltip-right .p-tooltip-arrow {
    border-right-color: #212529;
}
.p-tooltip.p-tooltip-left .p-tooltip-arrow {
    border-left-color: #212529;
}
.p-tooltip.p-tooltip-top .p-tooltip-arrow {
    border-top-color: #212529;
}
.p-tooltip.p-tooltip-bottom .p-tooltip-arrow {
    border-bottom-color: #212529;
}

.p-fileupload .p-fileupload-buttonbar {
    background: #efefef;
    padding: 1rem 1.25rem;
    border: 1px solid #dee2e6;
    color: #212529;
    border-bottom: 0 none;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
}
.p-fileupload .p-fileupload-buttonbar .p-button {
    margin-right: 0.5rem;
}
.p-fileupload .p-fileupload-buttonbar .p-button.p-fileupload-choose.p-focus {
    outline: 0 none;
    outline-offset: 0;

}
.p-fileupload .p-fileupload-content {
    background: #ffffff;
    padding: 2rem 1rem;
    border: 1px solid #dee2e6;
    color: #212529;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
}
.p-fileupload .p-progressbar {
    height: 0.25rem;
}
.p-fileupload .p-fileupload-row > div {
    padding: 1rem 1rem;
}
.p-fileupload.p-fileupload-advanced .p-message {
    margin-top: 0;
}

.p-fileupload-choose:not(.p-disabled):hover {
    background: #0069d9;
    color: #ffffff;
    border-color: #0069d9;
}
.p-fileupload-choose:not(.p-disabled):active {
    background: #0062cc;
    color: #ffffff;
    border-color: #0062cc;
}

.p-breadcrumb {
    background: #efefef;
    border: 0 none;
    border-radius: 4px;
    padding: 1rem;
}
.p-breadcrumb ul li .p-menuitem-link {
    transition: box-shadow 0.15s;
    border-radius: 4px;
}
.p-breadcrumb ul li .p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;

}
.p-breadcrumb ul li .p-menuitem-link .p-menuitem-text {
    color: $color-blue-light;
}
.p-breadcrumb ul li .p-menuitem-link .p-menuitem-icon {
    color: $color-blue-light;
}
.p-breadcrumb ul li.p-breadcrumb-chevron {
    margin: 0 0.5rem 0 0.5rem;
    color: #6c757d;
}
.p-breadcrumb ul li:last-child .p-menuitem-text {
    color: #6c757d;
}
.p-breadcrumb ul li:last-child .p-menuitem-icon {
    color: #6c757d;
}

.p-contextmenu {
    padding: 0.5rem 0;
    background: #ffffff;
    color: #212529;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: none;
    width: 12.5rem;
}
.p-contextmenu .p-menuitem-link {
    padding: 0.75rem 1rem;
    color: #212529;
    border-radius: 0;
    transition: box-shadow 0.15s;
    user-select: none;
}
.p-contextmenu .p-menuitem-link .p-menuitem-text {
    color: #212529;
}
.p-contextmenu .p-menuitem-link .p-menuitem-icon {
    color: #212529;
    margin-right: 0.5rem;
}
.p-contextmenu .p-menuitem-link .p-submenu-icon {
    color: #212529;
}
.p-contextmenu .p-menuitem-link:not(.p-disabled):hover {
    background: #e9ecef;
}
.p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: #212529;
}
.p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
    color: #212529;
}
.p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    color: #212529;
}
.p-contextmenu .p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.15rem rgba(38, 143, 255, 0.5);
}
.p-contextmenu .p-submenu-list {
    padding: 0.5rem 0;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: none;
}
.p-contextmenu .p-menuitem {
    margin: 0;
}
.p-contextmenu .p-menuitem:last-child {
    margin: 0;
}
.p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
    background: #e9ecef;
}
.p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
    color: #212529;
}
.p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon, .p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
    color: #212529;
}
.p-contextmenu .p-menu-separator {
    border-top: 1px solid #dee2e6;
    margin: 0.5rem 0;
}
.p-contextmenu .p-submenu-icon {
    font-size: 0.875rem;
}

.p-megamenu {
    padding: 0.5rem 1rem;
    background: #efefef;
    color: rgba(0, 0, 0, 0.9);
    border: 0 none;
    border-radius: 4px;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link {
    padding: 1rem;
    color: rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    transition: box-shadow 0.15s;
    user-select: none;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text {
    color: rgba(0, 0, 0, 0.5);
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon {
    color: rgba(0, 0, 0, 0.5);
    margin-right: 0.5rem;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link .p-submenu-icon {
    color: rgba(0, 0, 0, 0.5);
    margin-left: 0.5rem;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover {
    background: transparent;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: rgba(0, 0, 0, 0.7);
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
    color: rgba(0, 0, 0, 0.7);
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    color: rgba(0, 0, 0, 0.7);
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.15rem rgba(38, 143, 255, 0.5);
}
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link,
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover {
    background: transparent;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text,
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: rgba(0, 0, 0, 0.9);
}
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon,
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
    color: rgba(0, 0, 0, 0.9);
}
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon,
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    color: rgba(0, 0, 0, 0.9);
}
.p-megamenu .p-menuitem-link {
    padding: 0.75rem 1rem;
    color: #212529;
    border-radius: 0;
    transition: box-shadow 0.15s;
    user-select: none;
}
.p-megamenu .p-menuitem-link .p-menuitem-text {
    color: #212529;
}
.p-megamenu .p-menuitem-link .p-menuitem-icon {
    color: #212529;
    margin-right: 0.5rem;
}
.p-megamenu .p-menuitem-link .p-submenu-icon {
    color: #212529;
}
.p-megamenu .p-menuitem-link:not(.p-disabled):hover {
    background: #e9ecef;
}
.p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: #212529;
}
.p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
    color: #212529;
}
.p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    color: #212529;
}
.p-megamenu .p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.15rem rgba(38, 143, 255, 0.5);
}
.p-megamenu .p-megamenu-panel {
    background: #ffffff;
    color: #212529;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: none;
}
.p-megamenu .p-megamenu-submenu-header {
    margin: 0;
    padding: 0.75rem 1rem;
    color: #212529;
    background: #ffffff;
    font-weight: 600;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
}
.p-megamenu .p-megamenu-submenu {
    padding: 0.5rem 0;
    width: 12.5rem;
}
.p-megamenu .p-megamenu-submenu .p-menu-separator {
    border-top: 1px solid #dee2e6;
    margin: 0.5rem 0;
}
.p-megamenu .p-megamenu-submenu .p-menuitem {
    margin: 0;
}
.p-megamenu .p-megamenu-submenu .p-menuitem:last-child {
    margin: 0;
}
.p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
    background: #e9ecef;
}
.p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
    color: #212529;
}
.p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon, .p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
    color: #212529;
}
.p-megamenu.p-megamenu-vertical {
    width: 12.5rem;
    padding: 0.5rem 0;
}
.p-megamenu.p-megamenu-vertical .p-menuitem {
    margin: 0;
}
.p-megamenu.p-megamenu-vertical .p-menuitem:last-child {
    margin: 0;
}

.p-menu {
    padding: 0.5rem 0;
    background: #ffffff;
    color: #212529;
    border: 1px solid #dee2e6;
    border-radius: 4px;
    width: 12.5rem;
}
.p-menu .p-menuitem-link {
    padding: 0.75rem 1rem;
    color: #212529;
    border-radius: 0;
    transition: box-shadow 0.15s;
    user-select: none;
}
.p-menu .p-menuitem-link .p-menuitem-text {
    color: #212529;
}
.p-menu .p-menuitem-link .p-menuitem-icon {
    color: #212529;
    margin-right: 0.5rem;
}
.p-menu .p-menuitem-link .p-submenu-icon {
    color: #212529;
}
.p-menu .p-menuitem-link:not(.p-disabled):hover {
    background: #e9ecef;
}
.p-menu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: #212529;
}
.p-menu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
    color: #212529;
}
.p-menu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    color: #212529;
}
.p-menu .p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.15rem rgba(38, 143, 255, 0.5);
}
.p-menu.p-menu-overlay {
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: none;
}
.p-menu .p-submenu-header {
    margin: 0;
    padding: 0.75rem 1rem;
    color: #212529;
    background: #ffffff;
    font-weight: 600;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
}
.p-menu .p-menu-separator {
    border-top: 1px solid #dee2e6;
    margin: 0.5rem 0;
}
.p-menu .p-menuitem {
    margin: 0;
}
.p-menu .p-menuitem:last-child {
    margin: 0;
}

.p-menubar {
    padding: 0.5rem 1rem;
    background: #efefef;
    color: rgba(0, 0, 0, 0.9);
    border: 0 none;
    border-radius: 4px;
}
.p-menubar .p-menuitem-link {
    padding: 0.75rem 1rem;
    color: #212529;
    border-radius: 0;
    transition: box-shadow 0.15s;
    user-select: none;
}
.p-menubar .p-menuitem-link .p-menuitem-text {
    color: #212529;
}
.p-menubar .p-menuitem-link .p-menuitem-icon {
    color: #212529;
    margin-right: 0.5rem;
}
.p-menubar .p-menuitem-link .p-submenu-icon {
    color: #212529;
}
.p-menubar .p-menuitem-link:not(.p-disabled):hover {
    background: #e9ecef;
}
.p-menubar .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: #212529;
}
.p-menubar .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
    color: #212529;
}
.p-menubar .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    color: #212529;
}
.p-menubar .p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.15rem rgba(38, 143, 255, 0.5);
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link {
    padding: 1rem;
    color: rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    transition: box-shadow 0.15s;
    user-select: none;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text {
    color: rgba(0, 0, 0, 0.5);
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon {
    color: rgba(0, 0, 0, 0.5);
    margin-right: 0.5rem;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-submenu-icon {
    color: rgba(0, 0, 0, 0.5);
    margin-left: 0.5rem;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover {
    background: transparent;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: rgba(0, 0, 0, 0.7);
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
    color: rgba(0, 0, 0, 0.7);
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    color: rgba(0, 0, 0, 0.7);
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.15rem rgba(38, 143, 255, 0.5);
}
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link,
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover {
    background: transparent;
}
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text,
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: rgba(0, 0, 0, 0.9);
}
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon,
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
    color: rgba(0, 0, 0, 0.9);
}
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon,
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    color: rgba(0, 0, 0, 0.9);
}
.p-menubar .p-submenu-list {
    padding: 0.5rem 0;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: none;
    width: 12.5rem;
}
.p-menubar .p-submenu-list .p-menu-separator {
    border-top: 1px solid #dee2e6;
    margin: 0.5rem 0;
}
.p-menubar .p-submenu-list .p-submenu-icon {
    font-size: 0.875rem;
}
.p-menubar .p-submenu-list .p-menuitem {
    margin: 0;
}
.p-menubar .p-submenu-list .p-menuitem:last-child {
    margin: 0;
}
.p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link {
    background: #e9ecef;
}
.p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
    color: #212529;
}
.p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon, .p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
    color: #212529;
}

@media screen and (max-width: 960px) {
    .p-menubar {
        position: relative;
    }
    .p-menubar .p-menubar-button {
        display: flex;
        width: 2rem;
        height: 2rem;
        color: rgba(0, 0, 0, 0.5);
        border-radius: 50%;
        transition: box-shadow 0.15s;
    }
    .p-menubar .p-menubar-button:hover {
        color: rgba(0, 0, 0, 0.7);
        background: transparent;
    }
    .p-menubar .p-menubar-button:focus {
        outline: 0 none;
        outline-offset: 0;

    }
    .p-menubar .p-menubar-root-list {
        position: absolute;
        display: none;
        padding: 0.5rem 0;
        background: #ffffff;
        border: 1px solid rgba(0, 0, 0, 0.15);
        box-shadow: none;
        width: 100%;
    }
    .p-menubar .p-menubar-root-list .p-menu-separator {
        border-top: 1px solid #dee2e6;
        margin: 0.5rem 0;
    }
    .p-menubar .p-menubar-root-list .p-submenu-icon {
        font-size: 0.875rem;
    }
    .p-menubar .p-menubar-root-list > .p-menuitem {
        width: 100%;
        position: static;
    }
    .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link {
        padding: 0.75rem 1rem;
        color: #212529;
        border-radius: 0;
        transition: box-shadow 0.15s;
        user-select: none;
    }
    .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text {
        color: #212529;
    }
    .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon {
        color: #212529;
        margin-right: 0.5rem;
    }
    .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-submenu-icon {
        color: #212529;
    }
    .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover {
        background: #e9ecef;
    }
    .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
        color: #212529;
    }
    .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
        color: #212529;
    }
    .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
        color: #212529;
    }
    .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: inset 0 0 0 0.15rem rgba(38, 143, 255, 0.5);
    }
    .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link > .p-submenu-icon {
        margin-left: auto;
        transition: transform 0.15s;
    }
    .p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link > .p-submenu-icon {
        transform: rotate(-180deg);
    }
    .p-menubar .p-menubar-root-list .p-submenu-list {
        width: 100%;
        position: static;
        box-shadow: none;
        border: 0 none;
    }
    .p-menubar .p-menubar-root-list .p-submenu-list .p-submenu-icon {
        transition: transform 0.15s;
        transform: rotate(90deg);
    }
    .p-menubar .p-menubar-root-list .p-submenu-list .p-menuitem-active > .p-menuitem-link > .p-submenu-icon {
        transform: rotate(-90deg);
    }
    .p-menubar .p-menubar-root-list .p-menuitem {
        width: 100%;
        position: static;
    }
    .p-menubar .p-menubar-root-list ul li a {
        padding-left: 2.25rem;
    }
    .p-menubar .p-menubar-root-list ul li ul li a {
        padding-left: 3.75rem;
    }
    .p-menubar .p-menubar-root-list ul li ul li ul li a {
        padding-left: 5.25rem;
    }
    .p-menubar .p-menubar-root-list ul li ul li ul li ul li a {
        padding-left: 6.75rem;
    }
    .p-menubar .p-menubar-root-list ul li ul li ul li ul li ul li a {
        padding-left: 8.25rem;
    }
    .p-menubar.p-menubar-mobile-active .p-menubar-root-list {
        display: flex;
        flex-direction: column;
        top: 100%;
        left: 0;
        z-index: 1;
    }
}
.p-panelmenu .p-panelmenu-header > a {
    padding: 1rem 1.25rem;
    border: 1px solid #dee2e6;
    color: #212529;
    background: #efefef;
    font-weight: 600;
    border-radius: 4px;
    transition: box-shadow 0.15s;
}
.p-panelmenu .p-panelmenu-header > a .p-panelmenu-icon {
    margin-right: 0.5rem;
}
.p-panelmenu .p-panelmenu-header > a .p-menuitem-icon {
    margin-right: 0.5rem;
}
.p-panelmenu .p-panelmenu-header > a:focus {
    outline: 0 none;
    outline-offset: 0;

}
.p-panelmenu .p-panelmenu-header:not(.p-highlight):not(.p-disabled) > a:hover {
    background: #e9ecef;
    border-color: #dee2e6;
    color: #212529;
}
.p-panelmenu .p-panelmenu-header.p-highlight {
    margin-bottom: 0;
}
.p-panelmenu .p-panelmenu-header.p-highlight > a {
    background: #efefef;
    border-color: #dee2e6;
    color: #212529;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}
.p-panelmenu .p-panelmenu-header.p-highlight:not(.p-disabled) > a:hover {
    border-color: #dee2e6;
    background: #e9ecef;
    color: #212529;
}
.p-panelmenu .p-panelmenu-content {
    padding: 0.5rem 0;
    border: 1px solid #dee2e6;
    background: #ffffff;
    color: #212529;
    margin-bottom: 0;
    border-top: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link {
    padding: 0.75rem 1rem;
    color: #212529;
    border-radius: 0;
    transition: box-shadow 0.15s;
    user-select: none;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-menuitem-text {
    color: #212529;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-menuitem-icon {
    color: #212529;
    margin-right: 0.5rem;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-submenu-icon {
    color: #212529;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover {
    background: #e9ecef;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: #212529;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
    color: #212529;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    color: #212529;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.15rem rgba(38, 143, 255, 0.5);
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-panelmenu-icon {
    margin-right: 0.5rem;
}
.p-panelmenu .p-panelmenu-content .p-submenu-list:not(.p-panelmenu-root-submenu) {
    padding: 0 0 0 1rem;
}
.p-panelmenu .p-panelmenu-panel {
    margin-bottom: 0;
}
.p-panelmenu .p-panelmenu-panel .p-panelmenu-header > a {
    border-radius: 0;
}
.p-panelmenu .p-panelmenu-panel .p-panelmenu-content {
    border-radius: 0;
}
.p-panelmenu .p-panelmenu-panel:not(:first-child) .p-panelmenu-header > a {
    border-top: 0 none;
}
.p-panelmenu .p-panelmenu-panel:not(:first-child) .p-panelmenu-header:not(.p-highlight):not(.p-disabled):hover > a, .p-panelmenu .p-panelmenu-panel:not(:first-child) .p-panelmenu-header:not(.p-disabled).p-highlight:hover > a {
    border-top: 0 none;
}
.p-panelmenu .p-panelmenu-panel:first-child .p-panelmenu-header > a {
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
}
.p-panelmenu .p-panelmenu-panel:last-child .p-panelmenu-header:not(.p-highlight) > a {
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
}
.p-panelmenu .p-panelmenu-panel:last-child .p-panelmenu-content {
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
}

.p-slidemenu {
    padding: 0.5rem 0;
    background: #ffffff;
    color: #212529;
    border: 1px solid #dee2e6;
    border-radius: 4px;
    width: 12.5rem;
}
.p-slidemenu .p-menuitem-link {
    padding: 0.75rem 1rem;
    color: #212529;
    border-radius: 0;
    transition: box-shadow 0.15s;
    user-select: none;
}
.p-slidemenu .p-menuitem-link .p-menuitem-text {
    color: #212529;
}
.p-slidemenu .p-menuitem-link .p-menuitem-icon {
    color: #212529;
    margin-right: 0.5rem;
}
.p-slidemenu .p-menuitem-link .p-submenu-icon {
    color: #212529;
}
.p-slidemenu .p-menuitem-link:not(.p-disabled):hover {
    background: #e9ecef;
}
.p-slidemenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: #212529;
}
.p-slidemenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
    color: #212529;
}
.p-slidemenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    color: #212529;
}
.p-slidemenu .p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.15rem rgba(38, 143, 255, 0.5);
}
.p-slidemenu.p-slidemenu-overlay {
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: none;
}
.p-slidemenu .p-slidemenu-list {
    padding: 0.5rem 0;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: none;
}
.p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link {
    background: #e9ecef;
}
.p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link .p-slidemenu-text {
    color: #212529;
}
.p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link .p-slidemenu-icon, .p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link .p-slidemenu-icon {
    color: #212529;
}
.p-slidemenu .p-slidemenu-separator {
    border-top: 1px solid #dee2e6;
    margin: 0.5rem 0;
}
.p-slidemenu .p-slidemenu-icon {
    font-size: 0.875rem;
}
.p-slidemenu .p-slidemenu-backward {
    padding: 0.75rem 1rem;
    color: #212529;
}

.p-steps .p-steps-item .p-menuitem-link {
    background: transparent;
    transition: box-shadow 0.15s;
    border-radius: 4px;
    background: transparent;
}
.p-steps .p-steps-item .p-menuitem-link .p-steps-number {
    color: #212529;
    border: 1px solid #dee2e6;
    background: transparent;
    min-width: 2rem;
    height: 2rem;
    line-height: 2rem;
    font-size: 1.143rem;
    z-index: 1;
    border-radius: 4px;
}
.p-steps .p-steps-item .p-menuitem-link .p-steps-title {
    margin-top: 0.5rem;
    color: #6c757d;
}
.p-steps .p-steps-item .p-menuitem-link:not(.p-disabled):focus {
    outline: 0 none;
    outline-offset: 0;

}
.p-steps .p-steps-item.p-highlight .p-steps-number {
    background: $color-blue-light;
    color: #ffffff;
}
.p-steps .p-steps-item.p-highlight .p-steps-title {
    font-weight: 600;
    color: #212529;
}
.p-steps .p-steps-item:before {
    content: " ";
    border-top: 1px solid #dee2e6;
    width: 100%;
    top: 50%;
    left: 0;
    display: block;
    position: absolute;
    margin-top: -1rem;
}

.p-tabmenu .p-tabmenu-nav {
    background: transparent;
    border: 1px solid #dee2e6;
    border-width: 0 0 1px 0;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem {
    margin-right: 0;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
    border: solid;
    border-width: 1px;
    border-color: #ffffff #ffffff #dee2e6 #ffffff;
    background: #ffffff;
    color: #6c757d;
    padding: 0.75rem 1rem;
    font-weight: 600;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    transition: box-shadow 0.15s;
    margin: 0 0 -1px 0;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link .p-menuitem-icon {
    margin-right: 0.5rem;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link:not(.p-disabled):focus {
    outline: 0 none;
    outline-offset: 0;

}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem:not(.p-highlight):not(.p-disabled):hover .p-menuitem-link {
    background: #ffffff;
    border-color: #dee2e6;
    color: #6c757d;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
    background: #ffffff;
    border-color: #dee2e6 #dee2e6 #ffffff #dee2e6;
    color: #495057;
}

.p-tieredmenu {
    padding: 0.5rem 0;
    background: #ffffff;
    color: #212529;
    border: 1px solid #dee2e6;
    border-radius: 4px;
    width: 12.5rem;
}
.p-tieredmenu .p-menuitem-link {
    padding: 0.75rem 1rem;
    color: #212529;
    border-radius: 0;
    transition: box-shadow 0.15s;
    user-select: none;
}
.p-tieredmenu .p-menuitem-link .p-menuitem-text {
    color: #212529;
}
.p-tieredmenu .p-menuitem-link .p-menuitem-icon {
    color: #212529;
    margin-right: 0.5rem;
}
.p-tieredmenu .p-menuitem-link .p-submenu-icon {
    color: #212529;
}
.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover {
    background: #e9ecef;
}
.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: #212529;
}
.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
    color: #212529;
}
.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    color: #212529;
}
.p-tieredmenu .p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.15rem rgba(38, 143, 255, 0.5);
}
.p-tieredmenu.p-tieredmenu-overlay {
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: none;
}
.p-tieredmenu .p-submenu-list {
    padding: 0.5rem 0;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: none;
}
.p-tieredmenu .p-menuitem {
    margin: 0;
}
.p-tieredmenu .p-menuitem:last-child {
    margin: 0;
}
.p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
    background: #e9ecef;
}
.p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
    color: #212529;
}
.p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon, .p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
    color: #212529;
}
.p-tieredmenu .p-menu-separator {
    border-top: 1px solid #dee2e6;
    margin: 0.5rem 0;
}
.p-tieredmenu .p-submenu-icon {
    font-size: 0.875rem;
}

.p-inline-message {
    padding: 0.5rem 0.75rem;
    margin: 0;
    border-radius: 4px;
}
.p-inline-message.p-inline-message-info {
    background: #cce5ff;
    border: solid #b8daff;
    border-width: 0px;
    color: #004085;
}
.p-inline-message.p-inline-message-info .p-inline-message-icon {
    color: #004085;
}
.p-inline-message.p-inline-message-success {
    background: #d4edda;
    border: solid #c3e6cb;
    border-width: 0px;
    color: #155724;
}
.p-inline-message.p-inline-message-success .p-inline-message-icon {
    color: #155724;
}
.p-inline-message.p-inline-message-warn {
    background: #fff3cd;
    border: solid #ffeeba;
    border-width: 0px;
    color: #856404;
}
.p-inline-message.p-inline-message-warn .p-inline-message-icon {
    color: #856404;
}
.p-inline-message.p-inline-message-error {
    background: #f8d7da;
    border: solid #f5c6cb;
    border-width: 0px;
    color: #721c24;
}
.p-inline-message.p-inline-message-error .p-inline-message-icon {
    color: #721c24;
}
.p-inline-message .p-inline-message-icon {
    font-size: 1rem;
    margin-right: 0.5rem;
}
.p-inline-message .p-inline-message-text {
    font-size: 1rem;
}
.p-inline-message.p-inline-message-icon-only .p-inline-message-icon {
    margin-right: 0;
}

.p-message {
    margin: 1rem 0;
    border-radius: 4px;
}
.p-message .p-message-wrapper {
    padding: 1rem 1.25rem;
}
.p-message .p-message-close {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background: transparent;
    transition: box-shadow 0.15s;
}
.p-message .p-message-close:hover {
    background: rgba(255, 255, 255, 0.3);
}
.p-message .p-message-close:focus {
    outline: 0 none;
    outline-offset: 0;

}
.p-message.p-message-info {
    background: #cce5ff;
    border: solid #b8daff;
    border-width: 1px;
    color: #004085;
}
.p-message.p-message-info .p-message-icon {
    color: #004085;
}
.p-message.p-message-info .p-message-close {
    color: #004085;
}
.p-message.p-message-success {
    background: #d4edda;
    border: solid #c3e6cb;
    border-width: 1px;
    color: #155724;
}
.p-message.p-message-success .p-message-icon {
    color: #155724;
}
.p-message.p-message-success .p-message-close {
    color: #155724;
}
.p-message.p-message-warn {
    background: #fff3cd;
    border: solid #ffeeba;
    border-width: 1px;
    color: #856404;
}
.p-message.p-message-warn .p-message-icon {
    color: #856404;
}
.p-message.p-message-warn .p-message-close {
    color: #856404;
}
.p-message.p-message-error {
    background: #f8d7da;
    border: solid #f5c6cb;
    border-width: 1px;
    color: #721c24;
}
.p-message.p-message-error .p-message-icon {
    color: #721c24;
}
.p-message.p-message-error .p-message-close {
    color: #721c24;
}
.p-message .p-message-text {
    font-size: 1rem;
    font-weight: 500;
}
.p-message .p-message-icon {
    font-size: 1.5rem;
    margin-right: 0.5rem;
}
.p-message .p-message-summary {
    font-weight: 700;
}
.p-message .p-message-detail {
    margin-left: 0.5rem;
}

.p-toast {
    opacity: 1;
}
.p-toast .p-toast-message {
    margin: 0 0 1rem 0;
    box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
    border-radius: 4px;
}
.p-toast .p-toast-message .p-toast-message-content {
    padding: 1rem;
    border-width: 0;
}
.p-toast .p-toast-message .p-toast-message-content .p-toast-message-text {
    margin: 0 0 0 1rem;
}
.p-toast .p-toast-message .p-toast-message-content .p-toast-message-icon {
    font-size: 2rem;
}
.p-toast .p-toast-message .p-toast-message-content .p-toast-summary {
    font-weight: 700;
}
.p-toast .p-toast-message .p-toast-message-content .p-toast-detail {
    margin: 0.5rem 0 0 0;
}
.p-toast .p-toast-message .p-toast-icon-close {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background: transparent;
    transition: box-shadow 0.15s;
}
.p-toast .p-toast-message .p-toast-icon-close:hover {
    background: rgba(255, 255, 255, 0.3);
}
.p-toast .p-toast-message .p-toast-icon-close:focus {
    outline: 0 none;
    outline-offset: 0;

}
.p-toast .p-toast-message.p-toast-message-info {
    background: #cce5ff;
    border: solid #b8daff;
    border-width: 1px;
    color: #004085;
}
.p-toast .p-toast-message.p-toast-message-info .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-info .p-toast-icon-close {
    color: #004085;
}
.p-toast .p-toast-message.p-toast-message-success {
    background: #d4edda;
    border: solid #c3e6cb;
    border-width: 1px;
    color: #155724;
}
.p-toast .p-toast-message.p-toast-message-success .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-success .p-toast-icon-close {
    color: #155724;
}
.p-toast .p-toast-message.p-toast-message-warn {
    background: #fff3cd;
    border: solid #ffeeba;
    border-width: 1px;
    color: #856404;
}
.p-toast .p-toast-message.p-toast-message-warn .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-warn .p-toast-icon-close {
    color: #856404;
}
.p-toast .p-toast-message.p-toast-message-error {
    background: #f8d7da;
    border: solid #f5c6cb;
    border-width: 1px;
    color: #721c24;
}
.p-toast .p-toast-message.p-toast-message-error .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-error .p-toast-icon-close {
    color: #721c24;
}

.p-galleria .p-galleria-close {
    margin: 0.5rem;
    background: transparent;
    color: #efefef;
    width: 4rem;
    height: 4rem;
    transition: box-shadow 0.15s;
    border-radius: 4px;
}
.p-galleria .p-galleria-close .p-galleria-close-icon {
    font-size: 2rem;
}
.p-galleria .p-galleria-close:hover {
    background: rgba(255, 255, 255, 0.1);
    color: #efefef;
}
.p-galleria .p-galleria-item-nav {
    background: transparent;
    color: #efefef;
    width: 4rem;
    height: 4rem;
    transition: box-shadow 0.15s;
    border-radius: 4px;
    margin: 0 0.5rem;
}
.p-galleria .p-galleria-item-nav .p-galleria-item-prev-icon,
.p-galleria .p-galleria-item-nav .p-galleria-item-next-icon {
    font-size: 2rem;
}
.p-galleria .p-galleria-item-nav:not(.p-disabled):hover {
    background: rgba(255, 255, 255, 0.1);
    color: #efefef;
}
.p-galleria .p-galleria-caption {
    background: rgba(0, 0, 0, 0.5);
    color: #efefef;
    padding: 1rem;
}
.p-galleria .p-galleria-indicators {
    padding: 1rem;
}
.p-galleria .p-galleria-indicators .p-galleria-indicator button {
    background-color: #e9ecef;
    width: 1rem;
    height: 1rem;
    transition: box-shadow 0.15s;
    border-radius: 4px;
}
.p-galleria .p-galleria-indicators .p-galleria-indicator button:hover {
    background: #dee2e6;
}
.p-galleria .p-galleria-indicators .p-galleria-indicator.p-highlight button {
    background: $color-blue-light;
    color: #ffffff;
}
.p-galleria.p-galleria-indicators-bottom .p-galleria-indicator, .p-galleria.p-galleria-indicators-top .p-galleria-indicator {
    margin-right: 0.5rem;
}
.p-galleria.p-galleria-indicators-left .p-galleria-indicator, .p-galleria.p-galleria-indicators-right .p-galleria-indicator {
    margin-bottom: 0.5rem;
}
.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators {
    background: rgba(0, 0, 0, 0.5);
}
.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator button {
    background: rgba(255, 255, 255, 0.4);
}
.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator button:hover {
    background: rgba(255, 255, 255, 0.6);
}
.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator.p-highlight button {
    background: $color-blue-light;
    color: #ffffff;
}
.p-galleria .p-galleria-thumbnail-container {
    background: rgba(0, 0, 0, 0.9);
    padding: 1rem 0.25rem;
}
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-prev,
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-next {
    margin: 0.5rem;
    background-color: transparent;
    color: #efefef;
    width: 2rem;
    height: 2rem;
    transition: box-shadow 0.15s;
    border-radius: 4px;
}
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-prev:hover,
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-next:hover {
    background: rgba(255, 255, 255, 0.1);
    color: #efefef;
}
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-item-content:focus {
    outline: 0 none;
    outline-offset: 0;

}

.p-galleria-mask.p-component-overlay {
    background-color: rgba(0, 0, 0, 0.9);
}

.p-avatar {
    background-color: #dee2e6;
    border-radius: 4px;
}
.p-avatar.p-avatar-lg {
    width: 3rem;
    height: 3rem;
    font-size: 1.5rem;
}
.p-avatar.p-avatar-lg .p-avatar-icon {
    font-size: 1.5rem;
}
.p-avatar.p-avatar-xl {
    width: 4rem;
    height: 4rem;
    font-size: 2rem;
}
.p-avatar.p-avatar-xl .p-avatar-icon {
    font-size: 2rem;
}

.p-avatar-group .p-avatar {
    border: 2px solid #ffffff;
}

.p-badge {
    background: $color-blue-light;
    color: #ffffff;
    font-size: 0.75rem;
    font-weight: 700;
    min-width: 1.5rem;
    height: 1.5rem;
    line-height: 1.5rem;
}
.p-badge.p-badge-secondary {
    background-color: #6c757d;
    color: #ffffff;
}
.p-badge.p-badge-success {
    background-color: #28a745;
    color: #ffffff;
}
.p-badge.p-badge-info {
    background-color: #17a2b8;
    color: #ffffff;
}
.p-badge.p-badge-warning {
    background-color: #ffc107;
    color: #212529;
}
.p-badge.p-badge-danger {
    background-color: #dc3545;
    color: #ffffff;
}
.p-badge.p-badge-lg {
    font-size: 1.125rem;
    min-width: 2.25rem;
    height: 2.25rem;
    line-height: 2.25rem;
}
.p-badge.p-badge-xl {
    font-size: 1.5rem;
    min-width: 3rem;
    height: 3rem;
    line-height: 3rem;
}

.p-blockui.p-component-overlay {
    background: rgba(0, 0, 0, 0.4);
}

.p-chip {
    background-color: #dee2e6;
    color: #212529;
    border-radius: 16px;
    padding: 0 0.75rem;
}
.p-chip .p-chip-text {
    line-height: 1.5;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
}
.p-chip .p-chip-icon {
    margin-right: 0.5rem;
}
.p-chip .pi-chip-remove-icon {
    margin-left: 0.5rem;
}
.p-chip img {
    width: 2rem;
    height: 2rem;
    margin-left: -0.75rem;
    margin-right: 0.5rem;
}
.p-chip .pi-chip-remove-icon {
    border-radius: 4px;
    transition: box-shadow 0.15s;
}
.p-chip .pi-chip-remove-icon:focus {
    outline: 0 none;
    outline-offset: 0;

}

.p-inplace .p-inplace-display {
    padding: 0.5rem 0.75rem;
    border-radius: 4px;
    transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
}
.p-inplace .p-inplace-display:not(.p-disabled):hover {
    background: #e9ecef;
    color: #212529;
}
.p-inplace .p-inplace-display:focus {
    outline: 0 none;
    outline-offset: 0;

}

.p-progressbar {
    border: 0 none;
    height: 1.5rem;
    background: #e9ecef;
    border-radius: 4px;
}
.p-progressbar .p-progressbar-value {
    border: 0 none;
    margin: 0;
    background: $color-blue-light;
}
.p-progressbar .p-progressbar-label {
    color: #212529;
    line-height: 1.5rem;
}

.p-scrolltop {
    width: 3rem;
    height: 3rem;
    border-radius: 4px;
    box-shadow: none;
    transition: box-shadow 0.15s;
}
.p-scrolltop.p-link {
    background: rgba(0, 0, 0, 0.7);
}
.p-scrolltop.p-link:hover {
    background: rgba(0, 0, 0, 0.8);
}
.p-scrolltop .p-scrolltop-icon {
    font-size: 1.5rem;
    color: #efefef;
}

.p-skeleton {
    background-color: #e9ecef;
    border-radius: 4px;
}
.p-skeleton:after {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0));
}

.p-tag {
    background: $color-blue-light;
    color: #ffffff;
    font-size: 0.75rem;
    font-weight: 700;
    padding: 0.25rem 0.4rem;
    border-radius: 4px;
}
.p-tag.p-tag-success {
    background-color: #28a745;
    color: #ffffff;
}
.p-tag.p-tag-info {
    background-color: #17a2b8;
    color: #ffffff;
}
.p-tag.p-tag-warning {
    background-color: #ffc107;
    color: #212529;
}
.p-tag.p-tag-danger {
    background-color: #dc3545;
    color: #ffffff;
}
.p-tag .p-tag-icon {
    margin-right: 0.25rem;
    font-size: 0.75rem;
}

.p-terminal {
    background: #ffffff;
    color: #212529;
    border: 1px solid #dee2e6;
    padding: 1.25rem;
}
.p-terminal .p-terminal-input {
    font-size: 1rem;
    font-family: Poppins, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

/* Vendor extensions to the designer enhanced bootstrap compatibility */
.p-breadcrumb .p-breadcrumb-chevron {
    font-family: Poppins, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}
.p-breadcrumb .p-breadcrumb-chevron:before {
    content: "/";
}

/* Customizations to the designer theme should be defined here */

/* Add in component class="comexport-theme" */

.comexport-theme {

    /* Control size by styleClass="sm" */
    .sm {
        input.p-autocomplete-input {
            @include input-default-sm;
        }
    }

    .md {
        input.p-autocomplete-input {
            @include input-default-md;
        }
    }

    .lg {
        input.p-autocomplete-input {
            @include input-default-lg;
        }
    }

    &.sm {
        @include input-default-sm;
    }

    .p-autocomplete {
        width: 100%;

        input.p-autocomplete-input {
            width: 100%;
        }

        .p-autocomplete-panel {
            margin-top: 5px;
            border-radius: 25px;
            width: 100%;

            ul {
                padding: 0;

                li {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    padding: .5rem .8rem;
                }
            }
        }
    }

    &:enabled:focus {
        border: $default-input-border_focus;
        box-shadow: none;
        outline: none;
    }

    .p-dropdown {
        border-radius: 25px;

        &:not(.disabled).p-focus {
            border: $default-input-border_focus;
            box-shadow: none;
            outline: none;
        }

        &.full {
            width: 100%;
        }

        &.sm {
            @include input-dropdown-default-sm;

            .p-dropdown-trigger {
                width: 1.2rem;
                margin-left: 10px;
            }
        }

        &.md {
            @include input-default-md;
        }

        &.lg {
            @include input-default-lg;
        }

        &.for-table {
            border: none;
            box-shadow: none;
            outline: none;
            background-color: transparent;

            .p-dropdown-label {
                color: $color-grey;
            }
            .p-dropdown-trigger {
                transform: translateX(-5px) scale(0.7);
            }

            &.p-focus {
                border: none;
            }

            &.p-dropdown-open {

                .p-dropdown-trigger {
                    transform: translateX(-5px) scale(0.7) rotate(180deg);

                    .p-dropdown-trigger-icon {
                        color: $color-grey;
                    }

                }
            }
        }

        span {
            padding: 0;
            margin: 0;
        }

        .p-dropdown-clear-icon {
            margin-right: 10px;
        }

        .p-dropdown-panel {
            .p-dropdown-header {
                background-color: white;
                padding: 0 0 0 7px;

                .p-dropdown-filter-container {
                    .p-dropdown-filter-icon {
                        top: 12px;
                    }

                    .p-inputtext {
                        border: none;
                    }
                }
            }
        }
    }

    &.p-inputtext {
        width: 100%;

        &.sm {
            @include input-default-sm;

            &.p-input-icon {
                padding-left: 30px;
                width: calc(100% - 30px);
            }
        }

        &.md {
            @include input-default-md;
        }

        &.lg {
            @include input-default-lg;
        }
    }

    .p-inputnumber {
        width: 100%;

        input {
            width: 100%;
        }

        &.sm {

            input {
                width: 100%;
                @include input-default-sm;
            }
        }

        .currency {
            text-align: right;
        }

        &.for-table {

            input {
                border: none;
                box-shadow: none;
                outline: none;
                background-color: transparent;
                padding: 0;
                margin: 0;
            }

            &.right {

                input {
                    text-align: right;
                    font-size: .9rem;
                }
            }
        }

    }
    .p-accordion {

        p-accordiontab {

            div.p-accordion-tab {

                div.p-accordion-header {
                    background-color: transparent !important;
                    margin-bottom: 10px;

                    &.p-highlight {
                        a {
                            color: $color-blue-light !important;
                        }
                    }
                    a {
                        &:focus {
                            outline:0 !important;
                            box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
                        }
                        padding: 0;
                        font-size: 11px;

                        color: $color-blue-light;
                        border: none;
                        background-color: transparent !important;
                        outline: none !important;

                        &:hover {
                            background-color: transparent !important;
                            color: $color-blue !important;
                            text-decoration: none;
                        }
                        &:enabled, &:focus, &:active {
                            outline: none !important;
                            border: none !important;
                        }

                        .p-accordion-header-text {
                            line-height: 1.2 !important;
                        }

                        span.p-accordion-toggle-icon {
                            &::before {
                                font-size: 10px;
                            }
                            transform: translate(-3px,-3px);
                            margin-right: 0;
                        }
                    }
                }

                .p-accordion-content {
                    outline: none !important;
                    border: none !important;
                    padding: 0;
                    margin: 0;
                    background-color: transparent !important;

                    strong {
                        font-weight: 700;
                    }

                    span.link {
                        cursor: pointer;
                        color: black;
                        font-weight: 700;
                        text-decoration: underline;
                    }

                    p {
                        padding: 15px 0;
                        letter-spacing: 0em !important;

                        &:first-child {
                            padding-top: 0;
                        }
                    }

                    ul {
                        padding: 0 0 15px 0;
                        margin-left: 30px;

                        li {
                            letter-spacing: 0em !important;
                            padding: 3px 0;
                            list-style: square;
                            font-family: 'Source Sans Pro', sans-serif;
                        }
                    }
                }
            }
        }
    }

    .p-splitbutton {
        button.p-splitbutton {
            border: none;
        }

        button.p-splitbutton-defaultbutton {
            border-top-left-radius: 25px;
            border-bottom-left-radius: 25px;

            span.p-button-icon {
                padding-left: 5px;
            }
        }

        button.p-splitbutton-menubutton {
            border-top-right-radius: 25px;
            border-bottom-right-radius: 25px;

            span.p-button-icon {
                padding-right: 5px;
            }
        }

        p-menu {

            .p-menu {
                margin-top: 2px;
                border-radius: 25px;
                right: 0;
                left: auto !important;

                ul.p-menu-list {

                    li.p-menuitem {

                        &:first-child {
                            a {
                                &:hover {
                                    border-top-left-radius: 10px;
                                    border-top-right-radius: 10px;
                                }
                            }
                        }
                        &:last-child {
                            a {
                                &:hover {
                                    border-bottom-left-radius: 10px;
                                    border-bottom-right-radius: 10px;
                                }
                            }
                        }

                        a.p-menuitem-link {

                            span.p-menuitem-text {
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                overflow: hidden;
                                line-height: inherit;
                            }
                        }
                    }
                }
            }
        }
    }

    &.p-multiselect {
        width: 100%;
        border-radius: 25px;
        padding: 3px 12px;

        &:not(.p-disabled):hover {
            border-color: $color-grey;
        }

        .p-multiselect-label-container {

            .p-multiselect-label {

                padding: 3px 5px;
            }
        }

        .p-multiselect-panel {

            .p-multiselect-header {
                padding: 5px;
                background-color: transparent;
            }
        }

        .p-multiselect-trigger {
            width: 15px;

            span.pi {
                font-size: 10px;
                font-weight: 700;
                color: grey;
            }
        }

        .p-multiselect-panel {

            .p-multiselect-items-wrapper {

                .p-multiselect-items {

                    .p-multiselect-item {

                        &.p-highlight {
                            background-color: $color-grey-opaque;
                            color: $color-grey-dark
                        }

                        .p-checkbox {

                            .p-checkbox-box {

                                &.p-highlight {
                                    border-color: $color-blue-light;
                                }
                            }
                        }

                    }
                }
            }
        }
    }

    &.form-column {
        .message-empty-form {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;

            .message-text {
                transform: translateY(-50px);
                font-weight: 500;
                color: grey;
            }
        }
    }
}

.quotation-custom-multiselect {
    min-width: 205px;
    width: 205px;
    font-size: 12.6px;

    &.p-multiselect .p-multiselect-label.p-placeholder {
        padding: 3px 0px 3px 10px !important;
    }

    &.p-multiselect .p-multiselect-label {
        padding: 3px 0px 3px 10px !important;
    }

    .p-multiselect-panel {

        .p-multiselect-header {
            padding: 3px 5px 3px 21px;
            border-bottom: 1px solid #dee2e6;
            color: #212529;
            background: #ffffff;
            margin: 0;
            border-top-right-radius: 4px;
            border-top-left-radius: 4px;

            .p-inputtext {
                border: none;
                padding: 0;
            }
        }
    }
}

.modal-transportation-custom-multiselect {
    min-width: 205px;
    width: 205px;
    font-size: 12.6px;

    &.p-multiselect .p-multiselect-label.p-placeholder {
        padding: 3px 0px 3px 10px !important;
    }

    &.p-multiselect .p-multiselect-label {
        padding: 3px 0px 3px 10px !important;
    }

    .p-multiselect-panel {

        .p-multiselect-header {
            padding: 3px 5px 3px 21px;
            border-bottom: 1px solid #dee2e6;
            color: #212529;
            background: #ffffff;
            margin: 0;
            border-radius: 4px;
            border-radius: 4px;

            .p-inputtext {
                border: none;
                padding: 0;
            }
        }
    }
}
