//
//** "Datatable" Component
//

//==== Component Mixins ====//

$m_base_color: #716aca;

//== Component Base
@mixin m-build-component--datatable-default-base() {
	.m-datatable {
		display: none;
	}
	.m-datatable.m-datatable--default {
		display: block;

		//== Base
		> .m-datatable__table {
			border-collapse: collapse;
			overflow: hidden;
			display: none;

			> .m-datatable__head,
			> .m-datatable__body,
			> .m-datatable__foot {
				visibility: hidden;
				display: block;

				.m-datatable__row {
					width: 100%;

					> .m-datatable__cell {
						vertical-align: middle;
						padding: 11px 10px;
						font-size: get-font-size(regular);

						i {
							font-size: get-font-size(icon);
						}

						&:last-child {
							//border-right: 0;
						}

						&.m-datatable__cell--left {
							text-align: left;
							> span {
								text-align: left;
							}
						}

						&.m-datatable__cell--right {
							text-align: right;
							> span {
								text-align: right;
							}
						}

						&.m-datatable__cell--center {
							text-align: center;
							> span {
								text-align: center;
							}
						}

						&.m-datatable__cell--sort {
							cursor: pointer;
						}

						&.m-datatable__cell--resizing {
							cursor: col-resize;
						}

						> span {
							display: block;
							overflow: hidden;
							text-overflow: ellipsis;
						}

						&.m-datatable__cell--check {
							> span {
								overflow: visible;

								> .m-checkbox {
									top: 2px;
									padding: 0;
									margin: 0 0 0 0;
								}
							}
						}

						// cell dropdown menu
						.dropdown {
							display: inline-block;

							.dropdown-menu {
								margin-top: 0.5rem;
							}

							&.dropup {
								.dropdown-menu {
									margin-top: auto;
									margin-bottom: 0.5rem;
								}
							}

							.dropdown-menu.dropdown-menu-right {
								margin-right: -0.8rem;
							}

							.dropdown-menu.dropdown-menu-left {
								margin-left: -0.8rem;
							}
						}
					}
				}

				> .m-datatable__lock {
					border: 0;
				}
			}

			> .m-datatable__head,
			> .m-datatable__foot {
				.m-datatable__row {
					> .m-datatable__cell {
						font-weight: get-font-weight('boldest');
						vertical-align: middle;
					}
				}
			}

			> .m-datatable__head {
				.m-datatable__row {
					> .m-datatable__cell {
						padding: 16px 10px;

						> span {
							
							> i {
								display: inline-block;
								position: relative;
								right: -10px;
								line-height: 0;
								vertical-align: middle;
								font-size: 1.1rem;
							}
						}
					}
				}
			}

			> .m-datatable__body {
				.m-datatable__row {
					> .m-datatable__cell {
						font-weight: get-font-weight('regular');

						&.m-datatable__cell--check {
							> span {
								> .m-checkbox {
									top: 1px;
								}
							}
						}
					}
				}
			}
		}

		//== Error message
		&.m-datatable--error {
			.m-datatable__body {
				padding: 30px;
				text-align: center;

				.m-datatable__error {

				}
			}
		}

		//== Lock state
		&.m-datatable--lock {
			> .m-datatable__table {
				> .m-datatable__head,
				> .m-datatable__body,
				> .m-datatable__foot {
					> .m-datatable__lock {
						display: inline-block;
						position: relative;
						vertical-align: top;
						overflow: hidden;
					}
				}
			}
		}

		//== Loaded state
		&.m-datatable--loaded {
			display: block;

			> .m-datatable__table {
				display: block;

				> .m-datatable__head,
				> .m-datatable__body,
				> .m-datatable__foot {
					visibility: visible;
					display: block;

					.m-datatable__row {
						display: table;
						table-layout: initial;

						> .m-datatable__cell {
							display: table-cell;
						}
					}
				}
			}
		}

		//== Subtable
		&.m-datatable--subtable {
			> .m-datatable__table {
				> .m-datatable__head,
				> .m-datatable__body,
				> .m-datatable__foot {
					.m-datatable__row {
						width: 100%;
					}

					.m-datatable__toggle-subtable {
						> i {
							line-height: 0;
							font-size: get-font-size(icon);
						}

						&:hover {
							text-decoration: none;
						}
					}

					.m-datatable__row-detail {
						display: table;
						width: 100%;

						> .m-datatable__detail {
							padding: 20px;
						}
					}
				}
			}
		}

		//== Scrollable state
		&.m-datatable--scroll {
			> .m-datatable__table {
				display: block;

				> .m-datatable__head,
				> .m-datatable__body,
				> .m-datatable__foot {
				}

				> .m-datatable__head,
				> .m-datatable__foot {
					overflow: hidden;

					.m-datatable__row {
						position: relative;
					}
				}

				> .m-datatable__body {
					overflow: hidden;
				}
			}
		}

		//== Pagination
		$page-size: 2.25rem;

		> .m-datatable__pager {
			margin-top: 20px;

			&.m-datatable__pager--top {
				margin-bottom: 20px;
			}

			> .m-datatable__pager-nav {
				margin: 10px 0 0 0;
				padding: 0;
				display: inline-block;

				> li {
					padding: 0;
					margin-right: 5px;
					display: inline-block;

					&:last-child {
						margin-right: 0;
					}

					> .m-datatable__pager-link {
						cursor: pointer;
						display: inline-block;
						height: $page-size;
						width: $page-size;
						vertical-align: middle;
						padding: 0;
						@include border-radius(50%);
						text-align: center;
						position: relative;
						@include vertical-horizontal-center-flexbox();
						font-size: 1rem;
						line-height: 1rem;
						font-weight: get-font-weight(bold);

						> i {
							font-size: 1rem;
							text-align: center;
							display: inline-block;
						}

						&.m-datatable__pager-link--more-prev,
						&.m-datatable__pager-link--more-next {
							font-weight: get-font-weight(boldest);
						}
					}

					> .m-pager-input {
						height: $page-size;
						width: 3.5rem;
						text-align: center;
						@include border-radius(15px, '!important');
					}
				}
			}

			> .m-datatable__pager-info {
				float: right;
				margin-top: 10px;

				.m-datatable__pager-size {
					margin-right: 10px;

					.btn.dropdown-toggle {
						@include border-radius(20px, '!important');
						height: $page-size;
						padding: 0.45rem 1rem;
					}

					.dropdown-menu.inner > li.selected > a span.check-mark {
						margin-top: -0.6rem;
					}
				}

				.m-datatable__pager-detail {
				}
			}
		}

		//== Row Details sub table
		.m-datatable__row-detail {
			.m-datatable__detail {
				> .m-datatable {
					> .m-datatable__pager {
						margin-top: 10px;
						padding: 0 20px 15px 20px;
					}
				}
			}
		}
	}

	@include mobile {
		.m-datatable.m-datatable--default {
			> .m-datatable__pager {
				> .m-datatable__pager-info {
					float: none;
					display: block;
					margin: 10px 0 0 0;
				}
			}
		}
	}
}

//== Component Skin
@mixin m-build-component--datatable-default-skin($skin) {
	.m-datatable.m-datatable--default {
		//== Base color
		$base-color: lighten($m_base_color, 38.5%);

		//== Row colors
		$row-even: $base-color;
		$row-hover: darken($base-color, 1%);
		$row-active: darken($base-color, 4%);
		$row-detail-expanded: darken($base-color, 4%);

		//== Pagination colors
		$page-default: darken($base-color, 4%);
		$page-hover: darken($base-color, 8%);
		$page-active: $m_base_color;
		$page-active-font: get-brand-inverse-color();

		//== Lock table shadow
		$lock-shadow: 0px 0px 17px 5px rgba($m_base_color, 0.14);

		//== Base Table
		> .m-datatable__table {
			background-color: #fff;

			> .m-datatable__head,
			> .m-datatable__body,
			> .m-datatable__foot {
				> .m-datatable__lock {
					&.m-datatable__lock--left {
						z-index: 1;
						@include shadow($lock-shadow);
					}

					&.m-datatable__lock--right {
						@include shadow($lock-shadow);
					}
				}

				.m-datatable__row {
					> .m-datatable__cell {
						.m-checkbox.m-checkbox--solid > span {
							background: darken($base-color, 6%);
						}

						.m-checkbox.m-checkbox--solid > input:checked ~ span {
							background: $m_base_color;
						}
					}
				}
			}

			> .m-datatable__head,
			> .m-datatable__foot {
				.m-datatable__row {
					> .m-datatable__cell {
						background: darken($base-color, 2%);

						> span {
							color: get-color($skin, regular);
						}
					}
				}
			}

			> .m-datatable__head {
				.m-datatable__row {
					> .m-datatable__cell {
						> span {
							> i {
								color: get-brand-color('++');
							}
						}
					}
				}
			}

			> .m-datatable__body {
				.m-datatable__row {
					> .m-datatable__cell {
						> span {
							color: get-color(light, regular);
						}
					}

					&.m-datatable__row--even {
						> .m-datatable__cell {
							background: $row-even;
						}
					}

					&.m-datatable__row--active {
						> .m-datatable__cell {
							background: $row-active;
						}
					}

					&.m-datatable__row--hover:not(.m-datatable__row--active) {
						> .m-datatable__cell {
							background: $row-hover;
						}
					}
				}
			}
		}

		//== Error message
		&.m-datatable--error {
			.m-datatable__body {
				.m-datatable__error {

				}
			}
		}

		//== Subtable
		&.m-datatable--subtable {
			> .m-datatable__table {
				> .m-datatable__body {

					.m-datatable__toggle-subtable {
						> i {
							color: get-color($skin, icon);
							color: $m_base_color;
						}
					}

					.m-datatable__row {
						&.m-datatable__row--detail-expanded {
							> .m-datatable__cell {
								background: $row-detail-expanded !important;
							}
						}
					}

					.m-datatable__row-detail {
						border-top: 0;
						background: $row-even;

						> .m-datatable__detail {
							> .m-datatable {
								@include shadow(0px 0px 15px 1px rgba($m_base_color, 0.2));
							}
						}
					}
				}
			}
		}

		//== Scrollable
		&.m-datatable--scroll {
			//== scrollbar
			.mCustomScrollbar {
				.mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
					background: get-color($skin, panel, '+++++');
					@include opacity(0.7);
					@include transition(all 0.3s ease);
				}

				// on drag
				.mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
					background: get-color($skin, panel, '+++++');
					@include opacity(1);
					@include transition(all 0.3s ease);
				}
			}
		}

		//== Pagination
		> .m-datatable__pager {
			> .m-datatable__pager-nav {
				> li {
					> .m-datatable__pager-link {
						color: get-color($skin, regular, '---');

						&.m-datatable__pager-link--first,
						&.m-datatable__pager-link--prev,
						&.m-datatable__pager-link--next,
						&.m-datatable__pager-link--last {
							color: $m_base_color;
							background: $page-default;

							&:hover {
								background: $page-hover;
							}
						}

						&:hover {
							color: $m_base_color;
							background: $page-default;
						}

						&.m-datatable__pager-link--active {
							background: $page-active;
							color: $page-active-font;
						}
					}

					> .m-pager-input {
						background: $page-default;
						border-color: $page-default;

						&:focus {
							background: $page-hover;
							border-color: $page-hover;
						}
					}
				}
			}

			> .m-datatable__pager-info {
				.m-datatable__pager-size {
					.btn.dropdown-toggle {
						background: $page-default;
						color: get-color($skin, regular, '+');

						&:hover {
							background: $page-hover;
						}
					}
				}

				.m-datatable__pager-detail {
				}
			}
		}
	}
}

//==== Generate Component ====//

//== Component Base
@include m-build-component--datatable-default-base();

//== Component Skin
@include m-build-component--datatable-default-skin(light);