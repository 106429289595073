//Colors
$blue-dark: #16263F;
$blue-light: #2BC7D4;

$grey-800: #282828;
$grey-600: #414042;
$grey-400: #999999;
$grey-200: #F0F0F0;

$blue-800: #011B44;
$blue-600: #0D3169;
$blue-400: #3163B1;
$blue-200: #94B3E2;

$aqua-800: #00758E;
$aqua-600: #00969F;
$aqua-400: #13C9D4;
$aqua-200: #95F9FF;

$green-800: #245D43;
$green-600: #349366;
$green-400: #29B473;
$green-200: #AEE4CB;

$yellow-800: #D0BD0F;
$yellow-600: #F1DB13;
$yellow-400: #F5E65C;
$yellow-200: #F7F0B0;

$orange-800: #A33B00;
$orange-600: #EE6400;
$orange-400: #FF7E21;
$orange-200: #FFB987;

$red-800: #6F0000;
$red-600: #A80000;
$red-400: #CD1717;
$red-200: #FF6060;

$purple-800: #582E5F;
$purple-600: #8C4897;
$purple-400: #C670D3;
$purple-200: #F4AEFF;



//Font sizes
$paragraphFontSizeXs: 11px;
$paragraphFontSizeSm: 12px;
$paragraphFontSizeMd: 13px;
$paragraphFontSizeLg: 14px;
$paragraphFontSizeXl: 15px;

//Font weight
$paragraphFontWeightLight: 300;
$paragraphFontWeightRegular: 400;
$paragraphFontWeightMedium: 500;
$paragraphFontWeightBold: 600;
$paragraphFontWeightStrong: 800;

// Base colors
$comexport-blue: #00192d;

$color-grey: #495057;
$color-grey-opaque: #efefef;
$color-grey-light2: #d9d9d9;
$color-grey-light: #c9c9c9;
$color-grey-medium2: #606a73;
$color-grey-medium: #3d4349;
$color-grey-dark: #32373b;

$color-blue-grey: #808182;
$color-blue-grey-light2: #a7a7a8;
$color-blue-grey-light: #8d8e8f;
$color-blue-grey-dark: #5f6970;

$color-red: #9e0101;
$color-red-dark: #7c0000;

$color-green: #29b473;
$color-green-hover: #1b8855;
$color-green-dark: #006d3b;

$color-purple: #652d90;
$color-purple-dark: #4b1e6d;

$color-blue-opaque: #ebedf2;
$color-blue-light2: #0091d6;
$color-blue-light: #3b6394;
$color-blue-light-grey: #ced4da;
$color-blue: #193d69;
$color-blue-dark: #3a4861;

$color-orange: #ff6c0a;
$color-orange-light: #ff7f4d;
$color-orange-light2: #ff9e77;
$color-orange-dark: #df5302;

$color-yellow: #ffc50a;
$color-yellow-light: #ffe17e;
$color-yellow-light2: #ffe9a1;
$color-yellow-dark: #d8a600;

$color-aqua: #289399;

// Chart colors
$chart-color1: #808083;
$chart-color2: #d0d2d3;
$chart-color3: #082442;
$chart-color4: #0091d6;
$chart-color5: #6acef5;
$chart-color6: #ffc50a;
$chart-color7: #f05a28;
$chart-color8: #652d90;
$chart-color9: #006d3b;
$chart-color10: #29b473;

$fontPoppins: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

/* Theme inputs comexport */

$default-input-border: 1px solid $color-blue-light-grey;
$default-input-border_focus: 1px solid $grey-600;

/* SM */

$sm-input-padding: 6px 12px;
$sm-input-fontsize: 13px;

$sm-input-dropdown-padding: 4px 12px 5px 12px;

$md-input-padding: 8px 15px 7px 15px;
$md-input-fontsize: 14px;

$lg-input-padding: 9px 15px 7px 15px;
$lg-input-fontsize: 14px;

@mixin input-default-sm {
    transition: border-color 0.3s ease;
    padding: $sm-input-padding;
    font-size: $sm-input-fontsize;
    font-family: $fontPoppins;
    border: $default-input-border;

    &:enabled:focus {
        border: $default-input-border_focus;
        box-shadow: none;
        outline: none;
    }
}

@mixin input-dropdown-default-sm {
    transition: border-color 0.3s ease;
    padding: $sm-input-dropdown-padding;

    font-family: $fontPoppins;
    border: $default-input-border;

    .p-dropdown-label {
        font-size: $sm-input-fontsize;
        padding: 2px 0 1px 0;
    }

    .p-dropdown-trigger {
        .p-dropdown-trigger-icon,
        .pi,
        .pi-chevron-down {
            font-size: 10px;
            font-weight: 700;
            margin-top: 3px;
        }
    }

    &:enabled:focus {
        border: $default-input-border_focus;
        box-shadow: none;
        outline: none;
    }
}

@mixin input-default-md {
    transition: border-color 0.3s ease;
    padding: $md-input-padding;
    font-size: $md-input-fontsize;
    font-family: $fontPoppins;
    border: $default-input-border;

    &:enabled:focus {
        border: $default-input-border_focus;
        box-shadow: none;
        outline: none;
    }
}

@mixin input-default-lg {
    transition: border-color 0.3s ease;
    padding: $lg-input-padding;
    font-size: $lg-input-fontsize;
    font-family: $fontPoppins;
    border: $default-input-border;

    &:enabled:focus {
        border: $default-input-border_focus;
        box-shadow: none;
        outline: none;
    }
}
